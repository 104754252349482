package planning.mod;
import js.html.Clipboard;
import js.Browser;
import planning.api.Serv;
import planning.mod.SessionCalc;
import planning.mod.Modele;
import planning.mod.Modeles;
import tink.pure.Mapping;
using tink.CoreApi;
import  tink.state.Promised;
import planning.cross.Interfaces;
@:jsonStringify((_:coconut.json.Serializer<mod.ModelMap>))
@:jsonParse((_:coconut.json.Unserializer<mod.ModelMap>))
class ModelMap implements coconut.data.Model {

    // @:editable
    // var clearMe:Bool=false;

    // @:observable
    // var tit:String= @byDefault "loop";
    @:editable
    var rev:Int=0;

    @:skipCheck
    @:constant 
    var server:IServ;

    @:constant
    var modeles:Modeles = @byDefault new Modeles({server:server.Log('serv'),sessions:sessions,batch:batch});

    @:skipCheck
    @:computed
    var sessions:Array<CoolDate>= SessionCalc.genSessions();



    // @:skipCheck
    // @:computed
    // var sessions:Array<CoolDate>= [];

    // @:skipCheck
    // var asyncDisposMap:DisposMap== {
    // rev;

    // modeles.getModeles()
    // };
   
    @:computed
    var disposMap:DisposMap = 
    {
        rev;
        normalize(
            SessionCalc.mix(
                    modeles.getModeles().Log("frrrrrrrrrr"),
                    sessions.Log("sessions")
                    )
                ).Log("disposMap");
    }

    //@:skipCheck
    private var resultMap:Map<String,String>=  new Map();

    //@:skipCheck
    private var counterMap:Map<String,Int>= new Map();
 
    // @:editable
    // public var modeleList:List<String>=@byDefault [];

    // @:editable
    // public var poserList:List<Modele> = @byDefault [];

   
    @:editable  var revision:Int = 1;
    @:loaded
    var result:tink.pure.Mapping<String,String>={ 
        revision;
        server.getRez()
        //server.getModeles()
        .next(m->(m:Mapping<String,String>))
        .next(m->counterInit(m.Log("fromsave")));
    }


    @:constant 
    public var isAdmin:Bool;
    public function rec(){
       // return @patch {rev:rev+1};
           return  server.saveDispos(disposMap)
           .next(
               e->{@patch {rev:rev+1}}
           );       
    }

    public static function normalize(map:Map<CoolDate,Array<String>>){
        var mappo:Map<String,List<String>>=[];

        for ( k=>v in map ){
            mappo.set(k.toString(),v.fromArray());
        } 
      
       return (mappo:tink.pure.Mapping<String,List<String>>);
    }

    //tools
    static function getUnique<T>(it:Iterable<T>) {
        var l = [];
        for (v in it) {
         	if (l.indexOf(v) == -1) { // array has not v
            	l.push(v);
            }
         }
        return l;
    }

    private function counterInit(m:Mapping<String,String> ){
        
        for( k=>v in m){

            resultMap.set(k,v);
            counterMap.set(v,1);

        }
       // modeles.modeleList=getUnique(resultMap);
    
        calCounter();
        return m;

    }


    public function batch(sels:List<Modele>){
        trace( "batch" + sels);
        var buf = new StringBuf();
        var cleanTel= function(pho){
            var reg=~/[^\d+]+/g;
           return  reg.replace(pho,"");
       } 
        sels.map(m->{
            server.getDetail(m.nom).next(
                detail->{
                  //  trace('imessage://${detail.num}');
    
               buf.add(cleanTel(detail.num)+ ',');
             
           // Browser.navigator.clipboard.writeText(buf.toString()).toPromise();
            buf;

            }
            )
            
        .next(buf->{Browser.navigator.clipboard.writeText(buf.toString()).toPromise();buf;})
        .tryRecover( f -> f )
        .handle(t->t);
        }
       
        );
       
    trace("les nums sont copiés dans le clipBoard");
    }

    public function sticker ( session:CoolDate, choix:String ){

        command.StickerCommand.launch( session,choix);
       // js.Browser.window.location.assign("/sticker");


    }

    public function sms(nom:String){
        trace( "sms"+nom);
        //Browser.window.location.href="imessage://0676266440";
        // server getSms(nom).next((num,sms)->{
        //     Browser.window.location.href="imessage://"+num;
        // })
        
       var dates = getDatesByModele(nom);
       
       var dato=function(date:CoolDate){
            //return 'le mardi ${date.date}/${date.month+1}';
            // switch(date){
            //     case Some(d):
            //         return 'le mardi ${d.toString()}';
            //     case None: return "";
            // }

            return 'le mardi ${date.toString()}';
           
       }

       var cleanTel= function(pho){
            var reg=~/[^\d+]+/g;
           return  reg.replace(pho,"");
       } 
        server.getDetail(nom).next(
            detail->{
                trace('imessage://${detail.num}');
            Browser.window.location.href='imessage://${cleanTel(detail.num)}';
           //Browser.window.location.href='imessage://‭+33 66 99 44582‬';

           var buf = new StringBuf();
           buf.add('Coucou $nom ! j\'ai constitué mon planning de modeles vivants pour ce trimestre.\nJe peux te proposer ');
           var it = dates.iterator();
           if (dates.length==1){
                buf.add('cette date :\n ${dato(dates.first().orNull())}\n');

           }
            else{
                buf.add('ces dates :\n');
                while( it.hasNext()){
                    var next=it.next();
                        if(next!=dates.last().sure()){
                         buf.add( dato(next) + ",\n");
                        }else{
 
                        buf.add('et ${dato(next)}.\n');
                    }
                }
            }
            buf.add( "Est ce que ça te convient ? \nDavid pour #nuEnSlip.");
            trace( buf.toString());
            buf.toString();
            }
            ).next(buf->Browser.navigator.clipboard.writeText(buf).toPromise())
            .handle(f->f);
        return "pipou";
    }

    // flush choiz de modele
    @:transition 
    public function flushIt(){
        trace("flushIt");
        return cast server.flushBak();//.handle(f->f);
    }
    // flush dispos...
    @:transition 
    public function flushDispoz(){
        trace("flushDispoz");
        return cast server.flushDispoz();//.handle(f->f);
    }

    // assignation d'un modele
    @:transition
    public function choiz(date:CoolDate, modele:String){
        trace("choiz boss mode is "+ isAdmin);
        if (!isAdmin )
            return {};

        if( modele == "" )
        resultMap.remove( date );

        else
        resultMap.set(date , modele);

        return  server.setRez(date, modele).next(
           e->{ @patch { revision:revision+1 } }
       );

    }

    // @:transition
    // public function update(){
    //     trace( "up");
    //    return{ rev:rev+1};
    // }
    
    /*
    end transitions
    */
   

    public function getCount(name:String):Int{
        return counterMap.get(name);
    }

    private function calCounter(){
        for ( k=>v in resultMap){
            
            if(counterMap.exists(v))
                counterMap.set(v.Log("modele"),Lambda.count(resultMap,n->{n==v;}));
            else
            counterMap.remove(v.Log("notexist"));
        }
        //counterMap.Log("counterMap");
    }

    //cal server 
    public function goCal(){
        #if !noIcal
        server.genCal();
        #end
    }
   
    public function getDisposBySession(session:CoolDate):List<String>{
        trace( "getDisposBySession" +session);
        return disposMap.get(session).Log("dispo");
    }


    public function getDatesByModele(modele:String):List<CoolDate>{
        var list:List<CoolDate>= new List();
        for( k=>v in resultMap.Log("resultMap"))
            if(v==modele)
                list=list.append(k);
            return list;

    }


}