package planning.command;


import js.Browser.document as doc;
using tink.CoreApi;
import app.IRemoteRoot;
import cocomponents.*;
using Lambda;
#if client
import js.jquery.Helper.*;
import js.Browser.document as doc;
import planning.comps.MainComp;
import coconut.Ui.hxx;
import coconut.ui.Renderer;
import planning.mod.ModelMap;
import planning.api.Serv;
import planning.mod.Sessions;
#end

@:keep
class PlanningCommand<T> implements poscope.wire.ICommand<Noise>{

   #if client
   var remote:tink.web.proxy.Remote<IRemoteRoot>;

   public function new(r){
    this.remote=r;
   }

   var data:T=null;
    public function execute<T>(?data:T):Promise<Noise>{
         trace( "execute planning");
         this.data= cast data;
         var landing=doc.querySelector(untyped (data.landing));

         var ses=new Sessions();

         //var mapmod=ModelMap.normalize(SessionCalc.mix(SessionCalc.genModeles(),SessionCalc.genSession()));
         //var mods = new ModelMap({map:mapmod});
         
         var url:tink.Url=js.Browser.location.href;
             
         var serv= new planning.api.Serv();
         serv.remote=remote;
         var mods = new ModelMap({isAdmin:StringTools.contains(url.path ,"/boss"), server:serv}); 
        // tink.Json.stringify(mods).Log("pipo");
        // doc.addEventListener("DOMContentLoaded",e->{
             
             Renderer.mount(landing, hxx('<MainComp  {...ses} modelMap={mods} api={serv}/>'));
             
        // });
        


       
        //doc.addEventListener("DOMContentLoaded",init);  
        return Promise.lift(Noise);


    }




    

    #end

}