package sticker;

import fomantic.Calendar;
import fomantic.Dropdown;
import sticker.Ecchymodel;
import coconut.ui.View;
using tink.CoreApi;
import sticker.StickerComps;
import sticker.TerreModel;

class TerreSticker extends coconut.ui.View{


    @:attr
    public var sessionNum:Int;
    @:attr
    public var date:Date;

    @:attr
    public var hashtag:String;

  

    @:attr
    public var modele:String;

    @:attr
    public var background:String= @byDefault "";

    @:tracked
    @:attr
    public var prix_modele:Int;


    @:attr
    public var duree:Int;

   

    @:attr
    public var nb_participants:Int;

    @:attr
    public var prixSession:Int;

    @:attr
    public var sessionType:TerreType = @byDefault nu;

    @:attr
    public var dateString:String;

    @:attr
    public var setNum:Int->Promise<Noise>;
    @:attr
    public var setHash:String->Promise<Noise>;
    @:attr
    public var setSessionNum:Int->Promise<Noise>;
    @:attr
    public var setDate:Date->Promise<Noise>;
    @:attr
    public var setDuree:Int->Promise<Noise>;
    @:attr
    public var setSessionType:TerreType->Promise<Noise>;

    @:attr
    public var setModele:String->Promise<Noise>;
    @:attr
    public var setPrixModele:Int->Promise<Noise>;


    public function render()
        <div class='sticker terre' style='background-image:url(${background};'>
        <h1> Session par Terre !  nº<EcritoNumInput text={sessionNum} choose={n->setSessionNum(n)}/> </h1>
        <Dater data={date} dataString={dateString} onAct={e->setDate(e)} />
        <h5>session <InlineDropdown data={SessionType.toValues()} choose={n->setSessionType(n)} dataString={sessionType} /> avec <EcritoLineInput class="under" text={modele} choose={n->setModele(n)}/> </h5> .
        <p> durée:<EcritoNumInput text={duree} choose={n->setDuree(n);}/>h</p>
       
       <p> Inscriptions ouvertes avec le code :</p>
        <h1> #<EcritoLineInput text={hashtag} choose={n->setHash(n)}/></h1>
        <p>
        <span>prix par participants :
        <EcritoNumInput text={prix_modele} choose={n->setPrixModele(n);}/>€
        /<EcritoNumInput text={nb_participants} choose={n->setNum(n)}/> 
        </span>
       <span>
        ={prixSession}€
        </span>
        </p>

        </div>
        ;


    
}




