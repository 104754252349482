package auth.client;

import fomantic.Types;
import fomantic.Icon;
import fomantic.Input;
import fomantic.Button;
import fomantic.Label;
import fomantic.Form;
using Debug;
import js.jquery.Helper.*;
using tink.CoreApi;
import coconut.ui.View;

class Inscript extends coconut.ui.View{

  @:attr var withEmail:Bool=true;

  @:attr public var act:{pass:String,id:String ,?mail:String}->Promise<Noise>;
  
  @:attribute var className:tink.domspec.ClassName = null;
  
   var pass:String=null;
   var id:String=null;
   var mail:String=null;

  @:state var errorMsg:String=null;
  @:state var status:Int=0;
  var me:js.html.Element=null;
  function gather(e){
    trace( "gatzzer");
    e.preventDefault();

    var _e = untyped ( J(me).find('form')).form("validate form");
    if(_e == true)
    act({pass:pass, id:id ,mail:mail}).next(
      n->{
        n.Log("ok");
        status=1;
      }
    ).tryRecover((er:tink.Error)->{

     // er.data.Log("error");
      var t:{error:String}=haxe.Json.parse(er.data);
      errorMsg = t.error.Log();
      status = -1;
      er;

    });
    else
    status=-1;
    
  }

  // public function humanize(status){
  
  //   var reg= ~/#([0-9]+\w)/;
  //   reg.match(status);
  //   var code=(reg.matched(1));
  //   return switch Std.parseInt(code).Log("code"){
  //     case 401, 403: "erreur d'authentification ...";
  //     case _: "erreur serveur";
  //   }

  // }

  function iconStatus(status:Int):IconName{
    trace(status);
    return switch status{
      case 0: IconName.save;
      case 1: IconName.check;
      case -1: IconName.recycle;
      case _:IconName.save;
      
    }
  }

   function valid(el:js.html.Element){
      me=el;
    untyped (J(el).find('form'))
        .form({
          onSuccess: function() {
            trace( 'Success');
            return true; // false is required if you do don't want to let it submit
          },
            onFailure: function() {
            trace('Failure');
            return false; // false is required if you do don't want to let it submit                                            
          },
          fields: {
            email: {
              identifier  : 'email',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Merci de renseigner un e-mail'
                },
                {
                  type:'email',
                 // type   : 'regExp[/[\\w\\.-]+@[\\w\\.-]+\\.\\w{2,4}/]',

                  prompt : 'votre e-mail n\'est pas valide'
                }
              ]
            },
            pass: {
              identifier  : 'pass',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'un bon mot de passe c\'est tout pas de longuer minimum , pas de caractères alpha numerique chelou ! '
                },
                
              ]
            },
            name: {
              identifier  : 'name',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Please enter your name'
                },
                {
                  type   : 'length[3]',
                  prompt : 'Your name must be at least 3 characters'
                }
              ]
            }
          }
        })
      ;
   }
   function render(){
      <div ref=${valid} class=${className}>
      <Form class="pluj">    
         <Input name="name" img={IconName.lock} onBlur={s->id=s.Log("id")} placeholder="name" type={text}/>        
        <if {withEmail}>
         <Input name="email" img={IconName.lock} onBlur={s->mail=s.Log("pass")} placeholder="mail" type={InputType.email}/>        
         </if>
         <Input name="pass" img={IconName.user} onBlur={s->pass=s.Log("pass")} placeholder="password" type={InputType.password}/>
         <Button text="ok" type={secondary} onClick={gather} img={iconStatus(status)}/>
         <if {errorMsg!=null}>
          <Label>erreur:{errorMsg}</Label> 
         </if>
      </Form>
      </div>
   }
}


class RecupMail extends View{

  var mail:String=null;

  @:attr
  var act:String->Promise<{msg:String}>;

  @:state
  var msg:String=null;


  function doIt(e){
    act(mail.Log("mailup"))
      .next(s->msg=s.msg.Log())
      .tryRecover((er:tink.Error)->{
        var t:{error:String}=haxe.Json.parse(er.data);
        //var t=haxe.Json.parse(er.Log().message);
        msg= t.error;
        //er;
      });
  }
  
  function render()
    <div>
    <h3>Vous avez oublié votre mot de pass? renseignez votre mail pour le regenerer</h3>
    <Input name="email" img={IconName.lock} onBlur={s->mail=s.Log("mail")} placeholder="mail" type={InputType.email}/>        
    <Button text="ok" type={secondary} onClick={doIt} />
    <if {msg!=null}>
      <Label>{msg}</Label>
    </if>
    </div>;
}

class ChangePass extends View{

  @:attr
  @:skipCheck
  var user:{identity:String};
  @:attr
  var act:String->Void;

  var pass:String=null;
  
  function render()
    <div>
    <h3>Hello {user.identity} vous pouvez changer votre mot de passe ici</h3>
    <Input name="pass" img={IconName.lock} onBlur={s->pass=s.Log("pass")} placeholder="pass" type={InputType.password} />        
    <Button text="ok" type={secondary} onClick={e->act.bind(pass)() }  />
    </div>;
}

