package app;

class Config {
	
	public static var localPort = 8080;
	public static var localIP = 'localhost';
	
	 public static var PORT:Int  #if server =untyped (process.env.PORT) #end;
	 public static var IP:String   #if server =untyped (process.env.IP) #end; 
	//public static var PORT:Int   =untyped (process.env.PORT);
	//public static var IP:String  =untyped (process.env.IP) ; 

	public static var sqlPort:Int = 3309;
	public static var sqlUser:String = "root";
	public static var sqlPass:String = "";

	public static var LocalSqlUser:String = "root";
	public static var LocalSqlPort:Int = 8889;
    public static var LocalSqlPass:String = "root";
    
    public static var root='./';
    public static var localroot='./www';
    
}
