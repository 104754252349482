package command;

import tink.web.proxy.Remote;
#if client
import js.Browser.document as doc;
import coconut.Ui.hxx;
import js.jquery.Helper.*;
import ecrito.EcritoModel;
#end
using tink.pure.List;
using tink.CoreApi;
import app.IRemoteRoot;
import  ecrito.*;
import app.Interfaces;

class EcritoCommand<T> implements poscope.wire.ICommand<Noise>{

   
    var remote:tink.web.proxy.Remote<IRemoteRoot>;
    public function new(r) {
		remote = r;
	}

   #if client
    public function execute<T>(?data:T):Promise<Noise>{
trace("execute" +untyped(data).text);
       // var texto=haxe.Resource.getString("texto");
        var ecritoModel= new EcritoModel({data:untyped(data).text});
        coconut.ui.Renderer.mount(doc.querySelector(".landing"),
            
        hxx('<Ecrito model={ecritoModel}/>')
    );
        var st=Promise.trigger();
        EcritoModel.signal.handle(r->
            createPeople(r)
           // createMockPeople()
            .next(p->
                if(validateMail(p.mail))
                    p
                else
                    throw 'noMail')
            .next(p->remote.pre().insert( cast p.Log("pipl") ) )
             .next(p->p.Log("result"))
             
             .next(p->{redirect(untyped data.url);p;})
            .tryRecover(r->r.Log("erro"))
            .handle(r->r)
            );
       return Promise.NOISE;
        

    }

    function redirect(url:String){
        js.Browser.window.location.href = url; //relative to domain
    }

    function validateMail(s):Bool{
        var ereg=~/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return ereg.match(s);
    }

    static var mockCounter=0;
    function createMockPeople():Promise<People>{
        return Promise.lift({
            id:null,
            name:'mock$mockCounter',
            mail:"contact@ultragouache.com",
            data:'{}',
            status:MAYBE
        });
       
    }

    

    function createPeople(resultModel:ResultModel):Promise<People>{
        var haxeMap:Map<String,Array<String>>= resultModel;
        var people:People={
       // people.id=0;
       name:haxeMap.get('nom')[0].Log("nom"),
    
        data:haxe.Json.stringify(haxeMap),
        //people.cours=haxeMap.get("nom").join(',');
        //people.niveau=haxeMap.get("nom");
        //people.plage=haxeMap.get("nom").join(',');
        mail:haxeMap.get('mail')[0],
            
        status:MAYBE
        }
            trace( people);
        return Promise.lift( people);
    }
    #end
}