package planning.cross;
import planning.cross.Interfaces.Detail;
using tink.CoreApi;
import planning.cross.Interfaces;
#if !noIcal 
import ical.Cal;
#end
interface IPlanningRoute{

    @:get('/')
    @:get('/boss')
    public function home(?boss:String):{msg:String};

    @:get('/planning')
    @:get('/planning//boss')
    public function planning(?boss:String):Promise<tink.template.Html>;

    @:post
    public function saveCal(body:{content:String}):String;
    #if !noIcal 
    @:post
    public function genCal(body:{content:Array<Cal>}):Promise<Noise>;
    #end
    @:post
    public function saveBak(body:{content:String}):Promise<Noise>;
    
    @:get
    public function getBak():Promise<String>;

    @:get
    public function flushBak():Promise<Noise>;
    @:get
    public function flushDispoz():Promise<Noise>;
    @:post
    public function saveMods(body:{content:String}):Promise<Noise>;
    
    @:get
    @:produces("application/json")
    public function getMods():Promise<{content:String}>;

    @:get("/detail")
    public function setupDetail():Promise<{msg:String}>;

    @:get("/createDetail")
    public function createDetail():Promise<{msg:String}>;

    @:get("/detail/$nom")
    public function detail(nom:String):Promise<Detail>;

    @:post("detailUpdate")
    //public function detailUpdate(body:{nom:String,active:Bool,num:String}):Promise<Detail>;
    public function detailUpdate(body:Detail):Promise<Detail>;

    @:get('sms/$nom')
    public function getSms(nom:String):Promise<Sms>;

}