package planning.comps;
import planning.mod.Modeles;

class DispoModeleView extends coconut.vdom.View{


    @:attr
    var modeles:Modeles;




    public function render(){
        <div class="dispos">
        <for { modele in modeles.poserList}>

        <div class="mod">
            <p>{modele.nom}</p>
           
            <p> {modele.filtre.toCoolString()}</p>
          
            
        </div>

        </for>
        </div>
    }
}