package app.routes;

import app.Interfaces;

using tink.CoreApi;

import poscope.wire.*;
using poscope.wire.Actor;
import tink.template.Html;

interface IPreRoute {
	@:post
	public function insert(body:{
		name:String,
		mail:String,
		data:String,
		status:PeopleStatus
	}):Promise<Noise>;
}

#if server
@:publicFields
class PreinscrRoute implements IPreRoute {
	var api:apis.PreinscriptionApi;
    var mailApi:apis.MailApi;

	function new(db) {
        trace( "hello pre");
        mailApi= new apis.MailApi();
		api = new apis.PreinscriptionApi(db);
    }
    
    @:produces("application/json")
    @:get
    function all()
        return api.all();

    @:produces("application/json")
    @:get('/status/$level')
    @:get('/status')
    function allStatus(?level:Int=0)
        return api.allStatus(level);

    @:get
	function flush():Promise<Noise> {
		return api.flush();
    }
    
    @:produces("application/json")
    @:get
    function verif(){
        return api.getLast()
        .next(p->p.Log("last"));
      
    }



	@:post
	function insert(body:{
		name:String,
		mail:String,
		data:String,
		status:PeopleStatus
	}):Promise<Noise> {
        trace("insert");
		body.Log('people');
			
            return api.insert(cast body)
            .next(n->api.getLast())
            .next(goMail)
			.next(p->{msg:"preinscr ok"});
    }

    @:accepts('text/html')
    @:get('/confirm/$id/level/$level')
    function confirm(id:Int,level:Int){
        return api.confirm(id,level)
        .next(r->api.get(id))
        .next(p->views.temp.Confirm.render({nom:p.name}).withLayout().render());
        //return {msg:'ok-$id'};
    }
    
    function goMail(p:People){
        return mailApi.send(
            [p.mail],
            "merci from ultragouache",
            genMailBody(p, 'http://${Config.IP}:${Config.PORT}/pre/confirm/${p.id}/level/$SURE')     
        ).next(n->p)
        .tryRecover(r->r.Log("erroMail")); //check if mail is not good.
    }

    function genMailBody(p:People,url){
        return 'merci ${p.name}</br> cliquez sur le lien pour confirmer votre interêt </br> <a href="$url">clic</a> </br> à biehtôt. </br> www.ultragouache.com';

    }
}
#end
