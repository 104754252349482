package glossaire.command;



import tink.web.proxy.Remote;
#if client
import js.Browser.document as doc;
import coconut.Ui.hxx;
import js.jquery.Helper.*;
import auth.client.Inscript;
import auth.client.LogMark;
#end
using tink.pure.List;
using tink.CoreApi;
import app.IRemoteRoot;
import cocomponents.*;




class GlossaireCommand implements poscope.wire.ICommand<Noise>{

   
    var remote:tink.web.proxy.Remote<IRemoteRoot>;
    public function new(r) {
		remote = r;
	}

   #if client
    public function execute<T>(?data:T):Promise<Noise>{
        trace( "execute glossaire command");

       
 
        var p:PromiseTrigger<Noise>= Promise.trigger();
       // doc.addEventListener("DOMContentLoaded",e->{

            var landing=doc.querySelector(untyped (data.landing));
            var glossModel=new GlossModel({remote:remote});
            
            trace (landing);
            coconut.ui.Renderer.mount(landing,        
                  hxx('<GlossaireComp  mod={glossModel} />')
               
            );
                p.resolve(Noise); 
          
            
            return p.asPromise();
    }

    #end

}