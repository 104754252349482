package mdpaper.command;


import js.Browser.document as doc;


using tink.CoreApi;
import app.IRemoteRoot;
import cocomponents.*;
using Lambda;
#if client
import coconut.Ui.hxx;
import js.jquery.Helper.*;
#end

@:keep
class MdPaperCommand implements poscope.wire.ICommand<Noise>{


   
   #if client
   var remote:tink.web.proxy.Remote<IRemoteRoot>;
   public function new(r) {
       remote = r;
   }

    public function execute<T>(?data:T):Promise<Noise>{
        trace(" execute paper");
       trace( untyped(data.content));
        var landing=doc.querySelector(untyped (data.landing));
        var paperModel:MdPaperModel=new MdPaperModel({
            server:remote,
            content:untyped data.content,
            fileName:untyped data.fileName
            
        });
        
        trace(landing);
     
         // <Doodle users=${users} udates=${udates}  dates=${dates} addDate=${dates.add} userDelete=${users.delete} dateDelete=${dates.delete} addUser=${users.add} actdate=${users.affecte}/>
             coconut.ui.Renderer.mount(landing,
     
                   hxx('
                              
                   <MdPaperComp {... paperModel } />
                   
                   ')
     
     
                
             );
 
  
            return Promise.lift(Noise); 
    }


 

   




    #end

}