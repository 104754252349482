package ical;
import thx.Time;
import thx.DateTimeUtc;
using Std;

/*
start: [2018, 5, 30, 6, 30],
  duration: { hours: 6, minutes: 30 },
  title: 'Bolder Boulder',
  description: 'Annual 10-kilometer run in Boulder, Colorado',
  location: 'Folsom Field, University of Colorado (finish line)',
  url: 'http://www.bolderboulder.com/',
  geo: { lat: 40.0095, lon: 105.2669 },
  categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
  status: 'CONFIRMED',
  busyStatus: 'BUSY',
  organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
  attendees: [
    { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
    { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
  ]
  */

@:forward
abstract Cal(CalEvent) from CalEvent to CalEvent{
    public function new(t:CalEvent){
        this=t;
        
        uuid();
    }
    

    public function uuid(){
        this.uuid="popoo";
    }

     static function _stamp(){
        return Date.now();
    }

    static function toStringDur(dur:Time):String{
        //return 'PT6H30M';
        return 'PT${dur.hours}H${dur.minutes}M';
    }

   static function toStringTime(d:Date):String{
        return '${d.getHours()}${dig2(d.getMinutes()+1)}${dig2(d.getSeconds())}';
    }
    static function toStringDate(d:Date):String{
        return '${d.getFullYear()}${dig2(d.getMonth()+1)}${dig2(d.getDate())}T${toStringTime(d)}Z';
    }

    public static function dig2(n:Int):String{
        return (n<10)? '0$n':'$n';
    }

    
   public function toTemplate():CalString{
       trace('toTemplate');
        return {
            stamp:toStringDate(_stamp()),
            start:toStringDate(this.start),
            end:toStringDate( (DateTimeUtc.fromDate(this.start)+this.duration).toDate() ),
            title:this.title,
            description:this.description,
            location:this.location,
            url:this.url,
            geo:'${this.geo.lat};${this.geo.lon}',
            categories:this.categories.join(","),
            status:this.status,
            busyStatus:this.busyStatus,
            organizer:'Admin:mailto:${this.organizer.email}',
            attendees: (this.attendees==null)? [] :this.attendees,
            duration:toStringDur(this.duration),
            uuid:this.uuid,
        }
    }
}


typedef CalEvent={

    start:Date,
    duration:String,
    title:String,
    description:String,
    location:String,
    url:String,
    geo: LonLat,
    categories:Array<String>,
    status:CalStatus,
    busyStatus:CalBusyStatus,
    ?organizer:User,
    ?attendees:Array<User>,
    ?uuid:String
}

typedef CalString={
    stamp:String,
    end:String,
    start:String,
    duration:String,
    title:String,
    description:String,
    location:String,
    url:String,
    geo: String,
    categories:String,
    status:CalStatus,
    busyStatus:CalBusyStatus,
    organizer:String,
    attendees:Array<User>,
    ?uuid:String
}

typedef LonLat={
    lat:Float,
    lon:Float
}

enum abstract CalStatus(String){
    var CONFIRMED;
    var TENTATIVE;
    var CANCELED;
}
enum abstract CalBusyStatus(String){
    var BUSY;
}
enum abstract PartStat(String){
    var ACCEPTED;
}

enum abstract Role(String){

    var REQPARTICIPANT='REQ-PARTICIPANT';
    var OPTPARTICIPANT ="OPT-PARTICIPANT";
}
typedef User={
    name:String,
    ?email:String,
    ?dir:String,
    ?rsvp:Bool,
    ?partstat:PartStat,
    ?role: Role
}