package auth.client;

#if client
import app.IRemoteRoot;
import fomantic.Icon.IconName;
import tink.state.Promised;
import fomantic.*;
import app.Interfaces;
using tink.CoreApi;
class LogMark extends coconut.ui.View{
    
    @:attr
    var logout:Void->Void;
    @:attr
    var user:Promised<Option<User>>;

    function render()
        <div class="logmark">
            <switch {user}>
            <case {Done(optu)}>
                <switch {optu}>
                    <case {Some(u)}>
                    <Label>{u.identity}</Label>
                    <case {None}>

                </switch>
            <case {Loading}>
            <case {Failed(f)}>
            </switch>
            
            <Button onClick={e->logout()} text="logout" img={IconName.minus}/>
        </div>;

}

class LogMarkModel implements coconut.data.Model{


    @:constant
    var remote:tink.web.proxy.Remote<IRemoteRoot>;

    @:editable
    var rev:Int=0;

    @:loaded
    var user:Option<User>={
        rev;
        remote.auth().connectedUser(); 
    }

    @:transition
    function logout(){
        return remote.logout().next(
            n->{rev:rev+1}
            );
    }

    @:transition
    function login(){
       
            return {rev:rev+1};
            
    }




}
#end