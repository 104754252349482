package command;
#if client
import js.Browser.document as doc;
import coconut.Ui.hxx;
import js.jquery.Helper.*;
import cocomponents.*;
import cocomponents.dood.Doodle;
import cocomponents.dood.model.*;
import cocomponents.dood.DoodleModel;


#end
using tink.CoreApi;
import app.Interfaces;
import app.IRemoteRoot;
@:keep
class DoodleCommand implements poscope.wire.ICommand<Noise>{


    var remote:tink.web.proxy.Remote<IRemoteRoot>;
    public function new(r) {
		remote = r;
	}

   #if client
    public function execute<T>(?data:T):Promise<Noise>{
         trace( "execute DoodleCommand");
    var landing=doc.querySelector(untyped (data.landing));
    
    var users=new UserList({server:remote});
    var dates= new NuDateList({server:remote});
    var udates= new UserDateList({server:remote});
        //users.fromArray([for (a in 0 ...10) UserList.generateUser()]);
        //dates=[for (a in 0 ...10)generateDate()];
    var doomod:DoodleModel=new DoodleModel({remote:remote});

    // <Doodle users=${users} udates=${udates}  dates=${dates} addDate=${dates.add} userDelete=${users.delete} dateDelete=${dates.delete} addUser=${users.add} actdate=${users.affecte}/>
        coconut.ui.Renderer.mount(landing,

              hxx('
              <div>             
              <Doodle {... doomod } />
              </div>
              ')


           
        );
        
        return Promise.lift(Noise);    
    }

    function init(){
        trace("init");        
    }

    #end

}