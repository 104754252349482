package command;

using tink.CoreApi;

#if client
import js.Browser.document as doc;
using tink.CoreApi;
import coconut.Ui.hxx;
import coconut.ui.Renderer;
import js.jquery.Helper.*;
import tink.Url;
import app.IRemoteRoot;
import sticker.*;
using tink.core.Promise;
import thx.DateTime;
import domToImage.*;
#end
class StillCommand implements poscope.wire.ICommand<Noise>{

    #if client
    var remote:tink.web.proxy.Remote<IRemoteRoot>;
    public function new(r) {
		remote = r;
	}
    public function execute<T>(?data:T):Promise<Noise> {
      
        //var mapmod=ModelMap.normalize(SessionCalc.mix(SessionCalc.genModeles(),SessionCalc.genSession()));
        //var mods = new ModelMap({map:mapmod});
        trace( "yo");
        var url:tink.Url=js.Browser.location.href;
            
        var mods = new StillLifeModel({
            
            hashtag:"ecchymose",
           // background: "https://api.twitter.com/1/statuses/oembed.json?url=https://pbs.twimg.com/media/FKUgysjX0AA0jwl?format=jpg"
        }); 
        
        var fixe:js.html.Element;

        var pictureIt=function(container){
            DomToImage.toPng(container).toPromise()
            .next(e->{
                var img= doc.createImageElement();
               // img.src=e;
                img.src=e;
                fixe.Log();
                fixe.replaceChild(img,fixe.firstElementChild);
                }
                )
            .handle(r->r);
        };
       // tink.Json.stringify(mods).Log("pipo");
      // var nextTuesday=new DateTimeUTC(Date.now()).snapNextWeekDay(WeekDay.Tuesday).jump(TimePeriod.)
       // doc.addEventListener("DOMContentLoaded",e->{
            var container= doc.querySelector(".landing");
            fixe=doc.createElement("div");
            var img= doc.createImageElement();
            fixe.appendChild(img);
            // doc.body.appendChild(fixe);
            trace( container.parentNode );
            trace( "loaded");
            var tt= hxx('<StillLife {...mods} />');
            //var tt= hxx('<Bim />');
            coconut.vdom.Renderer.mountInto(container,tt);

            container.after(fixe);

            trace( fixe.parentNode);
            mods.observables.rev.bind(e->{
                e.Log("binded");
                mods.prixSession.Log("prix");
                pictureIt(container);
                }
            );
            
       // });
        return Promise.NOISE;
    }
    #end
}


