package planning.comps;
import js.html.Element;

using tink.pure.List;
import cix.Style.rule as css;
import fomantic.Button;
import fomantic.Icon;

import planning.mod.SessionCalc.CoolDate;
import planning.mod.ModelMap;
import planning.mod.ModeleDetail;

enum ViewManager{
    ViewAll;
    ModeleIdView(nom:String);
}

class MainComp extends View{

    var me=css('
        .grip{
            display:flex;
            width:100%;
            gap:3em;
        }
        
        .resume{
            margin:0 auto;
        }
    ');

    var className:tink.domspec.ClassName;

    @:skipCheck
    @:attr
    var api:planning.api.Serv.IServ;
  
    @:attr 
    var sessions:List<CoolDate>;
    
    
    @:attr
    var modelMap:ModelMap;

   
    @:state
    var viewManager:ViewManager=@ByDefault ViewAll;



    //@:state
    var curmonth:Int=0;

    function showHr(month:Int){
        var show=false;
        

        if(month != curmonth){
             show=true;
             curmonth=month;
        }

        return show;
    }

    function getChoix(session){
        return switch modelMap.result{
            case Loading:"...";
            case Done(v):v.get(session);
            case Failed(e): "error" +e;
        }
    }

    function genCal(){
        modelMap.goCal();
    }

    @:state
    var added:Int=0;


    function viewDidRender():Void {


        trace( "render update ");
    }

    

    function render()

        <div class={ className.add([me, "mainComp"]) } >

        <if {modelMap.isAdmin} >
         <Icon img={IconName.user} />
        </if>
       <Adder onAdded={(d,m)->{added++.Log("addddd");trace(d,m);}}  mods={modelMap.modeles}/>

       <switch {viewManager}>
       <case {ViewAll}>
               
       <div class="grip">
       <div>
        
       <for {session in sessions}> 

       <Month show={showHr(session.getMonth())} month={session.getMonth()}/>
           
           <let modele={getChoix(session)}>
           <Line session={session} modeles={modelMap.getDisposBySession(session)} sms={modelMap.sms} sticker={modelMap.sticker}choiz={modelMap.choiz} choix={modele} count={modelMap.getCount(modele)}/>
           </let>
       </for>
       <Button onClick={e->genCal()} text='export cal'></Button>
       <Button onClick={e->modelMap.modeles.flush()} text='flush dispos'></Button>

       <Button onClick={e->modelMap.rec()} text="rec planning"></Button>
       </div> 

        <ModelesView {...modelMap.modeles} onClick={e->viewManager=ModeleIdView(e).Log("cc")}/>

        <Inverted class="resume"  mod={modelMap} isAdmin={modelMap.isAdmin}/>
        <DispoModeleView modeles={modelMap.modeles} />
        </div>
        <case {ModeleIdView(_nom)}>
        <ModeleDetailView  nom={_nom} api={api} onClose={()->viewManager=ViewAll.Log("cc")}/>      
        </switch>
     </div>;
}

class Inverted extends View {

    @:attr 
    var isAdmin:Bool= @byDefault false;

    @:attr
    var className:tink.domspec.ClassName;

    @:attr
    var mod:ModelMap;

    function render()
        <div  class={className}>
        <switch {mod.modeles.modeleList}>
        <case {Done(_mm)}>

        {for (mo in _mm) 
            <div>
                {mo}
                <ul>
                    {for (n in mod.getDatesByModele(mo))
                        <li class="li">{n.toString()}</li>
                    }
                </ul>
            </div>
        }
        <if {isAdmin}>
        <Button onClick={e->mod.flushIt()} text={"flush"} />
        </if>
        <case {Loading}>
        <case {Failed(r)}>
        </switch>
        </div>;

}

class Month extends View{

    
    @:attr
    var show:Bool=true;
    @:attr
    var month:Int=0;

    var months=["janvier",	"février","mars",	"avril",	"mai",	"juin",	"juillet",	"août",	"septembre",	"octobre",	"novembre",	"décembre"];

    function render()
        <div class="month">
        
         <if {show}>
            <h3>{months[month]}</h3>
            <hr />
        </if>
        </div>;
}



class Line extends View{

    var ME= css('
    
        display:flex;
        
        .choose{
            /*align-self:flex-end;*/
            margin-left: auto;
            padding-left:1em;
        }

        .nom:nth-child(2n) {
            color: #cc3300;
        }
        .coolDate{
            margin-right:1em;
        }
        
    ');

    @:attr 
    var session:CoolDate;

    @:attr
    var modeles:List<String>;

    @:state
    var selId:String="";

    @:attr
    var choiz:(CoolDate, String)->Void;

    @:attr
    var sms:String->Void;

    @:attr 
    var sticker:(CoolDate,String)->Void;
    
    @:attr
    var choix:String="";

    @:attr
    var count:Int;

    var className:tink.domspec.ClassName;
    
    function sel(b:String):String{
        
        if(selId==b){
            choiz(session,"");
            return selId="";

        }

        choiz(session,b);
        return selId=b;
        
    }

    function render()
        <div class={className.add(ME)}>
    <span class= "coolDate">{session.toString().Log('up date')}</span>
    <for {nom in modeles}>
        <Nomo class="nom" value={nom} select={sel} active={selId==nom}/>
    </for>
    <div class="choose">  { choix }  {if(count!=null) '('+count +')' } </div>
    <Icon img={phone} act={e->sms(choix)} />
    <Icon img={sticky_note} act={e->sticker(session,choix))} />
    </div>;
}

class Nomo extends View{

    static var me=css('
       
        &.selected{
            color:black;
        }

        
        
    ');
    
    @:tracked
    @:attr
    var active:Bool;

    @:attr
    var value:String;

    @:attr 
    var select:String->Void;

   
    @:attr
    var className:tink.domspec.ClassName;

    @:ref
    var el:Element;

    function viewDidMount(){

        el.addEventListener('click',e->
                select(el.innerHTML)    
        );
         
    }

    function render()
        <span ref={el} class={className.add([me,{(active)?"selected":"";}])} >{value}</span>;
}