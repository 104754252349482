package glossaire;

using tink.CoreApi;

#if server
import planning.cross.Interfaces;
import ical.CalGen;
import asys.io.File;

using Debug;

import planning.api.DetailApi;

using tink.pure.List;

import poscope.wire.*;

using poscope.wire.Actor;

import auth.AuthApi;
import planning.command.*;
import app.Config;
import glossaire.GlossApi;

#end
import glossaire.GlossApi.GlossDef;
interface IGlossRoute {

	public function home(?boss:String):Promise<tink.template.Html>;

	@:get
	public function all():Promise<tink.pure.List<GlossDef>>;

    @:get("update/$id/$mot")
    public function update(id:Int,mot:String):Promise<Noise>;

    @:get("/create/$mot")
    public function create(mot:String):Promise<Noise>;
}

#if server
class GlossRoute {
	var api:GlossApi;

	public function new() {
		trace("hello gloss");
		api = new GlossApi();
	}

	@:get("/")
	@:get('/boss')
	public function home(?boss:String) {
		return all().next(r -> views.Planning.render()
			.withLayout()
			.addAction(glossaire.command.GlossaireCommand, {landing: '#planning_landing'})
            .addScript("https://cdn.jsdelivr.net/npm/jquery@3.3.1/dist/jquery.min.js")
            .addScript("/victorstyle/semantic/dist/semantic.min.js")
            .addStyle("/victorstyle/semantic/dist/semantic.min.css")
            .addStyle("/victorstyle/vide/vide.css")
            .addStyle("/style.css")

            .cleanAfter()
			.render());
	}

	@:produces("application/json")
	@:get
	public function export():Promise<String> {
		trace("glossing");
		
		return asys.io.File.getContent(app.Config.root + "/gloss.json")
        //.next(s -> {
			// trace( "bef"+s );
			// return try {
			// 	var t:Array<String> = haxe.Json.parse(s);

			// 	t.fromArray();
			// }
			// // ["pip"]
			// catch (msg:Any) {
			// 	trace(msg);
			// 	["bof"].fromArray();
			// }
		//}
        //)
        .tryRecover(r -> r.Log('nope'));

		//return api.all().fromArray();
	}

    @:get
    public function all():Promise<tink.pure.List<GlossDef>>{
        return api.all().next(
            r->r.fromArray()
        );
    }

	@:get
	public function setup()
		return api.setup().next(n -> {message: "Ok"});

    @:get("update/$id/$mot")
    public function update(id:Int, mot:String){
        return api.update(id, mot);
    }

    @:get("/create/$mot")
    public function create(mot:String){
        return api.create({mot:mot});
    }

	@:get('/import')
	public function _import(query:{link : String}){

		var p=Promise.trigger(); 
        trace( query.link);
        tink.http.Client.fetch(query.link).all()
        .handle(function(o) switch o {
			case Success(res):
				trace(res.header.statusCode);
				var text = res.body.toString();
				try {
					var t:Array<String> = haxe.Json.parse(text);
					api._import(t);
                    p.resolve(Noise);
				}
				// ["pip"]
				catch (msg:Any) {
					throw "blorp";
                    p.reject(new Error( "blorp"+ msg));
				}

			// do whatever with the bytes
			case Failure(e):
                p.reject(new Error( "_import bug"+ e));
		});
        return p.asPromise();
	}
}
#end
