package planning.mod;
import planning.api.Serv;

import planning.mod.ModeleDetail;
import tink.pure.Mapping;
using tink.CoreApi;
import tink.state.Promised;




class ModeleDetailMap implements  coconut.data.Model{

private static var done:Bool= false;

public function new(){
    trace( "new.....");
}

@:constant
var nom:String;

@:loaded 
var modele:ModeleDetail = {
   
    getDetail(nom);
}

@:skipCheck
@:constant var api:planning.api.IServ;

    public function getDetail(nom:String){
        trace("getDEtail ..."+nom);


        // return Promise.lift(new ModeleDetail({
        //                 nom:"detail.nom",
        //                 active:true,
        //                 num:"detail.num"
        // }));
        // if(!done)

        return api.remote.plan().detail(nom).next(
            detail->new ModeleDetail({
                    nom: detail.nom,
                    active:detail.active,
                    num:detail.num,
                    observations:detail.observations,
                    img:detail.img,
                    api:api
                })

        )
            
        // ).next(n->{
        //     done==true;
        //     n;
        // })
         .tryRecover(n->{
            n.Log("recover");
            return new ModeleDetail({
                nom:nom,
                active:false,
                num:"num",
                observations:"",
                img:"",
                api:api
            });
            }
            );
        // return Promise.NEVER;
    }

}
