package cocomponents.dood;
import model.*;
import app.IRemoteRoot;
import app.Interfaces;
import cocomponents.MayBool;
import tink.pure.Mapping;
import cocomponents.dood.model.*;

class DoodleModel implements Model{
    @:constant
    var remote:tink.web.proxy.Remote<IRemoteRoot>;
    @:computed
    var users:UserList=new UserList({server:remote});
    @:computed
    var dates:NuDateList=new NuDateList({server:remote});
    @:computed
    var udates:UserDateList= new UserDateList({server:remote});

    @:editable 
    var prix:Float=@byDefault 40.0;

    //@:computed
    
    var addDate:(Date,String)->Void=dates.add;
    //@:computed
    var userDelete:(Int)->Void=users.delete;
   // @:computed
    var addUser:(val:String)->Void=users.add;
    var dateDelete:(Int)->Void=dates.delete;
    //@:computed
    var actDate:(p:Pair<Int,Int>,MayBool)->Void=users.affecte;


    public function onCheck(p:Pair<Int,Int>,checked:MayBool,date:NuDateCheck){
        p.Log("pair");
        checked.Log("checked");
        date.Log("date");
        actDate(p,checked);
        calculePrix(date,checked);
    }

    @:skipCheck 
    var map:Map<NuDateCheck,Int>=new Map();
    @:transition
    function calculePrix(d:NuDateCheck,checked:MayBool){
        var prixMax=40; //move it
        var uz=0;
        var dir= switch checked {

            case Sure(b):
                if(b)
                    1;
                else
                    -1;
            case Maybe:
                0;

        }
       /// var dir=(checked)? 1:-1;
        if (map.exists(d))
        uz=map.get(d);
        else
        uz=d.users.length;
        uz=uz-1;//because of the fake zero user
        uz=uz+dir;
        uz.Log("uz");
        d.prix= prixMax/(uz);
        map.set(d,uz+1);// becaus of the fake zero user 
        d.prix=Std.int(d.prix*10)/10; //arrondi 0.00
        
       // prix=d.prix; //on arrondit pour les modèles !
        return { prix:d.prix};
    }


    public function isChecked(u:UserCheck,d:NuDateCheck):MayBool{

        return Sure(Lambda.exists(u.dates,ex->(ex==d.id)));
        //return Maybe;
        }

}