package app;

using tink.CoreApi;
#if server
import tink.sql.Types;
#end
import tink.pure.List;
import sessionsModule.ISessionApi;

#if client
	typedef Id<T>=Int;
	typedef VarChar<T>=String;
	typedef DateTime=Date;
	typedef TinyInt=Int;

#end

//becaus' cannot update dynamic ! 
typedef PatchString={
	id:Int,
	field:String,
	value:String
}

typedef MSG={msg:String};


/*
		---------- remote root  ( move it ) -----------
*/
/*
interface IRemoteRoot {
	@:get()
	public function allUsers():Promise<List<User>>;
	@:get()
	public function allDates():Promise<List<NuDate>>;
	@:get('/addUser/$name')
	public function addUser(?name:String):Promise<Noise>;
	@:get('/deleteUser/$id')
	public function deleteUser(id:Int):Promise<Noise>;
	@:get('/addDate/$date')
	public function addDate(?date:Date):Promise<Noise>;
	@:get('/deleteDate/$id')
	public function deleteDate(id:Int):Promise<Noise>;
	@:get('/allUsersCheck/')
	public function allUsersCheck():Promise<List<UserCheck>>;
	@:get('/allDatesCheck/')
	public function allDatesCheck():Promise<List<NuDateCheck>>;

	@:get('/assign/$did/$uid')
	public function assign(did:Int, uid:Int):Promise<Noise>;
	@:get('/deassign/$did/$uid')
	public function deassign(did:Int, uid:Int):Promise<Noise>;

	@:post('recUser/')
	public function recUser(body:{email:String, name:String}):Promise<Noise>;

	@:post('/inscripeinture')
	public function inscripeinture(body:{user:User, activity:Activity}):Promise<Noise>;
}
*/
/*
		---------- sql -----------
*/
#if server
@:tables(
	User,
	Profile,
	NuDate,
	UserDate,
	Activity,
	ActivityUser,
	Session,
	SessionTag,
	SessionTagJoin,
	SessionAttendee,
	SessionActivity,
	People)
interface Def extends tink.sql.DatabaseDefinition {
	
}
typedef Db = tink.sql.Database<Def> 
#end
/*
		---------- tables & models -----------
	 */
@:pure
typedef UserDate = {
	// @:autoincrement @:primary @:optional public var id(default	null):Id<UserDate>;
	public var date_id:Id<NuDate>;
	public var user_id:Id<User>;
	public var state:TinyInt;
}

@:pure
typedef User = {
	@:autoIncrement @:primary @:optional public var id(default, null):Id<User>;
	public var identity(default, null):#if server VarChar<255>#else String #end;
	 public var mail(default,null):#if server VarChar<255>#else String #end;
	@:optional public var token(default, null):#if server VarChar<255>#else String #end;
	@:optional public var access(default,null):#if server tink.sql.Types.TinyInt#else Access#end;
	//@:optional public var mail(default, null):#if server VarChar<255>#else String #end;
	//@:optional public var tel(default, null):#if server VarChar<255>#else String #end;
	
}

typedef People={
	@:autoIncrement @:primary @:optional final id:Id<People>;
	final name:#if server VarChar<255>#else String #end;
	final mail:#if server VarChar<255>#else String #end;
	final data:#if server Text#else String #end;
	//final plage:#if server VarChar<255>#else String #end;
	//final jour:#if server VarChar<255>#else String #end;
	//final activity:#if server VarChar<255>#else String #end;
	//final niveau:#if server VarChar<255>#else String #end;
	final status:#if server TinyInt#else PeopleStatus #end;

}

enum abstract PeopleStatus(Int){
	var GHOST=0;
	var SURE=1;
	var MAYBE=2;
}

enum abstract Access(Int){
	var BASIC=0;
	var ADMIN=1;
	var SUPERADMIN=2;
}

@:pure
typedef Profile={
	@:autoIncrement @:primary @:optional final id:Id<Profile>;
	public var user_id(default, null) :Id<User>;
	public var name(default, null):#if server VarChar<255>#else String #end;
	@:optional public var ?tel(default, null):#if server VarChar<255>#else String #end;

}
@:pure
typedef UserProfile = Profile & {
	//identity:#if server VarChar<255>#else String #end,
	mail:#if server VarChar<255>#else String #end,
	profile_id:#if server Id<Profile>#else Int #end,
	access:#if server TinyInt#else Access #end
	//token:#if server VarChar<255>#else String #end

}

@:pure
typedef UserJoin = User & {
	date_id:Id<NuDate>,
}

@:pure
typedef UserCheck = UserJoin & {
	public var dates:Array<Id<NuDate>>;
}

typedef ModelUser = User & {
	@:autoIncrement  @:optional public var id(default, null):Id<ModelUser>;
	public var name(default, null):#if server VarChar<255>#else String #end;
	@:optional public var mail(default, null):#if server VarChar<255>#else String #end;
	@:optional public var tel(default, null):#if server VarChar<255>#else String #end;
	public var dispos:Array<DateTime>;
}

@:pure
typedef NuDate = {
	@:autoIncrement @:primary @:optional public var id(default, null):Id<NuDate>;
	//@:autoIncrement  @:optional public var id(default, null):Id<NuDate>;
	public var date(default, null):DateTime;
	public var name(default,null):#if server VarChar<255>#else String #end;
}

@:pure
typedef NuDateJoin = NuDate & {
	user_id:Id<NuDate>
}

@:pure
typedef NuDateCheck = NuDate & {
	public var user_id:Id<User>;
	public var users:Array<Id<NuDate>>;
	public var prix:Float;
}

typedef Inscripted = {
	user_id:Id<User>,
	activity:Activity,
}


typedef Activity = {
	@:autoIncrement @:primary @:optional
	final id:Id<Activity>;
	final titre:#if server VarChar<255>#else String #end;
	final prix:Int;
	final cotisation:Int;
	final date:#if server VarChar<255>#else String #end;
	final details:#if server VarChar<255>#else String #end;
	final prof:#if server VarChar<255>#else String #end;
	//todo
	
	final duree:Int;
	final statut:#if server VarChar<255>#else String #end;
	
}





@:pure
typedef ActivityUser = {
	// @:autoincrement @:primary @:optional public var id(default,null):Id<UserDate>;
	public var activity_id:Id<Activity>;
	public var user_id:Id<User>;
}

/*
		---------- prototypes  -----------
	 */

interface Query {
	function afficheDate():Void;
	function afficheNextDate():Void;

	function login():Void;
	function logout():Void;
}

interface UserApi {
	function getUser():Promise<User>;
	function recUser(u:User):Promise<Noise>;
	function getUsers():Promise<Array<User>>;
	function deleteUser(u:User):Promise<Noise>;
}

interface NudeEventApi {
	function getNextDate():Void;
}

interface DoodleApi {
	function setPossibleDate(date:Date):Void;
	function getPossibleDates():Void;
	function getUserList():Void;
	function AssignUserToDate():Void;
	function listUsersbyDate():Void;
	function valideDoodle():Void;
}
