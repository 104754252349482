package glossaire;
import glossaire.GlossModel;
import sticker.EcritoLineInput;
using tink.CoreApi;
import fomantic.*;
import fomantic.Icon;
import glossaire.GlossApi.GlossDef;

class GlossaireComp extends coconut.ui.View{

    @:attr 
    var mod:GlossModel;

    // @:attr
    // var onChange:(String)->Promise<Noise>;

    

    function render()
        <div>
    <switch {mod.entries}>
    <case {Loading}>
    ...
    <case {Done(e)}>
    <AddMe act={n->mod.add(n)}/>
       {for ( a in e)
        
        <GlossMot GD={a} choose={mod.onChange }/>

        }
    <case {Failed(r)}>
    nope
    </switch>
        </div>;


    

}

class AddMe extends coconut.ui.View{

    
    @:attr
    var act:(String)->Void;

    var value:String;

    function render()
        <div>
        <Input value={value} onBlur={e->value=e}/>
        <Icon  img={IconName.plus_circle} act={e->act(value)} />
        </div>;

}


class GlossMot extends coconut.ui.View{

    @:attr
    var GD:GlossDef;

    @:attr
    var choose:(Int,String)->Promise<Noise>;

    function render()
    <div>
    <EcritoLineInput double= {true} text={GD.mot} choose={change.bind(_, GD.id ,GD.mot)}/>
    <Icon img={IconName.bug} act={e->trace("yo"+GD.mot)} />
    </div>;


    function change(b,a,orig){
        if( b=="" || b==null || b==orig) 
            return Promise.NOISE; 
        else
        return choose(a,b);
    }
}