
package cocomponents.dood.model;
using tink.pure.List;
import cocomponents.MayBool;
import app.Interfaces;

typedef MaybeUserDate={
user_id:Int,
date_id:Int,
state:MayBool
}

class UserDateList implements coconut.data.Model{
@:editable 
var rev:Int=0;

// @:computed
// var items:tink.pure.List<MaybeUserDate>= 
// switch userdates{
//    case Done(v):
//    v.map(ud->{
//       ud.state=switch(ud.state){
//          case 1: cast Sure(true);
//          case 0: cast  Sure(false);
//       }
//    });
//    case Failed(e):throw 'NuDateList'+e; 
//    case  Loading :[].fromArray();
//  }
 @:constant var server:tink.web.proxy.Remote<app.IRemoteRoot>;
 @:loaded 
 var userdates:tink.pure.List<UserDate>={rev;server.dood().userdates();};

}