package ical;
import thx.DateTimeUtc;
import ical.Cal;

class CalGen {
	public var events:Array<Cal>;

	public function new() {
		events = [];
	}

	public function createEvent(event:Cal):CalGen {
        events.push(event);
        return this;
	}

	#if tink_template
	public function render() {
		return ical.EventTemplate.render(events.map(e->e.toTemplate()));
	}
	#end

	public static function mockEvent():Cal {
		return {
			start: (Date.now():DateTimeUtc).addHours(3).toDate(),
			duration: "1:10:00",
			title: 'Bolder poim',
			description: 'Annual 10-kilometer run in Boulder, Colorado',
			location: 'Folsom Field, University of Colorado (finish line)',
			url: 'http://www.bolderboulder.com/',
			geo: {
				lat: 40.0095,
				lon: 105.2669
			},
			categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
			status: CONFIRMED,
			busyStatus: BUSY,
			organizer: ({name: 'Admin', email: 'Race@BolderBOULDER.com'}:User),
			attendees: ([
				{
					name: 'Adam Gibbons',
					email: 'adam@example.com',
					rsvp: true,
					partstat: ACCEPTED,
					role: REQPARTICIPANT
				},
				{
					name: 'Brittany Seaton',
					email: 'brittany@example2.org',
					dir: 'https://linkedin.com/in/brittanyseaton',
					role: OPTPARTICIPANT
				}
			]:Array<User>)
		}
	}
}
