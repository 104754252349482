package planning.mod;

import coconut.data.Model;
import planning.cross.Interfaces.Detail;
using tink.Anon;
using Mots;
class ModeleDetail implements  Model{



@:skipCheck
@:constant 
var api:planning.api.Serv.IServ ;


@:editable
public var nom:String=@byDefault "yanoo";

@:editable
public var active:Bool = @byDefault true;

@:editable
public var num:String =@byDefault "2";

@:editable 
public var img:String = @byDefault"poplo";

@:editable 
public var observations:String = @byDefault "observations";

@:computed
public var detail:Detail ={
    
    nom:nom,
    active:active,
    num:num,
    img:img,
    observations:observations
    };





@:transition
public function setActive(b:Bool){
    return Promise.lift({active:b}).next(
        n->{
         update(detail.merge({active:b}));
         n;
        }
    );

}

@:transition
public function setObs(b:String){
    trace(" setObs"+ b);
    return Promise.lift({observations:b}).next(
        n->{
         update(detail.merge({observations:b}));
         n;
        }
    );

}
@:transition
public function setImg(b:String){
    return Promise.lift({img:b}).next(
        n->{
         update(detail.merge({img:b}));
         n;
        }
    );

}
@:transition
public function setNum(b:String){
    //return { num:b };
    return Promise.lift({num:b}).next(
        n->{
         update(detail.merge({num:b}));
         n;
        }
    );

}

@:transition
public function setNom(b:String){
    
    return Promise.lift({nom:b}).next(
        n->{
         update(detail.merge({nom:b}));
         n;
        }
    );
  
}

public function sms(){
    var cleanTel= function(pho){
        var reg=~/[^\d+]+/g;
       return  reg.replace(pho,"");
   } 

   var nom = nom.capitalize();
   
   var text='Bonjour $nom , En ce debut de nouvelle année 2023 ( que je te souhaite bien bonne ), c\'est aussi l\'heure de refaire un planning de modèle vivant pour NuEnSlip. Est ce que tu pourrais me faire parvenir tes dispos pour ce semestre ( jusqu\'a fin juin ). je te ferai des propositions de dates sur la base de tes dispos dans les jours à venir. Les séances on toujours lieu les mardis de 19h30 à 21h30 à mon atelier rue Pargaminières.
   A trés bientôt et au plaisir de te dessiner. 
   David pour nuEnSlip.';
   js.Browser.navigator.clipboard.writeText(text);

   js.Browser.window.location.href='imessage://${cleanTel(num)}';


}

function update(detail:Detail){
    trace ("update");
    
     api.remote.plan().detailUpdate( cast detail).next(
        detail->new ModeleDetail( cast detail)
            .Log('updating detail')
            
    ).handle(f->f);
     
}



}