package ecrito;
import tink.pure.Mapping;
using tink.pure.List;
import tink.core.Signal;
using StringTools;

@:pure
typedef ResultLine={
    id:String,
    val:Array<String>,
}

@:pure
typedef ResultModel=tink.pure.Mapping<String,Array<String>>

class EcritoModel implements Model{


    @:constant
    var data:String;

   
    @:computed
    // var lines:tink.pure.List<EcritoLineModel> = parseJson(data);
    var lines:tink.pure.List<EcritoLineModel> = parseTexto(data);

    @:computed 
    var stringy:String= getAsString(resultModel);

    @:computed
    public  var resultModel:ResultModel=getAsResult(lines);


    static var st:SignalTrigger<ResultModel>=Signal.trigger();

    @:editable
    var ok:Bool=@byDefault false;

   
    static public var signal:tink.core.Signal<ResultModel>=st.asSignal();


   public static  function uuid(phrase:String) {
        phrase= StringTools.replace(phrase,",","");
        var tab= phrase.split(" ");

        var p=[];
         tab.map(s->{
            p.push(s.substr(0,1));
            p.push(s.substr(-1,1));
        });
        return p.join('');
    }

    public function getAsString(model:ResultModel):String{
        var buf:StringBuf= new StringBuf();
        for( key=>val in model)
            buf.add('$key : ${val.join("/")} </br>');
        return buf.toString();
    }

    public function getAsResult(lines:List<EcritoLineModel>){

        trace( "popopo");
        var res=new Mapping();
        var mm= new Map();
        for ( line in lines){
            mm.set(line.id,line.result.val);
        }
        res=mm;
        if(ok)
        st.trigger(res);
        return res;
    }

  
    
    

    function parseTexto(data:String){
        data= data.trim();
        
        var bracketereg:EReg=~/\[(.*?)\]/;
        var lines= data.split("\n").Log();
        var _lines= new tink.pure.List();

        var parse=function(line:String){
            line.Log("line");
            var multiple:Bool=false;
            var editable:Bool=false;
            var ismail:Bool=false;
            var id="rien";
            if (bracketereg.match(line)){

                id=bracketereg.matched(1).Log("id");
                if (StringTools.contains(id,"+")) multiple=true;
                if (StringTools.contains(id,"@")) ismail=true;
               line=StringTools.replace(line,bracketereg.matched(0),"");

            }

            var frag:Array<String>=line.split("|");
          
            var textIn= frag.shift();
            var textOut= "";
            var choices=[];

            var cur=0;
            if( frag.length>0 ){
                while (cur<frag.length-1){
                   
                    if( frag[cur].trim()=="..."){
                        choices.push("...");
                        editable=true;
                    }
                    
                    else
                    choices.push(frag[cur].trim());
                    
                    cur++;
                 }
                textOut=frag[cur++];
            }else{
                //textIn="bim";
               // textOut=frag[cur++];
                choices=[null];
            }

            return   new EcritoLineModel({
                textIn:textIn.Log("textIn"),
                editable:editable,
                textOut:textOut.Log("textout"),
                choices:choices.Log("choices").fromArray(),
                multiple:multiple,
                ismail:ismail,
                id:id
            });

        }
        for( a in lines)
           _lines=_lines.append( parse(a));
        return _lines;
    }


    function parseJson(data:String){
        var ob=haxe.Json.parse(data.Log());
        var _lines= new tink.pure.List();
        for ( n in (ob.lines:Array<Dynamic>)){
            var lineMod= new EcritoLineModel({
                textIn:(n.textIn:String).Log(),
                textOut:(n.textOut:String).Log(),
                choices:(n.choices:Array<String>).Log().fromArray(),
                multiple:n.multiple,
                editable:n.editable,
                id:n.id
            });
            _lines=_lines.append(lineMod);

        }
        return _lines;
    }
    @:transition
    function onOk(){
        return {ok:true};
    }


    



}




class EcritoLineModel implements Model {

    @:constant
    var choices:tink.pure.List<String>;

    @:constant
    var multiple:Bool=@byDefault false;

    @:constant
    var textIn:String;
    @:constant
    var textOut:String;

    @:constant
    var editable:Bool=@byDefault false;

    @:editable
    var result:ResultLine=@byDefault {id:id,val:[""]};

   @:constant
   var id:String;

   @:editable
   var notGoodEmail:Bool=@byDefault false;

   @:constant
   var ismail:Bool=@byDefault false;

    @:transition
    function choose(id:String,val:String):Promise<Noise>{
        var temp=result.val;
        if(ismail)
            valideMail(val);
        if( multiple){
        if (temp.contains(val))
            temp.remove(val);
        else
            temp.push(val);
        }else{
            temp=[val].Log('cest ça');
        }
        return {result:{id:id,val:temp}.Log("choose")};
    }

    @:transition
     function valideMail(str){
        var erg=~/[[\w.-]+@[\w\.-]+.\w{2,4}/g;
    
        return {notGoodEmail :! erg.match(str).Log("match email")}
    }

}