package planning.mod;

import planning.mod.Modele;
import planning.api.Serv;
using tink.CoreApi;
using tink.pure.List;
import fomantic.Dropdown.NamedSel;
import tink.state.Promised;
import planning.mod.SessionCalc.CoolDate;

class Modeles implements coconut.data.Model{

    @:skipCheck
    @:external
    public var sessions:Array<CoolDate>;

    @:skipCheck
    @:constant
    public var server:IServ;

    @:editable
    public var poserList:List<Modele>= @byDefault [];

    @:editable
    public var selecteds:List<Modele>=@byDefault [];

    @:editable 
    var rev:Int=0;

    @:constant
    var batch:List<Modele>->Void;

    @:computed 
    public var modeleListNamed:List<NamedSel<String>>=
    {
        switch modeleList{
            case Done(t):
                
                t.map(n->{
                    new NamedSel(n,n);
                    }
                    );

            case _:[].fromArray();
        };
    }

    @:transition
    public function select(active:Bool,name:String){
       var opt=poserList.first(n->n.nom==name);
       var liste=null;
       switch opt{
        case Some(s):
        switch (active){
                case true:
                 liste=selecteds.append(s);
                case false:
                    liste=selecteds.filter(n->n.nom!=s.nom);
        
        }
        trace( liste.count(e->e!=null));
        return {selecteds:liste}
        case None:
            return {selecteds:selecteds};
       }
    }

    public function send(){
        batch(selecteds);

    }


    
    @:loaded 
    public var modeleList:List<String>= {
        rev.Log("rev");
        server.getModeles()
        .next(p->
            p.map(n->{
                addModele(createModele(n.nom,n.filtre));
                n.filtre;
                n.nom;
            })  
        );
    }


    function new(){

        trace( "-------------------------------new");

        this.observables.poserList.bind(e->e.map(n->{

            trace("poserList changed");
            n.Log("popo").observables.filtre.bind(e->{
                Std.string(e).Log("binded");
                update();

                server.saveModeles(poserList.Log("saving"));
                });
            }
        )
        );
    }

    

    @:editable
    var editModeleName:String=@byDefault "bob";

    @:transition
    public function flush(){
        trace("Modeles flush");
        for ( modele in poserList)
            modele.flush();
        return {poserList:poserList};

    }
    

    @:transition 
    public function addModele(m:Modele){
       // trace( "addModele");
        if( poserList.exists(_m->_m.nom==m.nom)){
          //  trace(m.nom+ " exists");
           // return {poserList:poserList.replace(_m->_m.nom==m.nom,_m->_m) ,clearMe:true};
           return {rev:rev};
        }
       // m.observables.filtre.bind(e->{Std.string(e).Log("updtd");});
       //return {rev:rev};
        return {poserList:poserList.append(m) ,clearMe:true};
        //.next(m->m);
    }
 
    @:transition 
    public function addModeleName(m:String){
        trace( "addModeleName" + poserList);
        var poser=createModele(m);
        // if(poserList.exists(_m->_m.nom==m))
        //     return Promise.NOISE;

        
        addModele(poser);
       return  @patch {rev:rev+1};
       //return  server.saveModeles(poserList.map(m->m.nom).Log("saving"))
    //    return  server.saveModeles(poserList.Log("saving"))
       
    //     .next(f->@patch {
    //         rev:rev+1
    //     });
       
        
    }

    @:transition 
    public function remModeleName(m:String){
        trace( "remModele");
        return { poserList:poserList.filter(n->n.nom!=m) };
    }

    
    @:transition
    public function setEditModeleName(s:String){
        return @patch{ editModeleName:s};
    }

    public function getModeleByString(m:String):Modele{
        return poserList.first(p->p.nom==m).sure();
    }

    public  function getModeles():Array<Modele>{

        trace( "genModeles");
        poserList.map(t->{t.nom.Log("poserList1");t.filtre.toString();});
        //return SessionCalc.genModeles().concat(poserList.toArray());
        return poserList.toArray().Log("poserListe2");

    }

    @:transition
    function update(){
        return  {rev:rev+1};
    }

    public function createModele(s:String,?dispo:Dispo):Modele{
       
        trace( "createModele");
       
        dispo=(dispo==null)? null :dispo;
        // var poser=new Modele(s,Dates([]));
         var poser=new Modele(s,dispo);
       
        
        return poser;

    }


}