package command;

#if client
import js.Browser.document as doc;
import coconut.Ui.hxx;
import js.jquery.Helper.*;
#end
using tink.pure.List;
using tink.CoreApi;
import app.IRemoteRoot;
import cocomponents.*;
import userManager.model.UserManagerModel;
import userManager.comps.UserManager;

@:keep
class UserManagerCommand implements poscope.wire.ICommand<Noise>{

    var remote:tink.web.proxy.Remote<IRemoteRoot>;
    public function new(r) {
		remote = r;
	}

   #if client
    public function execute<T>(?data:T):Promise<Noise>{
         trace( "execute cocomanager command");
         trace(untyped  remote.headers);
        var landing=doc.querySelector(untyped (data.landing));
        var USM= new UserManagerModel({remote:cast remote});
        coconut.ui.Renderer.mount(landing,
            
              hxx('
              <div>
                <UserManager model={USM}/>
              </div>
              ')
           
        );
        
        return Promise.lift(Noise);    
    }

    function init(){
        trace("init");        
    }

    #end

}