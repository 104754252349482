package sticker;
import fomantic.Dropdown.NamedSel;
using thx.Dates;
using thx.TimePeriod;
using thx.Weekday;


typedef TerreData={
    final date:Date;
    final sessionNum:Int;
    final modele:String;
    final sessionType:TerreType;
    final background:String;
    final hashtag:String;
    final duree:Int;
}

@:forward
enum abstract TerreType(String) from String to String{

var nu;
var buste;


public static function toValues():Array<NamedSel<String>>{
    return [
        new NamedSel(nu,nu,true),
        new NamedSel(buste,buste,true),
        // new NamedSel(poseLongue,poseLongue,false),
        // new NamedSel(modelage,modelage,false),
        // new NamedSel(peinture,peinture,false),
        // new NamedSel(poseA2,poseA2,false),
        // new NamedSel(bizarre,bizarre,false),
    ];
}

}


class TerreModel implements coconut.data.Model{

    @:editable
    public var rev:Int=0;

    @:editable 
    public var modele:String;

    @:editable
    public var hashtag:String;

    @:constant
    public var background:String=@byDefault "";

    @:editable
    public var nb_participants:Int=4;

    @:editable
    public var sessionNum:Int=@byDefault 1;

    @:editable
    public var date:Date=@byDefault Date.now().snapNextWeekDay(Weekday.Sunday).withHour(16).withMinute(0);


    @:editable
    public var sessionType:TerreType= @byDefault nu;

    @:editable
    public var duree:Int= @byDefault 2;

    

    @:computed
    public var rawData:TerreData={
        return ({
            background:background,
            sessionNum:sessionNum,
            date:date,
            sessionType:sessionType,
            modele:modele,
            hashtag:hashtag,
            duree:duree
        }:TerreData);
    }

    // @:attr
    // var onChanged:Void->Void;

    @:computed
    public var dateString:String={
        var days=[for (n in "dimanche/lundi/mardi/mercredi/jeudi/vendredi/samedi".split("/")) n];
        var mois=[for (n in "janvier/février/mars/avril/mai/juin/juillet/aout/septembre/octobre/novembre/decembre".split("/")) n];

        return DateTools.format(date, 'le  ${days[date.getDay().Log("jour de la semaine")]} %d ${mois[date.getMonth()]} %Y à ${date.getHours()}h${date.getMinutes()}');
    
    }

    @:computed 
    public var prixSession:Int={
        trace( "hop");
        var result=Math.ceil(prix_modele/nb_participants);

        result=(result<5)? 5 :result;
        
        return result;
        
    }

    @:editable
    public var prix_modele:Int=@byDefault 50;

    @:transition
    public function setModele(s:String){
        return {modele:s ,rev:(++rev)};
    }


    @:transition
    public function setDuree(s:Int){
        var _s=(duree+1) %4;//max participants
        return {duree:_s, rev:(++rev)};
    }


    @:transition
    public function setPrixModele(s:Int){
        trace( "set prix" + s);
        var _s=(prix_modele+1) %81;//max participants
        return {prix_modele:_s, rev:(++rev)};
    }


    @:transition
    public function setNum(s:Int){
        //trace( "setNum");
        var _s=(nb_participants+1) %15;//max participants
        return {nb_participants:_s,rev:(++rev)};
    }
    @:transition
    public function setHash(s:String){
        return {hashtag:s,rev:(++rev)};
    }

    @:transition
    public function setSessionNum(s:Int){
        trace( "seesion num ...");
        var _s=(s+1);
         return {sessionNum:sessionNum+1,rev:(++rev)};
    }
    @:transition
    public function setDate(s:Date){
        //var _s=(s+1);
         return {date:s,rev:(++rev)};
    }

    @:transition
    public function setSessionType(d:TerreType){
        return {sessionType:d,rev: (++rev).Log('rev')};
    }
    

    public function new(){
        trace( "ey hop ! ");
        //haxe.Timer.delay(setPrixModele.bind(prix_modele),100);
    }


    public function export():String{
        return tink.Json.stringify(rawData);
    }

}