package planning.api;

import planning.cross.IPlanningRoute;
import thx.Uuid;
import thx.DateTimeUtc;

import ical.Cal;
import planning.cross.IPlanningRoute;
import tink.web.proxy.Remote;
import planning.mod.SessionCalc.CoolDate;
class CalApi {

    var cal:ical.CalGen;
    var remote:Remote<app.IRemoteRoot>;

public function new(remote){
    this.remote=remote;
    cal=new ical.CalGen();
}

public function addEvent(k:CoolDate,v) {

    var  kk=new Date(Date.now().getFullYear(),k.getMonth(),k.getDate(),19,30,0);

    var event:Cal={
        uuid:'5FC53010-${Std.random(5000)}-4F8E-BC28-1D7AE55A7C99',
        start:kk,
        duration:"02:00",
        title: '$v pour nuEnSlip',
        description: 'session modèle vivant',
        location: 'atelier nuEnSlip 66,rue Pargaminières',
        geo:{ lat:43.604606, lon:1.440427 },
        url: 'http://www.atelier.postite.com',
        categories:['dessin','atelier'],
        status:TENTATIVE,
        busyStatus:BUSY,
        organizer:{name:'david',email:'david@postite.com',role:REQPARTICIPANT},
    }

    cal.createEvent(event);
}

#if tink_template
#if !noIcal
public function generate():Promise<Noise>{
    //var remote = new Remote<IRootServer>(new JsClient(), new RemoteEndpoint(new Host('localhost', 8080)));
    var calResult=cal.render();

    return remote.saveCal({content: cal.events}).handle(e->{
        trace(e);
    });

   // var calResult=cal.render();
   // return calResult;
}
#end
#end

public function send(){
    #if !noIcal
    remote.genCal({content:cal.events}).handle(e->{
        trace(e);
    });
    #end
}

}