#if client

import js.html.*;
#end 

class Tools{


#if client
   
        public inline static function toArray(nodelist:js.html.NodeList):Array<js.html.Element> {
          return [for (node in nodelist) cast(node, Element)];
        }
    
#end

}