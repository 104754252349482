package app;
using DateTools;
import haxe.Unserializer;
import js.Browser.document as doc;
import coconut.vdom.Html;
import app.Interfaces;
import coconut.Ui.hxx;
using tink.CoreApi;
import tink.web.proxy.Remote;
import tink.http.clients.JsClient;
import tink.url.Host;
import fomantic.*;
import fomantic.Icon;
import app.IRemoteRoot;

using Debug;

import poscope.wire.ActionCommand;

class App{
    
    
    var remote:Remote<IRemoteRoot>;

    public function new(){

        trace( "new APpp");
        var here =doc.location.hostname;
        if( ~/local/.match(here)){
			//Compiler.define("local",true);
			Config.PORT=Config.localPort;
			Config.IP=Config.localIP;
		}
        var url:tink.Url= Std.string(doc.location);
        //remote = new Remote<IRemoteRoot>(new JsClient(), new RemoteEndpoint(new Host(Config.IP, Config.PORT)));
        remote = new Remote<IRemoteRoot>(new JsClient(), new RemoteEndpoint(url.host));
        
        doc.addEventListener("DOMContentLoaded",execute);
		
    }

	public function execute(){
		trace( "execute"+ untyped actions);
        //actions are a var in the htmlPage
		var t:Map<ActionCommand,Any>= 
		try
		haxe.Unserializer.run(untyped actions)
		catch(msg:Any){
			var z= StringTools.htmlUnescape(untyped actions);
			haxe.Unserializer.run(z);
			//msg.Log();
		}
		if (t==null) return;
		for( a in t.keys()){
			var b=Std.string(a).Log("action en course");
			a.Log('a');
			Type.createInstance(a.toClass(),[remote])
				.execute(t.get(a))
				.handle(h->h.Log('client execute :$b'));
		}
	}
    static function main(){
		CompileTime.importPackage("command");
		CompileTime.importPackage("mdpaper.command");
		CompileTime.importPackage("sessionsModule.command");
		CompileTime.importPackage("planning.command");
		CompileTime.importPackage("glossaire.command");
		new App();

	}
   

  
     
}

