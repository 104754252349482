package cocomponents;

import fomantic.Icon;

import tink.pure.List;
import fomantic.Search;
using tink.state.Promised;
@:pure
typedef Opt={
    public var value(default,never):String;
    public var title(default,never):String;
}
class Adder extends coconut.ui.View{

    
    @:attr public var add:(String)->Void;
    //@:attr public var suggest=(String->Promise<List<String>>);
    //@:state public var suggestions:List<String>=;
    @:state var selected:String="pick or write";
    @:attr var options:List<Opt>;
    @:ref var search:Search;
    

   
    
    function autoSelect(e:js.html.Event){
        selected=untyped e.currentTarget.value;
        //dropdown(suggest(value);
    }
    // function dropdown(t:List<String>):String{
    // }
    function more(e:js.html.Event){
        var res=search.value;
        trace("res="+res);
        add(res.title);
    }
    function render(){
    //trace("rendering!!! adder" +options);
       return  <div class="adder">

        <Search ref=${search} content={options} />
        <Icon  img={IconName.plus_circle} act={more} />
        </div>;

    }
}

/*

<input type="text" value={selected}  onchange={autoSelect} placeholder="nom..."/>
      <select  onchange={(e) -> selected=untyped(e.target).value }>
        <for {sel in options}>
            <option key={sel.value} value={sel.value}>{sel.display}</option>
        </for>
       
      </select>
      <Icon img={IconName.plus_circle} act={more} />



*/