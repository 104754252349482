package glossaire;

import glossaire.GlossApi.GlossDef;
import planning.mod.Modele;
import planning.api.Serv;
using tink.CoreApi;
using tink.pure.List;
import fomantic.Dropdown.NamedSel;
import tink.state.Promised;
import planning.mod.SessionCalc.CoolDate;
import glossaire.GlossApi.GlossDef;
import app.IRemoteRoot;

class GlossModel implements coconut.data.Model{

    @:editable
    var up:Int=0;

    @:constant
    var remote:tink.web.proxy.Remote<IRemoteRoot>;

    @:loaded
    var entries:tink.pure.List<GlossDef> = {
        up;
        remote.gloss().all();
    }

    @:transition
    public function add(n:String){
        return remote.gloss().create(n)
        .next(r->{up:up+1 });
    }

    @:transition
    public function onChange(id:Int,s:String):Promise<Noise>{

        trace( "changed" +s);
        
        return remote.gloss().update(id,s).next(
            r->{up:up+1 }
        );
        
    }






}