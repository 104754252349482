package command;

import js.html.ButtonElement;
using tink.CoreApi;

#if client
import js.Browser.document as doc;
using tink.CoreApi;
import coconut.Ui.hxx;
import coconut.ui.Renderer;
import js.jquery.Helper.*;
import tink.Url;
import app.IRemoteRoot;
import sticker.*;
using tink.core.Promise;
import thx.DateTime;
import domToImage.*;
using thx.Dates;

#end
class StickerCommand implements poscope.wire.ICommand<Noise>{

    #if client
    var remote:tink.web.proxy.Remote<IRemoteRoot>;
    public function new(r) {
		remote = r;
	}
    public function execute<T>(?data:T):Promise<Noise> {
      trace( "sticker command execute");
        //var mapmod=ModelMap.normalize(SessionCalc.mix(SessionCalc.genModeles(),SessionCalc.genSession()));
        //var mods = new ModelMap({map:mapmod});
        trace( "yo"+remote);
        var url:tink.Url=js.Browser.location.href;
        trace( data);
       // trace(  untyped(data).date= Date.fromString(untyped(data).date));
        var _data:Ecchymodel.RawData= untyped data;
        var mods = new Ecchymodel({
            modele:_data.modele,
            hashtag:_data.hashtag,
            sessionNum:_data.sessionNum,
            sessionType:_data.sessionType,
            background:_data.background,
            date:Date.fromTime(untyped data.date)
           // background: "https://api.twitter.com/1/statuses/oembed.json?url=https://pbs.twimg.com/media/FKUgysjX0AA0jwl?format=jpg"
        }); 
        
        var fixe:js.html.Element=null;

        var pictureIt=function(container){
            DomToImage.toPng(container).toPromise()
            .next(e->{
                var img= doc.createImageElement();
               // img.src=e;
                img.src=e;
                fixe.Log();
                fixe.replaceChild(img,fixe.firstElementChild);
                }
                )
            .handle(r->r);
        };
       // tink.Json.stringify(mods).Log("pipo");
      // var nextTuesday=new DateTimeUTC(Date.now()).snapNextWeekDay(WeekDay.Tuesday).jump(TimePeriod.)
       // doc.addEventListener("DOMContentLoaded",e->{
            var container= doc.querySelector(".landing");
            fixe=doc.createElement("div");
            var img= doc.createImageElement();
            fixe.appendChild(img);
            // doc.body.appendChild(fixe);
            trace( container.parentNode );
            trace( "loaded");
            var tt= hxx('<Ecchymose {...mods} />');
            //var tt= hxx('<Bim />');
            coconut.vdom.Renderer.mountInto(container,tt);

            container.after(fixe);

            var but:ButtonElement= doc.createButtonElement();
            but.addEventListener("click",e->remote.stickexport(mods.export().Log("export")));
            container.after(but);

            trace( fixe.parentNode);
            mods.observables.rev.bind(e->{
                e.Log("binded");
                mods.prixSession.Log("prix");
                pictureIt(container);
                }
            );
            
       // });
        return Promise.NOISE;
    }
    #end

    public static function launch(session:Date,choix:String){
        js.Browser.window.location.assign('/sticker/${session.getTime()}/$choix');
    }
}

#if client
class Bim extends coconut.ui.View{

    function render()
        <div>
         zzazeze
        </div>
        ;
    
}
#end