package planning.comps;
import planning.mod.Modele;
using tink.CoreApi;
import fomantic.*;
import fomantic.Icon;
import tink.state.Promised;
import planning.mod.ModeleDetail;
import planning.mod.ModeleDetailMap;

class ModeleDetailView extends coconut.ui.View{


    public function new(){
        trace( "new ModelView");
    }

    // @:attr
    // var mod:Promised<ModeleDetail> ;

    @:attr
    var nom:String;

    @:skipCheck
    @:attr
     var api:planning.api.Serv.IServ;


    // @:attr
    // var active:Bool;

    // @:attr
    // var num:String;

    @:state
    var mod:ModeleDetail= new ModeleDetail({
        nom:nom, 
        active:false, 
        num:"",
        observations:"observations",
        img:"img",
        api:api
    });

    @:attr
    var onClose:Void->Void=@byDefault ()->{};

    


    function viewDidRender():Void{
        trace( "my name is :"+ nom);
     new ModeleDetailMap({api:api, nom:nom}).getDetail(nom).next(
           n-> {
            trace( "help"+n.nom);
            return mod=n.Log("mod");
           }
       ).handle(f-> f);
        

    }


    public function render()
        <div>
    
    <div onclick={onClose}> <Icon img={arrow_circle_left} /></div>
    <Input type={text} value={mod.nom}  onBlur={mod.setNom}/>
    <Input type={tel} value={mod.num} onBlur={n->mod.setNum(n)} />
    <TextArea  value={mod.observations} onBlur={n->mod.setObs(n)} />
    <Input type={text} value={mod.img} onBlur={n->mod.setImg(n)} />
    <Icon img={phone} act={e->mod.sms()} />
    <CheckBox checked={mod.active} text="active" onChange={_->mod.setActive(!mod.active)}/>
    
   
        </div>;

}