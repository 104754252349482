package sessionsModule;

//import sessionsModule.ISessionApi.SessionAct;
import coconut.data.Model;
using tink.CoreApi;
import sessionsModule.ISessionApi.SessionAct as Session;
import sessionsModule.ISessionApi.Activity;
import sessionsModule.SessionApi.MockSession;
using tink.pure.List;
import tink.web.proxy.Remote;
import tink.state.Promised;


class SessionsModel implements Model{

    // @:computed
    // var tagsModel:TagsModel = new TagsModel({remote:this.remote});

    // @:external
    // var tags:Promised<List<EvtTag>>= @byDefault tagsModel.tags;
    
    @:editable
    public var rev:Int=0;

    @:constant
    var remote:Remote<app.IRemoteRoot>;
    
    @:editable
    var action:String="all";
    
    // @:editable
    // var tag:Int=0;

    @:transition
    public function refresh() {
        return {rev:rev++};
    }
    
    @:loaded
    public var sessions:List<Session> = {
        rev;
        remote.sess().all().next(arr->arr.fromArray());
    }



    @:computed
    var mods:Promised<List<SessionModel>>={
      
        sessions.map(
            _sess->_sess.map(ses->{

                var t= new SessionModel({
                    sess:ses,       
                    close:delete,
                    create:create
                }).Log("rrdddddrrrr");
                t.observables.sess.bind(_sess->{
                    _sess.Log("ettt");
                    if( _sess!=ses)
                    update(t);
                });
                t;
                }
                 )
        );
    }
    // @:transition
    // public function navi(id:String){
    //     return {action:id ,rev:rev++};
    // }

    // @:transition
    // public function navigueTag(i:Int,e:Int){
    //     trace("navigueTag "+ i + "/"+e);
    //     return {tag:e,rev:rev++};
    // }

    var setAct:(Int,Int)->Void;


    @:transition
    public function create(e:Session){
        // var mock= agenda.AgendaApi.MockEvt.mock("benz");
        // mock.Log("pp");
        
       return  remote.sess().create(e)
       
       .next(
            n->@patch{rev:rev+1}.Log("created")
        );
        
    }

    @:transition
    public function delete(id:Int){
       return  remote.sess().delete(id).next(
            n-> @patch{rev:rev+1}
        );
    }

    @:transition
    public function update(e:SessionModel){
         trace( "update");

        return remote.sess().update(e.sess)
        .next(
            n->@patch{rev:rev+1}
        );
    };

    public function createModel():SessionModel{
       
       return new SessionModel({ 
       // tags:tagsModel,
        sess:null,
        close:delete,
        create:create,
       // upModel:new UpModel({remote:this.remote})
    });
    
    }

}

class SessionModel implements Model{

    // @:external
    // var tags:TagsModel;

    // @:constant
    // var upModel:UpModel= @byDefault new UpModel({remote:null});

    @:editable 
    var sess:Session;

    @:observable
    var close:Int->Promise<Noise>;

    @:constant
    public var create:Session->Promise<Noise>;



    public function new(){

        if(this.sess==null) 
             createSession();

        // upModel.observables.paths.bind(n->{
        //     trace( '---------------${evt.img} & ${n.thumb}');
        //     if( evt.img!=n.thumb && n.thumb!=null)
        //      modall({img:n.thumb});
        // }
             //n.Log('pathhh')
           // );

    }


    @:transition
    public function createSession(){
        return {sess:MockSession.mock("new Event").Log("new Event")};
    }



    @:transition
    public function modall(val:Dynamic){
        trace("modal"+val);
        var mody:Session=mergeAnon(sess,val);
         mody.Log("mody");
        //var muddy:Evt= ObjectTools.with(evt,{titre:val});
        return {sess:mody};
    }


    private function mergeAnon<T,B>(ano1:T,ano2:B):T{
        var t:T=cast {};
        //pass1 copy 
        for( a in Reflect.fields(ano1))
            Reflect.setField(t,a,Reflect.field(ano1,a));
        //pass2 merge
        for( a in Reflect.fields(ano2))
            Reflect.setField(t,a,Reflect.field(ano2,a));
        return t;
    }
    

}





/*
class TagsModel implements Model{

    @:constant
    var remote:Remote<cross.ICrossRoot>;
    
    @:editable
    var rev:Int=0;

    @:skipCheck
    @:loaded
    var tags:List<SessionTagged>={
        rev;
        remote.sess().taggeds().next(
            t->List.fromArray(t).append({id:0,name:"all",evts:[]})
        );
    }

    @:transition
    function  select(evt_id:Int,tag_id:Int){
        trace ("select");
       return  remote.ses().taggue(evt_id,tag_id)
        .next(n->
            {rev:rev+1}
         );
    }

    @:transition
    function  create(name:String){
        trace(" create");
        return remote.agenda().createTag(name)
        .next(n->{rev:rev+1});
    }






    

}
*/