package sessionsModule;


import app.Interfaces.Activity;
import coconut.data.Model;
using tink.CoreApi;
import sessionsModule.ISessionApi.Session;
import sessionsModule.ISessionApi.Activity;
import sessionsModule.SessionApi.MockSession;
using tink.pure.List;
import tink.web.proxy.Remote;
import tink.state.Promised;


class ActivitiesModel implements Model{

    @:editable
    var rev:Int=0;

    @:constant
    var remote:Remote<app.IRemoteRoot>;

    @:loaded
    var acties:List<Activity>={
        rev;
        remote.sess().allActivities().next(
            t->t.fromArray().Log('acties')
            );
        
    }

    @:computed
    var mods:Promised<List<ActivityModel>>={
      
        acties.map(
            _act->_act.map(act->{
                var t= new ActivityModel({
                    act:act,       
                    close:delete,
                    create:create,

                    
                }).Log("rrdddddrrrr");
                t.observables.act.bind(_act->{
                    _act.Log("ettt");
                    if( _act!=act)
                        update(t);
                });
                t;
                }
                 )
        );
    }


    @:transition
    public function create(e:Activity){
        // var mock= agenda.AgendaApi.MockEvt.mock("benz");
        // mock.Log("pp");
        e.Log("activity");
       return  remote.sess().createActivity(e)
       
       .next(
            n->@patch{rev:rev+1}.Log("created")
        );
        
    }

    @:transition
    public function delete(id:Int){
       return  remote.sess().deleteActivity(id).next(
            n-> @patch{rev:rev+1}
        );
    }


    @:transition
    public function update(e:ActivityModel){
         trace( "update");

        return remote.sess().createActivity(e.act)
        .next(
            n->
                @patch{rev:rev+1}
                
        );
    };


    @:transition
    function  select(sess_id:Int,act_id:Int){
        trace ("select");
       return  remote.sess().addActivity(sess_id,act_id)
        .next(n->
            {rev:rev+1}
         );
    }

    public function createModel():ActivityModel{
       trace( "createModel");
        var neoModel= new ActivityModel({ 
        // tags:tagsModel,
         act: null,
         close:delete,
         create:create,
         createMock:createMockActivity
        });
       
        return neoModel;
     }

    //  @:transition
    // public function createActivity(){
    //     return {act:createMockActivity("mock Activity").Log("new Event")};
    // }
    


    function createMockActivity(titre:String):Activity{
        trace( "createMockActivity");
        return {
            id:null,
            titre:titre,
            prix:10,
            cotisation:10,
            date:'vendredi',
            details:"details",
            prof:"david",
            duree: 2,
            statut:"bof",
        };
    }

    // @:transition
    // function  create(name:String){
    //     trace("create");
    //     return remote.agenda().createTag(name)
    //     .next(n->{rev:rev+1});
    // }

}

class ActivityModel implements Model{

    @:editable 
    var act:Activity=@byDefault null;

    @:observable
    var close:Int->Promise<Noise>;

    @:constant
    public var create:Activity->Promise<Noise>;

    @:constant 
    var createMock:String->Activity=@byDefault function(a){return cast{titre:"pop"};};

    public function new(){
        trace("new" + act);
         if(this.act==null) 
             createActivity();

        // upModel.observables.paths.bind(n->{
        //     trace( '---------------${evt.img} & ${n.thumb}');
        //     if( evt.img!=n.thumb && n.thumb!=null)
        //      modall({img:n.thumb});
        // }
             //n.Log('pathhh')
           // );

    }

    @:transition
    public function createActivity(){
        trace( "createActivity");
        return {act:createMock("...").Log("new Event")};
    }

   

    @:transition
    public function modall(val:Dynamic){
        trace("modal"+val);
        var mody:Activity=cross.Tools.mergeAnon(act,val);
         mody.Log("mody");
        //var muddy:Evt= ObjectTools.with(evt,{titre:val});
        return {act:mody};
    }

   


    

}