package planning.api;


import js.Browser;
using tink.CoreApi;
import tink.http.clients.*;
import tink.web.proxy.Remote;
import tink.url.Host;
using tink.io.Source;
using tink.pure.List;
import planning.mod.Modele;
using tink.io.Source;
import planning.mod.SessionCalc.CoolDate;
import planning.cross.IPlanningRoute;
import planning.cross.Interfaces;

typedef DisposMap=tink.pure.Mapping<String,List<String>>;

typedef ModeleDetail=planning.cross.Interfaces.Detail;

class Serv implements IServ{


    var mm:Map<String,String>=[ 
     //"22/09" => "anaïs", 
    // "03/11" => "thomas", 
    // "01/09" => "juanita", 
];
    var  mock:String="";

    // public static final remote = new tink.web.proxy.Remote<IPlanningRoute>(
    //     new JsClient(), 
    //     new RemoteEndpoint(
    //             new Host('localhost', 8080)
    //         )
    //      );

    public var remote:tink.web.proxy.Remote<app.IRemoteRoot>;

    var moo:Map<String,String>;

    public function new() {
        mock=tink.Json.stringify(mm);
    }


    public function saveDispos(map:DisposMap){
        return Promise.NOISE;
    }

    public function getRez():Promise<Map<String,String>>{
        trace( "getRetz");
          //  if( moo!=null)
           // moo=tink.Json.parse(Browser.getLocalStorage().getItem("moo"))
           return remote.plan().getBak() 
           
            .next(n->{
               return  n.body.all().next(
                    r -> moo = tink.Json.parse(r.Log("parse ok") )
                );
            }).tryRecover(r->{

                trace ('get rets failed ');
                throw ("olala "+ r);
            });
           // moo=tink.Json.parse(mock)
        // else{
        // moo=tink.Json.parse(mock.Log('mock'));
        // return Promise.lift(moo.Log("serv moo"));
         //  }
    }

    public function setRez(d:CoolDate,m:String):Promise<Noise>{
        trace( "moo");
        trace( moo);
        if(m == "")
        moo.remove(d);
        else
        moo.set(d,m.Log("set"));



        var moox=tink.Json.stringify(moo);
        Browser.getLocalStorage().setItem("moo",moox);

        return remote.plan().saveBak({content:moox})
            .next(e->{e.Log("onSave");
            Noise;
        });
    }

    public function flushBak():Promise<Noise>{

        return remote.plan().flushBak().next(e->e.Log("onFlushed"));
    }

    public function flushDispoz():Promise<Noise>{
        return remote.plan().flushDispoz().next(e->e.Log("onFlushDisposed"));
    }


    public function saveModeles(liste:List<Modele>){
        trace( "save modeles");
        //liste.toArray().map(m->m.filtre.Log('saveMod'));
        var mods= tink.Json.stringify(liste);
        return remote.plan().saveMods({content:mods})
            .next(e->{e.Log("onSave");
                     Noise;
             });
    }

    public function getModeles(){
        trace( "get modeles");

        return remote.plan().getMods()
        .next(r->{
                        var body= r.content;
                           return  if ( body!=null && body!="" && body!="null"){
                            var t:List<Modele>= tink.Json.parse(body.Log("parsing"));
                            t;
                           }else{

                            [].fromArray();
                           }
                });
  
    }


    public function getSms(nom:String):Promise<Sms>{
            return remote.plan().getSms(nom);
    }

    public function genCal(){
    //     #if !noIcal
       var capi=new CalApi(remote);
        for (key=>val in moo){
            capi.addEvent((key:CoolDate),val);
        }
        capi.send();
    //     var calendrier= capi.generate();
    //     #end

    }


    public function getDetail(nom:String):Promise<ModeleDetail>{
       return remote.plan().detail(nom);
    }

    public function saveDetail(r:ModeleDetail):Promise<Noise>{
        return Promise.NOISE;
    }


   

    
}

interface IServ{
    public var remote:tink.web.proxy.Remote<app.IRemoteRoot>;

    public function saveDispos(map:DisposMap):Promise<Noise>;
    public function getRez():Promise<Map<String,String>>;
    public function setRez(d:CoolDate,m:String):Promise<Noise>;
    public function genCal():Void;
    public function saveModeles(liste:List<Modele>):Promise<Noise>;
    public function getModeles():Promise<List<Modele>>;
    public function getDetail(nom:String):Promise<Detail>;
    public function saveDetail(detail:ModeleDetail):Promise<Noise>;
    public function flushBak():Promise<Noise>;
    public function flushDispoz():Promise<Noise>;
    public function getSms(nom:String):Promise<Sms>;
   
}