package cocomponents.dood.model;

import coconut.data.*;
import tink.pure.List;
import app.Interfaces;
import app.IRemoteRoot;
using tink.CoreApi;
import tink.state.Promised;
using DateTools;

class NuDateList  implements coconut.data.Model{
  
  @:editable private var revision:Int = 0;
  @:computed var items:List<NuDateCheck>=switch loadedDates{
    case Done(v):
      trace( "olléééé"+v);
      v;
    case Failed(e):throw 'peux pas recup la liste'+e; 
    case  Loading :[];
  }

  @:constant var server:tink.web.proxy.Remote<IRemoteRoot>;
  @:loaded var loadedDates:List<NuDateCheck> = {revision;server.dood().allDatesCheck();};
  
  //private static var userId:Int=0;
  //@:transition 
  public function add(date:Date,name:String){   
    server.dood().addDate(date.Log(),name).next(
        p->{revision:revision ++}
    );
    //return { items: items.append(createUser(name))};   
  }

  public function delete(id:Int){   
    server.dood().deleteDate(id).next(
        p->{revision:revision ++}
    );
    //return { items: items.append(createUser(name))};   
  }

  
  static var df:Int=0;
    static function generateDate():NuDate{

        var date=Date.now();
        var tf= date.toString();
        return {date:date.delta(Math.random()*12400000000),name:"fake",id:++df};
    }
 
  
  // @:transition function delete(item)
  //   return { items: items.filter(i => i != item) };

  // @:transition function clearCompleted() 
  //   return { items: items.filter(i => !i.completed) };

}