class Debug{

    public static function Log<T>(val:T,?msg:String="",? pos:haxe.PosInfos):T{
      #if debug
        trace('${pos.className} ${pos.lineNumber} $msg',val);
        #end
        return val;
      }
    

}