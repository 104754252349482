package planning.comps;
import planning.mod.SessionCalc;
import planning.mod.Modele;
import planning.mod.Dispo;
import fomantic.Calendar;
import fomantic.Dropdown;
import fomantic.Input;
using tink.pure.List;
import tink.state.Promised;
import fomantic.Button;
using tink.CoreApi;
import planning.mod.Modeles;

class Adder extends View{

    @:attr 
    var onAdded:(String,String)->Void = (a,b)->{};
    
    @:state
    var disp:Dispo=@byDefault None;

    @:state
    var qui:String=@byDefault "polo";

    @:attr
    var mods:Modeles;

    @:state
    var modele:Modele=null;

    @:state
    var clearMe:Bool= @byDefault false;

    @:state
    public var clearSelector:Bool=true;

    function sel(val:String){ 
        
        disp= switch(val) {

        case "all":All();
        case "dates":Dates(SessionCalc.genSessions());
        case "liste": Liste(SessionCalc.genSessions());
        case "between":Between(mods.sessions[0],mods.sessions[mods.sessions.length-1]);
        case "none": None;
        case _:None;

        }
        val.Log("select "+disp);
    }


    function dispose(d:Dispo){
        trace("dispose" +d);
        modele.setDispo(d);
    }



    function setName(v:String) {
        mods.setEditModeleName(v.Log("me"));

       // modele=mods.createModele(mods.editModeleName);
      modele= mods.getModeleByString(mods.editModeleName);
    clearSelector=true;
    }


    

    function render()
        <div>
         <Input type={text} placeholder="me" onBlur={setName} value={mods.editModeleName}/>
            <Dropdown entries={ Done(mods.modeleListNamed)} values={[new NamedSel(mods.editModeleName,null)]} onChange={n->{setName(n);trace("value="+n);}} />
             <if {modele!=null} >
             <Selector onSelect={sel} />
             <Dispositor dispo={disp} mod={modele} shouldrender={clearSelector} onResult={dispose} />
            </if>
        </div>;
    


}

class Dispositor extends View{


    @:tracked
    @:attr
    var dispo:Dispo;

    @:attr
    var onResult:Dispo->Void=(n)->{};

    @:attr
    var mod:Modele;

    @:skipCheck
    @:state
    public var selected:Array<NamedSel<CoolDate>>=null;
   
    @:controlled
    var shouldrender:Bool=true;

    function shouldViewUpdate(){
       
    //    selected= switch mod.filtre{
    //         case Liste(l):
    //            l.Log("ll");
    //         case Dates(d, cond2): 
    //             d.map(n->new NamedSel(n,n,true)).Log("dd");
    //         case None:[]; 
    //         case _:null;
    //     }
    //     trace (selected);
      // trace("shouldrender"+ shouldrender +dispo);
        if (shouldrender){
           // selected= null;       
        }
        return true;
    }

    function render(){
        <switch {dispo}>
            <case {Dates(n)}>
                  <Calendar eventDates = {[{date:new Date(2020,9,29,0,0,0),message:"session",classe:"sessday"}].fromArray()} onChange={d->mod.addDate(d)} />
            <case {Liste(l)} >
                
                <Lister dates={l.fromArray()} onAdd={b->{selected=[];mod.addDate(b);}} onRem={mod.removeDate} />
            <case {All(d)} >
                <Excepte dates={SessionCalc.genSessions().fromArray()} onChange={onResult.bind(All())} mod={mod} onEx={ex->onResult(All(Except(ex.toArray().Log("exed"))))}/>
            <case {Between(a,b)}>
                <Betweener mod={mod} onChange={(d1,d2,ex)->onResult(Between(d1,d2,ex))} />
            <case {None}>
            <Button onClick={e->mod.flush()} text='flush'></Button>
            <case {_} >
                <input  type="text" value="rien"/>
        </switch>
    }
}

class Lister extends View{



    
    @:attr
    var dates:List<CoolDate>=  @byDefault SessionCalc.genSessions().fromArray();


    @:skipCheck
    @:computed
    var values:List<NamedSel<CoolDate>>=
        dates.map(c->new NamedSel(c.toString(),c));
    

    @:attr
    var onAdd:CoolDate->Void;
    @:attr
    var onRem:CoolDate->Void;

    function render()
        <Dropdown  values={values.toArray()} multiple={true} entries={Done(values.Log("l"))} useLabels={true} onRemove={(a,b,c)->onRem(b)} onAdd={(a,b,c)->onAdd(b)}  />;

}


class Aller extends View{

    
    function render()
        <div></div>;
    
}

class Excepte extends View{
    @:attr
    var onChange:Void->Void =@byDefault ()->{};

    @:attr
    var mod:Modele;

    @:attr
    var dates:List<CoolDate>;
   // @byDefault SessionCalc.genSessions().fromArray();

    @:state
    var ex:List<CoolDate>=[];

    @:attr 
    var onEx:List<CoolDate>->Void;

    function viewDidMount(){
        onChange();
    }
    function render()
    <div class ="excepte">
    <Lister dates={dates} onAdd={b->ex=ex.append(b).Log("hop")} onRem={
        mod.removeDate
    }/>
    <Button onClick={e->onEx(ex.Log('exxx'))} text="Except" />
    
    </div>;
}

class Betweener extends View{

    @:attr
    var onChange:(Date,Date,Dispo)->Void;
    
    @:skipCheck
    @:state
    var inout:{d1:Date,d2:Date}={d1:null,d2:null};

    @:state
    var excepte:Bool=false;

    @:attr
    var mod:Modele;

    function postpone(inou:Int,d:Date){
        switch(inou){
            case 1:inout.d1=d;
            case 2:inout.d2=d;
        }
        if(inout.d1!=null && inout.d2!=null){

            excepte=true;
            onChange(inout.d1,inout.d2,null);
        }
    }


    function render()
        <div>
        <Calendar  onChange={postpone.bind(1,_)}/>
        <Calendar  onChange={postpone.bind(2,_)}/>
        <if {excepte}>
        <Excepte mod={mod} dates={SessionCalc.genSession(Between(inout.d1,inout.d2)).fromArray()} onEx={ex->onChange(inout.d1,inout.d2,Except(ex.toArray().Log("ex")))} />
       
        </if>
        </div>;
    
}

class Selector extends View{

    @:attr
    var onSelect:String->Void;


    function setup(el:js.html.Element){
        var s:js.html.SelectElement=cast el;
        if ( s==null)return;
        s.addEventListener("change",e->{
            //var val=untyped s.options[s.selectedIndex].value;
            var val=untyped s.options[s.selectedIndex].value;
            onSelect(val);
        });
    }

    function render()
        <div>
        <select ref={setup} name="pets" id="pet-select">
            <option value="dates">dates</option>
            <option value="liste">liste</option>
            <option value="all">all</option>
            <option value="between">between</option>
            <option value="none">none</option>
        </select>
        </div>;
}