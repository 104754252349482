package mdpaper;
using tink.CoreApi;
import app.IRemoteRoot;
import fomantic.Tab;
using tink.pure.List;
using Debug;

class MdPaperModel implements coconut.data.Model{

    @:constant
    var server:tink.web.proxy.Remote<IRemoteRoot>;

    @:constant
    var fileName:String= @byDefault "mdpaper.md";

    @:editable
    var content:String= @byDefault "...";

    @:computed
    var elements:TabList= 

        new TabList({items:

            [
                TabItemModel.create("edit",check(content).Log("create")),
                TabItemModel.create("vue",Markdown.markdownToHtml(content))
            ].fromArray()
        });

    


    // @:transition
    // public function setContent(c:String){
        
    //     return {content:c};
    // }j

    @:transition
    public function onChange(_content:String, tab:String){
        

        

        // if (tab=="edit" )
        // return save(content).next(n->
        //     {content:content}
        // );
        // else


            if (tab !="edit" ){
            trace("change to vue" +_content);
            return   {content:_content};
            }else{
                return  {};
            }
    }

   

    public function save(content:String):Promise<Noise>{
        return server.paper().savePaper({filename:fileName,content:content});
    }


    function check(str:String):String{
        trace( "check" + str);

        var reg= ~/(\n)/gm;
        if (reg.match(str)) 
            str=reg.replace(str, "<br />");

        return str.Log("checked");
    }


}