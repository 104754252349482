package sticker;

import fomantic.Dropdown.NamedSel;
using thx.Dates;
using thx.TimePeriod;
using thx.Weekday;


typedef FigureData={
    final date:Date;
    final sessionNum:Int;
    final modele:String;
    final sessionType:FigureType;
    final background:String;
    final hashtag:String;
    final son:String;
}

@:forward
enum abstract FigureType(String) from String to String{

var portraitSonore ="portrait sonore";
var portraitClassique= "portrait classique";


public static function toValues():Array<NamedSel<String>>{
    return [
        new NamedSel(portraitSonore,portraitSonore,true),
        new NamedSel(portraitClassique,portraitClassique,true),
        // new NamedSel(poseLongue,poseLongue,false),
        // new NamedSel(modelage,modelage,false),
        // new NamedSel(peinture,peinture,false),
        // new NamedSel(poseA2,poseA2,false),
        // new NamedSel(bizarre,bizarre,false),
    ];
}

}


class FigureModel implements coconut.data.Model{

    @:editable
    public var rev:Int=0;

    @:editable 
    public var modele:String=@byDefault "modele";

    @:editable
    public var hashtag:String= @byDefault "hashtag";

    @:constant
    public var background:String=@byDefault "";

    @:editable
    public var nb_participants:Int=4;

    @:editable
    public var sessionNum:Int=@byDefault 1;

    @:editable
    public var date:Date=@byDefault Date.now().snapNextWeekDay(Weekday.Saturday).withHour(15).withMinute(0);
   // public var date:Date =  @byDefault Date.now();

    @:editable
    public var son: String= @byDefault "fond sonore par Magali";

    @:editable
    public var sessionType:FigureType= @byDefault portraitSonore;

    

    @:computed
    public var rawData:FigureData={
        return ({
            background:background,
            sessionNum:sessionNum,
            date:date,
            sessionType:sessionType,
            modele:modele,
            hashtag:hashtag,
            son:son
        }:FigureData);
    }

    // @:attr
    // var onChanged:Void->Void;

    @:computed
    public var dateString:String={
        
        var days=[for (n in "dimanche/lundi/mardi/mercredi/jeudi/vendredi/samedi".split("/")) n];
        var mois=[for (n in "janvier/février/mars/avril/mai/juin/juillet/aout/septembre/octobre/novembre/decembre".split("/")) n];
date.Log("datta");
        return DateTools.format(date, 'le  ${days[date.getDay().Log("jour de la semaine")]} %d ${mois[date.getMonth()]} %Y à ${date.getHours()}h${date.getMinutes()}').Log("dateString");
    
    }

    @:computed 
    public var prixSession:Int={
        trace( "hop");
        var result=Math.ceil(prix_modele/nb_participants);

        result=(result<5)? 5 :result;
        
        return result;
        
    }

    @:editable
    public var prix_modele:Int=@byDefault 40;

    @:transition
    public function setModele(s:String){
        return {modele:s ,rev:(++rev)};
    }


    @:transition
    public function setPrixModele(s:Int){
        trace( "set prix" + s);
        var _s=(prix_modele+1) %51;//max participants
        return {prix_modele:_s, rev:(++rev)};
    }


    @:transition
    public function setNum(s:Int){
        //trace( "setNum");
        var _s=(nb_participants+1) %15;//max participants
        return {nb_participants:_s,rev:(++rev)};
    }
    @:transition
    public function setHash(s:String){
        return {hashtag:s,rev:(++rev)};
    }

    @:transition
    public function setSon(s:String){
        return {son:s,rev:(++rev)};
    }

    @:transition
    public function setSessionNum(s:Int){
        trace( "seesion num ...");
        var _s=(s+1);
         return {sessionNum:sessionNum+1,rev:(++rev)};
    }
    @:transition
    public function setDate(s:Date){
        //var _s=(s+1);
         return {date:s,rev:(++rev)};
    }

    @:transition
    public function setSessionType(d:FigureType){
        return {sessionType:d,rev: (++rev).Log('rev')};
    }
    

    public function new(){
        trace( "ey hop ! ");
        //haxe.Timer.delay(setPrixModele.bind(prix_modele),100);
    }


    public function export():String{
        return tink.Json.stringify(rawData);
    }

}