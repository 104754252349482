package sessionsModule;
import sessionsModule.ActivitiesComp;
class PlanningComp extends View{

    @:attr
    var mod:PlanningModel;

    function render()
        <div class="planning">

        <SessionsComp {... mod.sessMod} actsMod={mod.actsMod}/>
        <ActivitiesComp {... mod.actsMod} />

        </div>;
}