package planning.mod;

import planning.mod.SessionCalc.CoolDate;

class Sessions implements coconut.data.Model{

        @:observable
        var sessions:List<CoolDate>= SessionCalc.genSessions().fromArray();





}