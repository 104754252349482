package planning.mod;

import haxe.Serializer;
import tink.CoreApi;

import planning.mod.SessionCalc.CoolDate;
import thx.DateTimeUtc;
using Lambda;
import tink.json.Representation;
import tink.json.Value;
using tink.pure.List;
import fomantic.Dropdown.NamedSel;

//  @:jsonStringify(function (m:mod.Dispo)
// // 	// var _m= switch m {

// // 	//   case Liste(d): {type:"Liste",val:d};
// // 	//   case Dates(d,cond2):cast {type:"Dates",val:d};
// // 	//   case All: {type:"All"};
// // 	//   case Except(d): {type:"Except",val:d};
// // 	//   case From(d,except): {type:"From",val:d};
// // 	//   case Until(d,except): {type:"Until",val:d};
// // 	//   case Between(deb,fin,except):{type:"Between",val:deb};

// // 	// };
// // 	// return _m;
// // 	return tink.Json.stringify(m)
// // 	//}
// return  tink.Json.stringify(m)
// //	return haxe.Json.stringify(m)
//    )
// @:jsonParse(function (s:String):Dispo{

// 	//return haxe.Json.parse(s);
// 	// return switch (p) {
// 	// 	case All:All;
// 	// 	case _:All;
// 	// }
// 	//   if ((s:String).charAt(0) == '"') Text(haxe.Json.parse(s))
// 	//   else Full(s.parse())
// 	}
//   )
@:forward
abstract CoolName(Array<NamedSel<CoolDate>>) from Array<NamedSel<CoolDate>> to Array<NamedSel<CoolDate>> {

	function new (d:Array<NamedSel<CoolDate>>){
		this=d;
	}

	@:to function toRepresentation():Representation<Array<CoolDate>>
		return new Representation(this.map(t->t.value));
	
	  @:from static function ofRepresentation(rep:Representation<Array<CoolDate>>)
		return new CoolName(rep.get().map(t->new NamedSel(t.toString(),t)));
	
	//   @:from static function ofString(s:String)
	// 	return CoolDate.fromString(s);


}
@:observable
@:using(planning.mod.Dispo.DispoTools)
enum Dispo {
	
	//Liste(d:CoolName);
	Liste(d:Array<CoolDate>);
	@:json({type:"dates"}) 
	Dates(d:Array<CoolDate>, ?cond2:Dispo);
	All(?except:Dispo);
	None;
	
	@:json({type:"except"}) 
	Except(d:Array<CoolDate>);

	From(d:CoolDate, ?except:Dispo);
	Until(d:CoolDate, ?except:Dispo);
	Between(deb:CoolDate, fin:CoolDate,?except:Dispo);
    
}

class DispoTools{
	
	public static function plus(dispo:Dispo,c:CoolDate):Dispo{
		 return switch dispo {
            case Liste(d):dispo;
			case Dates(d, cond2):d.push(c); Dates(d,cond2);
			case All(d):dispo;
			case Except(d):dispo;
			case From(d, var except):dispo;
			case Until(d, var except):dispo;
			case Between(deb, fin):dispo;
			case None:None; //not tested
		}
	}
	public static function moins(dispo:Dispo,c:CoolDate):Dispo{
        trace( "moinss"+c);
        return switch dispo {
                case Liste(d):dispo;
				//case Dates(d, cond2):d=d.filter(cc->cc.toString()!=c.toString()); Dates(d,cond2);//todo compare 2 coolDates
				case Dates(d, cond2):d=d.filter(cc->cc!=c); Dates(d,cond2);//todo compare 2 coolDates
				case All(d):dispo;
				case Except(d):dispo;
				case From(d, var except):dispo;
				case Until(d, var except):dispo;
				case Between(deb, fin):dispo;
				case None:None; //not tested
			}
	}

	


	
	/*
	//return Array<Cooldate> en fonction des sessions... 
	public static function flat(dispo:Dispo, dates:Array<CoolDate>, ?filtre2:Dispo):Array<CoolDate> {
		// var dates=_dates.copy();
		// var _dates:Array<String>=dates.map(n->n.toString());

		// ya t il une 2nde condition?
		var _filtre = (filtre2 == null) ? dispo : filtre2;
		
		return switch _filtre {

			case Liste(n): 
				n.map(n->n.value);

			case Dates(_dispos, ex):
				
				var _dates=dates.filter(date -> 
					Lambda.exists(_dispos, _dispo-> date.sameDay(_dispo))
					
				);
			// filtre2
			if (ex != null){
				
				var __dates= _dates.copy();
				var plus=dispo.flat(dates,ex);
				// _dates.length.Log("_dates");
				// dates.length.Log("dates");
				// plus.length.Log("plus");
				var pluplus=plus.concat(__dates);
				
				//pluplus.length.Log("pluplus");
				return pluplus;
				

			}
			//
			return _dates;
			// _dates;
			case All: dates;

			case Except(_dispos):
				
				dates.filter(date -> {
					!Lambda.exists(_dispos, _dispo -> date.sameDay(_dispo));
					
				});

			case From(_dispo, ex):
				
				var dtc:DateTimeUtc = _dispo;
				dates=dates.filter(date->{
					(date:DateTimeUtc).greaterEqualsTo(dtc);
				});
				if (ex != null)
					return dispo.flat(dates, ex);
				return dates;
				

			case Until(d, ex):
				var _dates:Array<CoolDate> = [];
				for (dd in dates) {
					var cd:DateTimeUtc = (dd : CoolDate);
					if (cd.lessEqualsTo((d : DateTimeUtc))) {
						var td:CoolDate = cd.toDate();
						_dates.push(td);
					}
				}
				// filtre2
				if (ex != null)
					return dispo.flat(_dates, ex);
				//
				return _dates;

			case Between(deb,fin,ex):
				var _dates = [];
				for (dd in dates) {
					var cd:DateTimeUtc = (dd : CoolDate);
					if (cd.greaterEqualsTo((deb : DateTimeUtc)) && cd.lessEqualsTo((fin : DateTimeUtc))) {
						var td:CoolDate = cd.toDate();
						_dates.push(td);
					}
				}
				if (ex != null)
					return dispo.flat(_dates, ex);
					
	
				
				// filtre2

				return _dates;
		}
	}
*/
	
    
    public static function toString(dispo:Dispo):String{
        return switch dispo {
           case Liste(d):d.join("*");
           case Dates(d, cond2): "Dates("+d.join("*")+")";
           case All():"All";
           case Except(d):"Except"+ d.join("*");
           case From(d, var except):"from"+ d + "except "+except.toString();
           case Until(d, var except):"Until"+ d+"except "+except.toString();
           case Between(deb, fin):'Between $deb & $fin ';
		   case None:"None";
       }
   }

   public static function toCoolString(dispo:Dispo):String{
	return switch dispo {
	   case Liste(d):d.join(",");
	   case Dates(d, cond2): 
		   //var t:Array<String>=[];
		d.map(date->(date:CoolDate).toString()).join(" | ");
	   case All(d):"All";
	   case Except(d):"Except"+ d.join("*");
	   case From(d, var except):"from"+ d + "except "+except.toString();
	   case Until(d, var except):"Until"+ d+"except "+except.toString();
	   case Between(deb, fin):'Between $deb & $fin';
	   case None:"None";
   }
}
	
}