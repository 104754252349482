package planning.mod;
import thx.DateTimeUtc;
import planning.mod.Dispo;
using tink.CoreApi;
import planning.mod.Modele;
import planning.mod.SessionCalc.CoolDate;



@:jsonStringify(function (modele:planning.mod.Modele) return { nom:modele.nom ,dispos:modele.filtre})
@:jsonParse(
	function(json) return new planning.mod.Modele( json.nom ,json.dispos )
)
class Modele implements coconut.data.Model {
	
	@:skipCheck
    @:editable
	public var nom:String;

	@:editable
	//public var filtre:Dispo=@byDefault Except([("22/09":CoolDate)]);
	public var filtre:Dispo=@byDefault Dates(["22/09"]);


	public static function parse(n:Dispo):Dispo{
		n.Log("parsedDispo");
		return Dispo.Except( ["15/09"] );
	}

	// @:editable
    // var dispos:List<CoolDate> =[];

	public function new(a:String, b:Dispo ) {
		//trace( "------new Modele------");
		// this.nom = a;
		// this.filtre = b;
		this = {//exactly one assignment to `this` is expected in the constructor body and it must contain a value for every property that doesn't have an initial or default value
		nom: a,
		filtre:b
	  };
	}


	@:transition 
	public function flush(){
		trace(" modele flush");
		//return {filtre:Dispo.None};
		return {filtre:Dispo.Dates([])};
		//return {filtre:Except(["10/01"])};
	}


	@:transition 
    function addDate(d:CoolDate){
        //sorting ? 
		trace('add nom=$nom dates=$d');
		//return {};
		return{ filtre:filtre.plus(d) };
		//return{ filtre:filtre};
		
    }

    @:transition 
    function removeDate(d:CoolDate){
        //sorting ? 
        trace('remove nom=$nom dates=$d');
        return{ filtre:filtre.moins(d)};
	}

	@:transition 
	public function setDispo(d:Dispo){
		return { filtre:d };
	}

	public function flat(dates:Array<CoolDate>, ?filtre2:Dispo):Array<CoolDate> {
		// var dates=_dates.copy();
		// var _dates:Array<String>=dates.map(n->n.toString());

		// ya t il une 2nde condition?
		 var _filtre = (filtre2 == null) ? filtre : filtre2;
		_filtre.Log("filtre");
		return switch _filtre {

			case Liste(n): 
				n.map(n->n);

			case Dates(dispos, ex):
				
				var _dates=dates.filter(date -> 
					Lambda.exists(dispos, dispo-> date.sameDay((dispo:CoolDate)))
				);
				// var _dates = dates;
				// dispos.Log("dispose");

			// filtre2
			if (ex != null){
				
				var __dates= _dates.copy();
				var plus=flat(dates,ex);
				 _dates.length.Log("_dates");
				 dates.length.Log("dates");
				 plus.length.Log("plus");
				var pluplus=plus.concat(__dates);
				
				pluplus.length.Log("pluplus");
				return pluplus;

			}
			//
			return _dates;
			// _dates;
			case All(ex):  
				if (ex != null)
				return flat(dates, ex);
			return dates;

			case None:[];

			case Except(dispos):
				
				dates.filter(date -> {
					///true;
					!Lambda.exists(dispos, (dispo:CoolDate) -> (date:CoolDate).Log("date").sameDay((dispo:Date).Log("dispo")));
					
				});

			case From(dispo, ex):
				
				var dtc:DateTimeUtc = dispo;
				dates=dates.filter(date->{
					(date:DateTimeUtc).greaterEqualsTo(dtc);
				});
				if (ex != null)
					return flat(dates, ex);
				return dates;
				

			case Until(d, ex):
				var _dates:Array<CoolDate> = [];
				for (dd in dates) {
					var cd:DateTimeUtc = (dd : CoolDate);
					if (cd.lessEqualsTo((d : DateTimeUtc))) {
						var td:CoolDate = cd.toDate();
						_dates.push(td);
					}
				}
				// filtre2
				if (ex != null)
					return flat(_dates, ex);
				//
				return _dates;
			case Between(deb,fin,ex):
				var _dates = [];
				for (dd in dates) {
					var cd:DateTimeUtc = (dd : CoolDate);
					if (cd.greaterEqualsTo((deb : DateTimeUtc)) && cd.lessEqualsTo((fin : DateTimeUtc))) {
						var td:CoolDate = cd.toDate();
						_dates.push(td);
					}
				}
				if (ex != null)
					return flat(_dates, ex);
					
	
				
				// filtre2

				return _dates;
		}
	}
}