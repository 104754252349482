package auth;

using tink.CoreApi;
import app.Interfaces;
using tink.pure.List;
import app.Session;
import tink.web.routing.Context;
import tink.http.Response;

interface IAuthRoot {

        @:get("/allUsers")
        public function allUsers():Promise<List<User>> ;
        @:get("/allProfile")
        public function allProfile():Promise<List<Profile>> ;
        @:get("/allUsersProfile")
        public function allUsersProfile():Promise<List<UserProfile>> ;
        
        @:post('/recUser/')
        public function recUser(body:{id:String, pass:String}):Promise<Noise> ;

        @:post('/recProfile/')
        public function recProfile(body:Profile):Promise<Noise> ;
        @:get('/profile/$id')
        public function profile(id:Int):Promise<UserProfile> ;
        
	@:get('/addNoLogUser/$name')
        public function addNoLogUser(name:String):Promise<Noise> ;
	
        @:get('/deleteUser/$id')
        public function deleteUser(id:Int):Promise<Noise> ;

        @:patch('update')
        public function upatche(body:PatchString):Promise<Noise>;

        @:post('/otto')
	public function otto(body:OTTO,context:Context):Promise<OutgoingResponse>;

        @:post('/signup')
        public function signup(body:OTTOMail):Promise<Noise>;

        @:get('/logout') 
	public function logout(context:Context):OutgoingResponse;

        @:get('/connectedUser') 
        public function connectedUser(context:Context):Promise<Option<User>>;
        
        @:get('/recup/$mail')
        public function recup(mail:String):Promise<MSG>;

        @:post('/restore/')
        public function restore(body:{id:Int,pass:String}):Promise<Noise>;       

}