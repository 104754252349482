package command;
import js.Browser.document as doc;
using tink.CoreApi;
import app.IRemoteRoot;
import cocomponents.*;


@:keep
class InscriptCommand implements poscope.wire.ICommand<Noise>{

   #if client
    public function execute<T>(?data:T):Promise<Noise>{
         trace( "execute inscrip");
       // doc.addEventListener("DOMContentLoaded",init);  
        return Promise.lift(Noise);
    }

    function init(){
        trace("init");
        
//var mods=tink.pure.List.fromArray([for(a in 0...3)({id:'a',title:'titre$a'}:Stuff)]);
        
    }
    #end

}