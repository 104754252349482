package command;

import tink.web.proxy.Remote;
#if client
import js.Browser.document as doc;
import coconut.Ui.hxx;
import js.jquery.Helper.*;
import auth.client.Inscript;
#end

using tink.CoreApi;
import app.IRemoteRoot;


@:keep
class RecupCommand implements poscope.wire.ICommand<Noise>{

    
    var remote:tink.web.proxy.Remote<IRemoteRoot>;
    public function new(r) {
		remote = r;
	}

   #if client
    public function execute<T>(?data:T):Promise<Noise>{
        trace( "execute recup command");

        var landing=doc.querySelector(untyped (data.landing));
        

        var restore=function(pass:String){
            pass.Log("restore");
                remote.auth().restore({id:untyped (data).id,pass:pass})
                .handle(r->r);
        }

        coconut.ui.Renderer.mount(landing,
            
              hxx('
              <div>
              <ChangePass act=${restore} user={untyped(data.user)}/>
              </div>
              ')
           
        );
        
        return Promise.lift(Noise);    
    }
    #end

}