package planning.comps;
import planning.mod.Modele;
using tink.CoreApi;
import fomantic.*;
import fomantic.Icon;
import tink.state.Promised;
class ModelesView extends coconut.ui.View{

    @:tracked
    @:attr
    var modeleList:Promised<List<String>>;

    @:state
    var neo:String="";

    @:attr
    var addModeleName:String->Void;
    @:attr
    var remModeleName:String->Void;

    @:attr
    var select:(Bool,String)->Void;
    
    @:attr
    var send:Void->Void;

    @:attr
    var onClick:String->Void;

    function render()
        <div class="modeleView">
            <ul>

            <switch {modeleList.Log("nbnbnbnbn")}>
                <case { Done(_mm) } >

                {for ( modele in _mm )

                    <li>
                        <a onclick={e->onClick(modele)}> { modele } </a>
                        <Icon img={close} act={e-> remModeleName(modele) } />
                        <CheckBox checked={false} onChange={select.bind(_,modele)}/>
                    </li>

                }

                <case { Loading } >

                loading ...

                <case { Failed(r) } >

                fail

            </switch>

            </ul>
            <Input type={text} onBlur={v->neo=v} />
            <Button  onClick={e->addModeleName(neo)} />
            <Button onClick={e->send()} text="send" />
        </div>;
    
}