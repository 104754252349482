package mdpaper;


using tink.CoreApi;
interface IMdPaperRoute {
   
    

    @:post
    public function savePaper(body:{filename:String, content:String}):Promise<Noise>;
       



}