package sticker;

import fomantic.Calendar;
import fomantic.Dropdown;

import coconut.ui.View;
using tink.CoreApi;
import coconut.ui.View;
import fomantic.Dropdown.NamedSel;
using tink.CoreApi;

class InlineDropdown<T> extends View{

    @:skipCheck
    @:attr
    var data:Array<NamedSel<T>>;

    @:attr
    public var choose:T->Void;

    @:state
    var edit=false;

    @:attr
    var dataString:String;

    function act(d){
        choose(d);
        edit=false;
    }

    function setup(el:js.html.Element) {
        el.addEventListener("dblclick", e->{
            edit=true;
        });
    }

    function render()
        <span ref={setup}>

    <switch {edit}>
    <case {true}> 
     <Dropdown values={data} onChange={act}/> 
    <case {false}> 
     <span>{dataString}</span> 
        </switch>
    </span>;
}


class Dater extends View{

    @:attr
    var data:Date;

    @:attr
    var dataString:String;

    @:state
    var edit=false;

    @:attr
    var onAct:Date->Void;

    function setup(el:js.html.Element) {
        el.addEventListener("dblclick", e->{
            edit=true;
        });
    }


    function act(d){
        onAct(d);
        edit=false;
    }
    

   

    function render()
        <div ref={setup}>
            <switch {edit}>
                <case {true}> 
                <Calendar  type= {DateTime}onChange={act} />
                <case {false}> 
                <h2>{dataString}</h2> 
            </switch>
        </div>;
}


class EcritoNumInput extends View{
    
    
    @:attr
    var text:Int;
    
    

    @:state
    var done:Bool=false;


    @:attr
    var  choose:(Int)->Promise<Noise>; 

   

   
    
    function setup2(el:js.html.Element){

        if( el==null)return;
       
        el.addEventListener("click",e->{
            if( done)return;
            var content=(Std.parseInt(e.currentTarget.innerText)+1);
            trace(content);
           // untyped e.currentTarget.innerText="";
            _choose(content);
       // e.currentTarget.textContent= StringTools.replace(e.currentTarget.textContent,"."," ").Log("txtcontent");
        }
        );
       
        
    }

    function _choose( content) {
      
       done=true;
        choose(content).next(
            n->done=false
        ).handle(r->r);
       

       
    }


    // function render()
        
    //     <span ref={setup2} class="choice span_input">{text}</span>;
    //     <!-- <input type="number" ref={setup} class="choice span_text" value={Std.string(text)} />-->
    //        ;

    function render()
        
        <span ref={setup2} class="choice span_input">{Std.string(text)}</span>;
        

}

