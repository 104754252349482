package userManager.model;
using tink.pure.List;
import app.IRemoteRoot;
#if client
import coconut.data.Model;
import app.Interfaces;
using Debug;
using tink.CoreApi;
using tink.pure.List;
import tink.state.Promised;
import fomantic.Dropdown.NamedSel;
class UserManagerModel implements Model{

    @:skipCheck
    @:constant 
    var remote:tink.web.proxy.Remote<IRemoteRoot>;

    @:editable 
    var rev:Int=0;
  
    @:transition
    function update(id,field,val){
        return remote.auth().upatche({id:id,field:field,value:val})
        .next(n->@patch{rev: (rev+1).Log("rev")});
    }
    @:transition
    function add(){
        return remote.auth().addNoLogUser("new commer")
        .next(n->@patch{rev: (rev+1).Log("rev")});
    }

    @:transition
    function delete(id:Int){
        return remote.auth().deleteUser(id)
        .next(n->@patch{rev: (rev+1).Log("rev")});
    }

    @:loaded
    var users:List<UserModel>={ 
        rev;
        untyped remote.auth().allUsersProfile()
        .next(lu->lu.map(u->new UserModel({user:u, update:update, delete:delete, detail:loadDetail.bind(u.id)})));
    };

    @:editable
    var detail:Option<DetailModel>=None;


    @:transition
    function loadDetail(user:Int){
        
        user.Log("loadDetail");
        if (user==null)
        return Promise.lift(@patch{detail:None });
        
        return remote.auth().profile(user)
            .next(n->
                return  @patch{

                    detail:Some(new DetailModel( {data:n.Log("detail")} ))

                }
            ).tryRecover(f->f.Log('error detail'));
    }
}

class DetailModel implements Model{
    @:editable
    var data:UserProfile;
}

class UserModel implements Model{

    @:editable
    var user:UserProfile;
    var  update:Int->String->Dynamic->Void;
    var  delete:Int->Void;
    var  detail:UserProfile->Void;

    @:computed
    var accessList:Promised<List<NamedSel<Access>>> =Done(
        [
            new NamedSel("BASIC",BASIC,false),
            new NamedSel("ADMIN",ADMIN,false),
            new NamedSel("SUPERADMIN",SUPERADMIN,false),
        ]
        .fromArray());

    public function onChange(field,val)
        update(user.id,field,val);
    public function close(e)
        delete(user.id);
    public function more(e)
        detail(user);


}   
#end