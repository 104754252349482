package sessionsModule.command;

import app.IRemoteRoot;
import tink.web.proxy.Remote;
using tink.CoreApi;
import poscope.wire.ICommand;
using Debug;

#if client

import sessionsModule.*;

import js.Browser.document as doc;


import coconut.ui.View;
import coconut.Ui.hxx;
import coconut.ui.Renderer;
#end

@:keep
class SessionsCommand<T:{id:Int}> implements ICommand<Noise>{
   
    var remote:Remote<IRemoteRoot>;
    public function new(r:Remote<IRemoteRoot>){
        trace(r);
        this.remote= r;
    }

    public function execute<T>(?data:T):Promise<Noise>{
        trace( "hello adminAgenda" + data);
        #if client

       // doc.addEventListener("DOMContentLoaded", e->{
        var adm=doc.querySelector("#sessionsadmin");
        if (adm==null) return Promise.NOISE;
        trace("mount");
        var planningModel= new sessionsModule.PlanningModel({remote:remote});
        Renderer.mount(adm,hxx('<PlanningComp  mod={planningModel} />'));
        //}
        
        //); //end listener

        #end
        return Promise.NOISE;
    }

}

