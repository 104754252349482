package userManager.comps;
using tink.pure.List;
#if client
import userManager.model.UserManagerModel;
import tink.state.Promised;
import coconut.ui.View;
import app.Interfaces;
import tink.domspec.ClassName;
import cix.Style.rule as css;
import fomantic.*;
using userManager.comps.UserManager.TargetTool;
using tink.CoreApi;
import fomantic.Dropdown.NamedSel;
class UserManager extends View{

    // @:attr
    // var detail:Option<DetailModel>=None;

    // @:attr
    // var users:Promised<List<UserModel>>;
    // @:attr
    // var add:Void->Void;
    
    @:attr
    var model:UserManagerModel;

    final STYLE=css('

        .userrow{
           
            display:flex;
            flex-direction:row;
        }
    ');
    @:attr
    var className:tink.domspec.ClassName=[];


    function render(){
        <div class={className.add([STYLE])}>
        <switch {model.detail}>
        <case {None}>
        <table>
             <thead>
            <tr>
            <th>ID</th>
            <th>name</th>
            <th>Email</th>
            <th>tel</th>
            <th>access</th>
            <th>efface</th>
            <th>detail</th>
            </tr>
        </thead>
        <tbody>
    
        <switch {model.users}>
        <case { Done(s)}>
            { for ( userModel in s)

                <UserView {...userModel}/>
            }
            <Icon img={Icon.IconName.plus} act={e->model.add()}/>
            <case { Failed(f)}>
            ffff + {f.toString()}
        <case {Loading}>
            ... loading
         </switch>
         </tbody>
         </table>
         <case {Some(more)}> 
                <UserDetail model={more} onDone={ function() model.loadDetail(null) } />
        </switch>
         </div>;
       
    }

    /*
    function render()
        <div class={className.add([STYLE])}>
        <switch {model.detail}>
        <case {None}>
            <div class="liste_users">
            <switch {model.users}>
            <case { Done(s)}>
                { for ( userModel in s)
                    <UserView {...userModel}/>
                }
                <Icon img={Icon.IconName.plus} act={e->model.add()}/>
                <case { Failed(f)}>
                ffff + {f.toString()}
            <case {Loading}>
                ... loading
             </switch>
             </div>
        <case {Some(more)}> 
                <UserDetail model={more} onDone={function()model.loadDetail(0)} />
        </switch>
       
        
        </div>;
        */
}

class UserDetail extends View{
    
    @:attr
    var model:DetailModel;

    @:attr
    var onDone:Void->Void;

    function render()
        <div>
            <Label >
                identity
                <Input value={model.data.name} onBlur={s->onDone()} />
            </Label>
            <Label >
            email
            <Input value={model.data.mail} onBlur={s->onDone()} />
            </Label>
            <Label >
            tel
            <Input value={model.data.tel} onBlur={s->onDone()} />
            </Label>
            <Button text='Ok' onClick={e->onDone()} />
        </div>;

}


class UserView extends View{
    
        @:attr 
        var user:UserProfile;
        @:attr
        var onChange:(String,String)->Void;
        @:attr 
        var close:js.html.Event->Void;
        @:attr 
        var more:js.html.Event->Void;
        @:attr
        var accessList:Promised<List<NamedSel<Access>>>;


        function render()
            <tr>
                <td>
                <Input value={Std.string(user.id)} />
                </td>
                 <td>
                     <Input value={user.name} onChange={e->onChange("name",e.src())}/>
                 </td>
                 <td>
                     <Input value={user.mail} onChange={e->onChange("mail",e.src())}/>
                 </td>
                 <td>
                     <Input value={user.tel} onChange={e->onChange("tel",e.src())}/>
                 </td>
                 <td>
                     <Dropdown entries={accessList} values={[new NamedSel(Std.string(user.access),user.access)]} onChange={e->onChange("access",Std.string(e))}/>
                 </td>
                 <td>
                     <Icon img={Icon.IconName.close} act={close}/>
                 </td>
                 <td>
                     <Icon img={Icon.IconName.plus} act={more}/>
                 </td>
            </tr>;
        
        // function render()
        //     <div class="userrow">
        //         <Input value={user.name} onChange={e->onChange("name",e.src())}/>
        //         <Input value={user.mail} onChange={e->onChange("mail",e.src())}/>
        //         <Input value={user.tel} onChange={e->onChange("tel",e.src())}/>
        //         <Icon img={Icon.IconName.close} act={close}/>
        //         <Icon img={Icon.IconName.plus} act={more}/>
        //     </div>;
}

class TargetTool{
    public static function src(e:js.html.Event){
            return untyped e.currentTarget.value;
    }
}
#end