package fomantic;
import js.html.Event;
import  js.jquery.Helper.*;

import fomantic.Icon;
import fomantic.Easing;
using Debug;


class Tab extends View{

    @:attr 
    var className:tink.domspec.ClassName=null;

    @:attr @:optional
    var elements:TabList=@ByDefault null;

    @:attr @:optional
    var onVisible:String->Void =@byDefault (a)->{};
    @:attr @:optional
    var onRequest:String->Void =@byDefault (a)->{};
    @:attr @:optional
    var onLoad:(tabPath:String, parameterArray:Array<Dynamic>, historyEvent:Dynamic)->Void =@byDefault (a,b,c)->{};
    @:attr @:optional
    var onFirstLoad:(tabPath:String, parameterArray:Array<Dynamic>, historyEvent:Dynamic)->Void = @byDefault (a,b,c)->{};
 
    @:ref
    var ME:js.html.Element;

    @:attr @:optional
    var children:Children;


    function viewDidUpdate(){
        //trace ("update tab",  elements.items.first().sure().content);
    }

    

    function viewDidMount(){
       // trace ("mount");

       //J('.ui.accordion').accordion({selector:selector},"close",1);
      untyped  J('.ui.tabular .item').tab({
        onVisible: onVisible,
        onLoad:onLoad,
        onRequest:onRequest,
        onFirstLoad:onFirstLoad
        
      });
    }


    function render()
    <div>
    <div ref={ME} class={className.add(["ui","tabular","menu"])}>
    
    <for {item in elements.items} >
            
    <div class="item" data-tab={item.title}>{item.title}</div>
    </for>
    </div>
        <if {elements!=null}>
            <for {item in elements.items} >
             <TabItem title={item.title} content={item.content} />
            </for>
        <else>
            {...children}
        </if>
    </div>
    ;

}

class TabList implements coconut.data.Model{

    @:observable 
    public var items:tink.pure.List<TabItemModel>;


}

class TabItemModel implements coconut.data.Model{

    @:observable
    var title:String;
    @:observable
    var content:String;


    public static function create(title:String,content:String):TabItemModel{
        return new TabItemModel({title:title,content:content});
    }
}


class TabItem extends View{

    @:attr @:optional
    var title:String;

    @:attr @:optional
    var content:String;


    public var el:js.html.Element;

    function viewDidMount() {

        //el.textContent="";
    }

    function setup(el:js.html.Element){
        //trace( "setup");
        this.el=el;
        
    };


    function viewDidUpdate(){
      // trace(" update tab item");
    }



    function render()
        <div  class="ui bottom attached tab segment" data-tab={title}>
         <raw  ref={setup} tag="div" class='content' content={content} /> 
    
        </div>;

}