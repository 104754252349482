package command;

import tink.web.proxy.Remote;
#if client
import js.Browser.document as doc;
import coconut.Ui.hxx;
import js.jquery.Helper.*;
import auth.client.Inscript;
import auth.client.LogMark;
#end
using tink.pure.List;
using tink.CoreApi;
import app.IRemoteRoot;
import cocomponents.*;


@:keep
#if client @:build(loadfile.MacLoadFile.load("acties.json")) #end
class CoCommand implements poscope.wire.ICommand<Noise>{

    var acties:Array<Dynamic>;
    var remote:tink.web.proxy.Remote<IRemoteRoot>;
    public function new(r) {
		remote = r;
	}

   #if client
    public function execute<T>(?data:T):Promise<Noise>{
        trace( "execute cocoq");

        var landing=doc.querySelector(untyped (data.landing));
        var logModel=new LogMarkModel({remote:remote});

        var ottoed=function(e){
            return remote.otto({id:e.id,pass:e.pass}.Log("mdp")).next(
                n->{
                    logModel.login();
                    Noise;
                }
            );
        }

        var recup=function(mail){
            "recup".Log();
            return cast remote.auth().recup(mail);
            
        }

        coconut.ui.Renderer.mount(landing,
            
              hxx('
              <div>
              <p>login</p>
              <Inscript act=${ottoed} withEmail={false}/>
              <LogMark {...logModel} />
              <p>signup</p>
              <RecupMail act=${recup} />
              <Inscript act=${remote.auth().signup}/>
              <Inscription  user_id=${untyped (data).user_id} act=${remote.inscripeinture} acty=${untyped(data.activity)} acties=${acties.fromArray()} />
              </div>
              ')
           
        );
        
        return Promise.lift(Noise);    
    }

    function init(){
        trace("init");        
    }

    #end

}