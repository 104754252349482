package cross;
class Tools{


public static function mergeAnon<T,B>(ano1:T,ano2:B):T{
    var t:T=cast {};
    //pass1 copy 
    for( a in Reflect.fields(ano1))
        Reflect.setField(t,a,Reflect.field(ano1,a));
    //pass2 merge
    for( a in Reflect.fields(ano2))
        Reflect.setField(t,a,Reflect.field(ano2,a));
    return t;
}

}