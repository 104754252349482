package app;



using tink.CoreApi;
import tink.pure.List;
import app.Interfaces;
import sessionsModule.SessionRoute;
/*
		---------- remote root  ( move it ) -----------
*/

interface IRemoteRoot{
	// @:get()
	// public function allDates():Promise<List<NuDate>>;
	// @:get('/dood/addDate/$date/$name')
	// public function addDate(?date:Date,?name:String):Promise<Noise>;
	// @:get('/dood/deleteDate/$id')
	// public function deleteDate(id:Int):Promise<Noise>;
	// @:get('/dood/allUsersCheck/')
	// public function allUsersCheck():Promise<List<UserCheck>>;
	// @:get('/dood/allDatesCheck/')
	// public function allDatesCheck():Promise<List<NuDateCheck>>;
	// @:get('/dood/userdates/')
	// public function userdates():Promise<List<UserDate>>;
	// @:get('/dood/assign/$did/$uid')
	// public function assign(did:Int, uid:Int):Promise<Noise>;
	// @:get('/dood/deassign/$did/$uid')
	// public function deassign(did:Int, uid:Int):Promise<Noise>;
	// @:get('/dood/maybise/$did/$uid')
	// public function maybise(did:Int, uid:Int):Promise<Noise>;
	

	/*doodle*/


	
	@:sub('/dood')
	public var dood:app.routes.IDoodleRoute;
	

	/*auth*/

	#if client
	@:post('/otto') 
	public function otto(body:app.Session.OTTO):Promise<app.Session.OTTO>;
	// @:post('/signup') 
	// public function signup(body:app.Session.OTTOMail):Promise<Noise>;
	@:get('/logout') 
	public function logout():Promise<Noise>;

	// @:get('/connectedUser') 
	// public function connectedUser():Promise<Option<User>>;

    @:sub('/auth')
	public var auth:auth.IAuthRoot;
	
	@:sub("/pre")
	public var pre:app.routes.PreinscrRoute.IPreRoute;
	#end


	
	@:post('/inscripeinture')
	public function inscripeinture(body:{user_id:Int, activity:Activity}):Promise<Noise>;
	
	// @:post('/preinscr')
	// public function preinscr(body:{
	// 	name:String,
	// 	mail:String,
	// 	data:String,
	// 	status:PeopleStatus
	// }):Promise<Noise>;

	@:consumes("application/json")
	@:post('/stickExport')
	public function stickexport(body:String):Promise<Noise>;

	@:consumes("application/json")
	@:post('/terreexport')
	public function terreexport(body:String):Promise<Noise>;

	// @:get('/sticker')
	// @:produces('text/html')
	// public function sticker():Promise<tink.template.Html>;
	@:sub
	public var sess:sessionsModule.ISessionsRoute;

	@:sub
	public var paper:mdpaper.IMdPaperRoute;

	@:sub
	public var plan:planning.cross.IPlanningRoute;

	@:sub
	public var gloss:glossaire.GlossRoute.IGlossRoute;


}