package sessionsModule;

//import agenda.Evt;
import tink.state.Promised;
using tink.pure.List;
using tink.CoreApi;
import fomantic.*;
//import agenda.comp.AgendaModel;
import fomantic.Icon;
using Lambda;
import sessionsModule.ISessionApi.SessionAct as Session;
import sessionsModule.PlanningModel;
import sessionsModule.SessionsModel;


import sessionsModule.ActivitiesComp.ActivitySelector;
//import map.comp.MapView;
//import up.UpComp;

class SessionsComp extends coconut.ui.View{


    @:attr
    var actsMod:ActivitiesModel;

    @:attr
    var create:Session->Promise<Noise>;
    @:attr
    var delete:Int->Promise<Noise>;

    

    @:tracked
    @:attr
    public var mods:Promised<List<SessionModel>>;

    @:state
    var addMe:Bool=false;

    @:attr
    var setAct:(Int,Int)->Void;

    // @:attr
    // var navi:String->Void;

    // @:attr
    // var navigueTag:(Int,Int)->Void;

  @:attr
  var createModel:Void->SessionModel;


    private function closeMe(i:Int){
        addMe=false;
        return Promise.NOISE;
    }

    private function toggleAdd(_){
        trace( 'toggle add');
        addMe=false;
       return  create(_).next(n->{
        trace( 'toggle add next');
       
        return Promise.NOISE;
        });
    }

    // private function nav(e:js.html.MouseEvent){
    //     trace("nav="+untyped(e.target).dataset.id);
    //     navi(untyped(e.target).dataset.id);
    // }   
    
    function render()
        <div class="planning">
           
            <div class="neo">
                <Icon class="huge" img={IconName.plus} act={e->addMe=!addMe} />
                <span>nouvel évenement</span>
                <if {addMe}>
                    <SessionComp class="evt new" {... createModel()} close={closeMe} create={toggleAdd}/>     
                 </if>
            
            </div>

            <switch {mods}>
            <case {Done(s)}>  
                 {for (sessMod in s)
                    <div class="card">
                        <SessionComp class="evt"{...sessMod} />
                        <ActivitySelector acties={actsMod.acties} value={sessMod.sess.Activity_id} onChange={e->setAct(sessMod.sess.id,e)}/>
                    </div>
                }
            <case {Loading}>
            <case {Failed(f)}>
            </switch>

        </div>;
}


class SessionComp extends coconut.ui.View{

   
    
    @:attr 
    var sess:Session;

    @:attr
    public var close:Int->Void;

    @:attr
    public var create:Session->Promise<Noise>;

    @:attr
    var modall:Dynamic->Promise<Noise>;

    @:attr
    var className:tink.domspec.ClassName=[];

   


    function render()
        <div class={className}>

        <Input class="huge"  value={sess.title} onBlur={s->modall({title:s})}/>
        <div class="calendars">
            <Label>
            <p>date de début</p>
            <Calendar value={sess.date_deb} onChange={s->modall({date_deb:s})}/>
            </Label>
            <Label>
            <p>date de fin</p> 
            <Calendar value={sess.date_End} onChange={s->modall({date_End:s})}/>
            </Label>
           
        </div>

        
        <CheckBox class="slider" checked={sess.is_recurring} onChange={(b)->modall({is_recurring:b})} text="recurring ?"/>
        <CheckBox class="slider" checked={sess.is_All_Day} onChange={(b)->modall({is_All_Day:b})} text="all Day ?"/>
        
        <Input   value={sess.recurrence_pattern} onBlur={s->modall({recurrence_pattern:s})}/>

        <div class ="tools">
            <Icon img={Icon.IconName.close} act={e->close(sess.id)}/>
            <Icon img={Icon.IconName.save} act={e->create(sess)}/>
        </div>
        
        </div>;
}

/*
class TagsView extends coconut.ui.View {


    @:skipCheck
    @:attr
    var tags:Promised<List<EvtTagged>>;

    @:attr
    var evt_id:Int=@byDefault null;

    @:attr
    var select:(Int,Int)->Void;

    function render()
        <div class="agenda_tags">
            <switch {tags}>
                <case {Done(s)}>
                <ul>
                {for (tag in s)
                 <Tag  tag={tag} evt={evt_id} act={(a,b)->select(a,b)} /> 
                }
                

                </ul>
                <case {Loading}> popo
                <case {Failed(r)}> 
                <p>{r.toString()}</p>

            </switch>
        </div>;


}

class Tag extends coconut.ui.View{

    @:skipCheck
    @:attr
    var tag:EvtTagged;

    @:attr
    var evt:Int;

    @:attr
    var act:(Int,Int)->Void;
    

     function setup(el:js.html.Element){
        if ( el==null) return ;
        if( tag.evts.has(evt))
            el.classList.add("tagged");

     }

    public function render()
        <li  ref={setup} class="agenda tag" onclick={e->act(evt,tag.id)}>{tag.name}</li>; 
}


class TagAdmin extends coconut.ui.View{


    @:attr
    var tagsMod:TagsModel;

    @:state
    var addMe:Bool=false;

    function render()
        <div>
    <Icon class="huge" img={IconName.plus} act={e->addMe=!addMe} />
    <span>nouveau tag!</span>
    <if {addMe}>
        <Input class="tag new" onBlur={s->tagsMod.create(s)}/>
        
     </if>

    <TagsView { ...tagsMod } />

        </div>;

}

*/