package sessionsModule;
import sessionsModule.ISessionApi;
using tink.CoreApi;
using DateTools;
using Debug;
import thx.DateTimeUtc;
//using tink.sql.expr.Functions;


#if server 
@:publicFields
class SessionApi implements ISessionApi{


    var db:Db;
    function new(db:Db):Void{
        this.db=db;
    }
    
    function getSession(id:Int):Promise<Session>{
        return Promise.NEVER;
    }

    function all():Promise<Array<SessionAct>>{
        trace(" all session Act");
        return db.Session
        .join(db.SessionActivity).on(SessionActivity.Session_id==Session.id)
        .select({
            id:Session.id,
            title:Session.title,
            date_deb:Session.date_deb,
            date_End:Session.date_End,
            is_All_Day:Session.is_All_Day,
            is_recurring:Session.is_recurring,
            recurrence_pattern:Session.recurrence_pattern,
            created_at:Session.created_at,
            updated_at:Session.updated_at,
            created_by:Session.created_by,

            Activity_id:cast SessionActivity.Activity_id
        
        })
        .all();
        
    }

    function allFull():Promise<Array<SessionActFull>>{
        trace(" all session Act");
        return db.Session
        .join(db.SessionActivity).on(SessionActivity.Session_id==Session.id)
        .leftJoin(db.Activity).on(Activity.id== SessionActivity.Activity_id)
        .select({
            id:Session.id,
            title:Session.title,
            date_deb:Session.date_deb,
            date_End:Session.date_End,
            is_All_Day:Session.is_All_Day,
            is_recurring:Session.is_recurring,
            recurrence_pattern:Session.recurrence_pattern,
            created_at:Session.created_at,
            updated_at:Session.updated_at,
            created_by:Session.created_by,

            prof:Activity.prof,
            details:Activity.details,
            activity_titre:Activity.titre,

            Activity_id:cast SessionActivity.Activity_id
        
        })
        .all();
        
    }

    function allByTag(t:String){
        return db.Session
			.join(db.SessionTagJoin).on(SessionTagJoin.Session_id == Session.id)
            .join(db.SessionTag).on(SessionTag.id == SessionTagJoin.Tag_id)
            .join(db.SessionAttendee).on(SessionAttendee.Session_id ==Session.id)
            .join(db.User).on(User.id== SessionAttendee.User_id)
            .leftJoin(db.SessionActivity).on(SessionActivity.Session_id==Session.id)
            .leftJoin(db.Activity).on(Activity.id==SessionActivity.Activity_id)
			.select({
				//id:Eyeql.id,
				tag: SessionTag.name,
                name:Session.title,
                attendee:User.identity,
                activity:Activity.titre
			})
			.where(SessionTag.name == t)
			.all()
			.next(function(rows) {
				trace(rows);
				return rows;
			}).mapError(err->err.Log("error"))
			;
    }

    function allByActivity(id:Int):Promise<Array<SessionActNames>>{
        return db.Session
            .join(db.SessionActivity).on(SessionActivity.Session_id==Session.id)
            .join(db.Activity).on(Activity.id==SessionActivity.Activity_id)
			.join(db.SessionTagJoin).on(SessionTagJoin.Session_id == Session.id)
            .join(db.SessionTag).on(SessionTag.id == SessionTagJoin.Tag_id)
            .join(db.SessionAttendee).on(SessionAttendee.Session_id ==Session.id)
            .join(db.User).on(User.id== SessionAttendee.User_id)

			.select({
				//id:Eyeql.id,
				//tag: SessionTag.name,
                name:Session.title,
                attendee:User.identity,
                activity:Activity.titre
			})
			.where(Activity.id == id)
			.all()
			.next(function(rows) {
				trace(rows);
				return rows;
			}).mapError(err->err.Log("error"))
			;
    }

    

    function  getSessions(from:Date,to:Date):Promise<Array<Session>>{
        return Promise.NEVER;
    }

    function getSessionsByTag(tag:String,from:Date,to:Date):Promise<Array<
Session>>{

    return Promise.NEVER;

}
    /*creation*/

    function createSession(e:Session){
        trace( "createSession");
        return db.Session.insertOne(e);
       
        
    }

    function createMock(){
        var mock:Session={
            title: "kiss a girl",
            date_deb: Date.now(),
            date_End:Date.now().delta(1000),
            is_All_Day:false,
            is_recurring:false,
            recurrence_pattern:"",
            created_at:Date.now(),
            updated_at:Date.now(),
            created_by:1,
        }
       return  createSession(mock);
    }
    function createTag(name:String){
        return db.SessionTag.insertOne({
            name:name,
            created_at: Date.now(),
            updated_at:Date.now()
        });
    }
    function joinTag(tagid:Int,sessionId:Int){
        return db.SessionTagJoin.insertOne({
            Tag_id:tagid,
            Session_id: sessionId
        });
    }

    function attendSession(session_id:Int,User_id:Int){
        return db.SessionAttendee.insertOne({
            User_id:User_id,
            Session_id: session_id,
            status:true,
            created_at: Date.now(),
            updated_at:Date.now()
        });
    }


    function allActivities():Promise<Array<Activity>>{
        return db.Activity.all();
    }

    function createMockActivity(titre:String){
            return db.Activity.insertOne({
                titre:titre,
                prix:10,
                cotisation:10,
                date:'vendredi',
                details:"details",
                prof:"david",
                duree: 2,
                statut:"bof",
            });
    }


    function addActivity(activity_id:Int,session_id:Int){
        return db.SessionActivity
        .where(
            SessionActivity.Session_id == session_id
        ).all()
          .next(o->{
             trace(o, "exist");
             if ( o == null || o.length==0)
                return insertSessionActivity(activity_id, session_id).next(i->Noise);
              else
                return updateSessionActivity(activity_id, session_id).next(e->Noise);
            }
        );
    }

    function updateSessionActivity(activity_id:Int,session_id:Int){
        return db.SessionActivity.update(e->[
            e.Activity_id.set(activity_id)
            
        ],{where:e->e.Session_id==session_id}
        ).next(n->session_id.Log("update sessionactivity"));
    }

    function insertSessionActivity(activity_id:Int,session_id:Int){
        return db.SessionActivity.insertOne({
            Activity_id:activity_id,
            Session_id: session_id,
            created_at: Date.now(),
            updated_at:Date.now()
        });
    }

    function createActivity(act:Activity){
        trace("crete activityu" + act.id);
        return db.Activity
        .where(
           Activity.id == act.id
        ).all()
          .next(o->{
             trace(o, "exist");
             if ( o == null || o.length==0)
                return db.Activity.insertOne(act).next(e->Noise);
              else
                return updateActivity(act).next(e->Noise);
            }
        );
            // return updateActivity(act).next(e->Promise.NOISE);
            // else

            // return db.Activity.insertOne(act).next(e->Promise.NOISE);
    }

    function updateActivity(act:Activity){

        // return db.Activity.update(e->[
           
            
        // ],{where:e->e.id==act.id}
        // ).next(n->act.Log("update activity"));

        return db.Activity.update(e->[
            e.titre.set(act.titre),
            e.details.set(act.details),
            e.titre.set(act.titre),
            e.prix.set(act.prix),
            e.cotisation.set(act.cotisation),
            e.date.set(act.date),
            e.details.set(act.details),
            e.prof.set(act.prof),
            e.duree.set(act.duree),
            e.statut.set(act.statut)
            //todo
            
            
        
            
        ],{where:e->e.id==act.id}
        ).next(n->act.Log("update activity"));

    }

    
    function deleteActivity(id:Int):Promise<Noise>{
        return db.Activity.delete({where:act->act.id==id})
		.next(r->Noise);

    }

    function createMockTag(){
        return createTag("tagMetwo");
    }

    function createBigMock(){
        return createMock()
        .next(evId->
            createMockTag()
            .next(tagId->{tagId:tagId, evId:evId})
        )
        .next(ob->{joinTag(ob.tagId,ob.evId);ob.evId;})
        .next(evId->{
            attendSession(evId,1);
            evId;
        })
        .next(evId->
            createMockActivity("ma super Activité")
                .next(actId->{actId:actId,evId:evId})
        )
        .next(ob->
            addActivity(ob.actId,ob.evId)
        );
    }

    function deleteSession(_id:Int):Promise<Noise>{
        trace( "delete" +_id);
		return db.Session.delete({where:sess->sess.id==_id})
		.next(r->Noise);

    }

    function updateSession(ses:Session):Promise<Noise>{
        

        ses.Log('updatte');
		
        return db.Session.update(e->[
            e.title.set(ses.title),
            e.date_deb.set(ses.date_deb),
            e.date_End.set(ses.date_End),
            e.is_All_Day.set(ses.is_All_Day),
            
            e.is_recurring.set(ses.is_recurring),
            e.recurrence_pattern.set(ses.recurrence_pattern),
            e.updated_at.set(Date.now())
        ],{where:e->e.id==ses.id}
        ).next(n->ses.Log("update"));
    }


    /*setup*/


    function setup(){
      // return Promise.NOISE
       return  db.Session.drop()
        .next(u->db.SessionAttendee.drop())
        .next(u->db.SessionTagJoin.drop())
       .next(u->db.SessionTag.drop())
        .next(u->db.SessionActivity.drop())
        .next(u->db.Activity.drop())
       //return  Promise.NOISE
        .next(n-> db.Session.create())
        .next(n-> db.SessionAttendee.create())
        .next(n->db.SessionTagJoin.create())
        .next(n->db.SessionTag.create())
        .next(n->db.SessionActivity.create())
        .next(n->db.Activity.create())
        .next(n->{msg:"setup done!"});
    }



}
#end
class MockSession {

	static var count:Int = 0;
	static var lieux = ["ici", "here", "là-bas", "overthere", "nulle-part", "nowhere"];
	static var titres = "one,two,three,four,five,six,seven".split(',');
	static var descs = "bla,bli, balbli,bof, pas mal , moueff, menfin ".split(',');

	public static function mock(?titre:String):SessionAct {
		count = count++;
		return cast {
			//id: count,
			title:(titre!=null)?titre : titres[Std.random(titres.length)],
			date_deb:DateTimeUtc.fromDate(Date.now()).jump(Day, Std.random(10)).toDate(),
            date_End:Date.now(),
            is_All_Day:false,
            is_recurring:true,
            recurrence_pattern:'FREQ=DAILY;INTERVAL=10;COUNT=5',
            created_at:Date.now(),
            updated_at:Date.now(),
            created_by:1,
            Activity_id:null
		}
	}

}
