package mdpaper;
import js.html.AbortController;
import coconut.ui.View;
using tink.CoreApi;
import fomantic.Tab;
using Debug;
using mdpaper.MdPaperComp.NodeListTools;

class MdPaperComp extends View{

    @:attr
    var content:String;

    @:attr
    var elements:TabList;

    @:attr
    var onChange:String->String->Void;

    
    
    var el:js.html.Element;

    function setup(el:js.html.Element){

        this.el =el;
        el.querySelector(".tab[data-tab=edit] .content").contentEditable="true";

    }

    function changed(tab){

        trace( "changed" +content);
        var _content=el.querySelector(".tab[data-tab=edit] .content");
     
        _content.querySelectorAll("div,br").map().map(
            n -> n.textContent= "&&&"
        );
       var formi= _content.textContent.Log("ccc").split("&&&").join("\n");
      // _content=_content.map(n->n.textContent);
        onChange(formi.Log("ccc"),tab);
        // .next(
        //     n->{ while(_content.length>1) _content.pop().remove();n;}
        // )
        //.handle(f->f);$
       
    }

    function render() 
        <div  ref={setup} class="paper">
         <Tab  elements={elements} onVisible={ changed } />
        </div>;

        

}


class NodeListTools{

    public static function map(nl:js.html.NodeList):Array<Dynamic>{
        var tab=[];
        for ( a in 0...nl.length)
            tab.push(nl.item(a));
        return tab;
    }
}