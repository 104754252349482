package cocomponents;
import fomantic.*;
import fomantic.Icon;
import fomantic.Input;
using tink.CoreApi;
using tink.pure.List;
import tink.state.Promised;
import app.Interfaces;
import js.jquery.Helper.*;
import fomantic.Dropdown.NamedSel;
class Inscription extends coconut.ui.View {

    @:attr public var acty:String="nu";
    @:attr public var user_id:Int;
   @:attr public var act:(Inscripted)->Promise<Noise>;
   @:attribute var className:tink.domspec.ClassName = null;
   @:state var name:String=null;
   @:state var adresse:String=null;
   @:state var tel:String=null;
   @:state var mail:String=null;
   @:state var url:String=null;
   @:state var checked:Bool=false;
   @:tracked
   @:state var activite:Activity=null;
   @:state var accepted:Bool=false;
    @:state var loading:Bool=false;
    @:attr var acties:List<Activity>;
   @:computed public var activities:Promised<List<NamedSel<String>>>=
    Done(tink.pure.List.fromArray( Lambda.map(acties,e->new NamedSel(e.titre,e.titre))  )  );

   @:state var img:IconName=IconName.circle;
   
   var acts=[
   "peinture"=>{
            titre:"Peinture",
            prof:"David quertelet",
            date:Date.now(),
            prix:130,
            cotisation:30,
            details :"oui c'est ça !"

         },
    "dessin"=>{
            titre:"Dessin",
            prof:"DAvid Quertelet",
            date:Date.now(),
            prix:150,
            cotisation:30,
            details :"encore mieux"
         },
    "pochade"=>{
            titre:"Pochade",
            prof:"David Quertelet",
            date:Date.now(),
            prix:150,
            cotisation:30,
            details :"encore mieux"
         },
    "nu"=>{
            titre:"Nu",
            prof:"David Quertelet",
            date:Date.now(),
            prix:150,
            cotisation:30,
            details :"encore mieux"
         }
        
        ];



   
   function gather(e){
    e.preventDefault();
    
    valide()
    .next(ok->{
      trace( "valide" + ok);
        
          ok;
        
      }
      )
    .next(b->{
      loading=true;
       act({
        user_id:user_id,
         activity:activite
        });
      }
    )
    .next(e -> {
      loading=false;
      checked=true;
      img=IconName.check;
      e.Log();
    })
    .recover(f->{
      f.Log();
      loading=false;
      img=IconName.redo;
      Noise;
      }
      )
    .handle(e->e);

  }
  function changeActiv(a:String){
    if( a.Log("a")==null || a=="")
   // activite=cast acts.get(acty).Log("activité");
    activite=cast Lambda.find(acties,n->(n.titre==acty)).Log("activité");
    else
     activite=cast Lambda.find(acties,n->(n.titre==a)).Log("activité");
  }
  var pt:PromiseTrigger<Bool>;
  function valide(){
    pt=Promise.trigger();
    untyped(J('.me.ui.form')).form('validate form');
    return pt.asPromise();
  }

  function onValid(event,fields){
    trace("success");
    pt.resolve(true);
  }
  function onNotValid(formErrors,fields){
    trace("fail"); 
    accepted=false;
    pt.reject(new Error(Std.string(formErrors)));
  }

  function validinit(){
      
      //untyped(J(form)).find('.ui.form').Log();
      //return ;
    untyped (J('.me.ui.form'))
        .form({
          fields: {
            email: {
              identifier  : 'mail',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'merci de renseigner votre e-mail'
                },
                {
                  type   : 'email',
                  prompt : 'cet email n\'est pas valide'
                }
              ]
            },
            name: {
              identifier  : 'name',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'Merci de renseigner votre nom'
                },
                {
                  type   : 'length[6]',
                  prompt : 'votre nom est til de la forme prénom nom ?'
                }
              ]
            },
            activity: {
              identifier  : 'activity',
              rules: [
                {
                  type   : 'empty',
                  prompt : 'merci de choisir une activité'
                }
              ]
            }
          },
          onSuccess:onValid,
          onFailure:onNotValid
        })
      ;
   }

   function render()
   <div  class='${className}'>
      <Form ref={n->validinit()} class='me'>
      
         <Input name="name" img={IconName.user} onChange={e->name=untyped(e.currentTarget).value} placeholder="prenom,nom:Pablo Picasso" type={text}/> 
         <Input name="adresse" img={IconName.home} onChange={e->adresse=untyped(e.currentTarget).value} placeholder="adresse: 2 rue des poules 31000 Toulouse" type={text}/> 
         <Input name="tel" img={IconName.phone} onChange={e->tel=untyped(e.currentTarget).value} placeholder="tel:06 67 89 45 34" type={text}/> 
         <Input name="mail" img={IconName.envelope} onChange={e->mail=untyped(e.currentTarget).value} placeholder="mail:pablo@minos.com" type={InputType.email}/> 
         <Input name="url" img={IconName.cloud} onChange={e->url=untyped(e.currentTarget).value} placeholder="social:http://instagram/picasso" type={InputType.url}/> 
         <Dropdown name="activity"  entries={activities} onChange={changeActiv} defaultText={"select activité"}/>
         <Activite data={activite} done={e->accepted=true}/>
        
      </Form>
      <if {accepted}>
      <Button loading={loading} img={img} type={secondary}  onClick={gather} text="inscription" />
      <else>
      <hr/>
      </if>
      <if {checked}>
      <p> vous aallez recevoir un email de confirmation . à bientôt</p>
      </if>
      </div>
      ;
}

class Activite extends coconut.ui.View{

   @:attr var data:Activity;
   @:attr var done:js.html.Event->Void;

   function render()
      <div class="activity">
      <if {data!=null}>
         <h3>{data.titre}</h3>
         <p>avec {data.prof}</p>
         <p> {data.date} </p>
         <p>{data.details}</p>
         <h4>Reglement</h4>
         <p> cotisation {data.cotisation} €</p>
         <p>{data.prix}€ /trimestre</p>
         </if>
         <Button text="Ok" type={secondary} onClick={e->{e.preventDefault();done(e);}} />
      </div>

      
      ;
}