package cocomponents.dood.model;
import cocomponents.MayBool;
import coconut.data.*;
import tink.pure.List;
import app.Interfaces;
using tink.CoreApi;
import app.IRemoteRoot;
import tink.state.Promised;
using Lambda;

class UserList  implements coconut.data.Model{
  
  @:editable 
  private var revision:Int = 0;

  @:editable 
  var cache:List<UserCheck>=[];

  @:computed var items:List<UserCheck>=switch loadedUsers{

    case Done(v):
    cache=v.Log("useCheck");
    v;
    case Failed(e):throw 'UserList'+e.Log("userCheck"); 
    case  Loading :cache;
  }

  @:constant var server:tink.web.proxy.Remote<IRemoteRoot>;
  //@:loaded var loadedUsers:List<UserCheck> =  {revision;server.allUsersCheck();};
  @:loaded var loadedUsers:List<UserCheck> = {revision;server.dood().allUsersCheck();};
  
  static var maxPrix:Float=40;

  private static var userId:Int=0;
 
  //@:transition 
  public function add(name:String){   
    trace( "addUser"+name);
    return server.auth().addNoLogUser(name).next(
        p->revision++.Log("rev")
    ).tryRecover(err->err.Log("rec error"));

    //items=items.append(createUserCheck(name));
    //return { items: items.append(createUserCheck(name)) revision:revision, };   
  }

  // @:transition
  // public function addMe(name:String){
  //   switch (items.select((f:UserCheck)->{
  //     if( f.name==name)
  //       return Some(f);
  //       return None;
  //   }).first()){
  //     case Some(v):v.dates.push(1);
  //     case None:null;
  //   }
  //   return {items:items};
    
  // }

  

  public  function affecte(pair:Pair<Int,Int>,checked:MayBool){
        trace( "affecte");
       var prom= switch checked {
          case Sure(b):
            if(b)
              server.dood().assign(pair.a,pair.b);
            else
              server.dood().deassign(pair.a,pair.b);
             
          case Maybe:
            server.dood().maybise(pair.a,pair.b);
        }
        prom.next(
          p->{revision:revision ++}
        );

      //   if(checked)
      //   server.assign(pair.a,pair.b).next(
      //       n->{
      //           trace("assign"+n);
      //           return n;
      //       }
      //   ).next(
      //   p->{revision:revision ++}
      //   );
      //   else
      //   server.deassign(pair.a,pair.b).next(
      //       n->{
      //           trace("deassigne"+n);
      //           return n;
      //       }
      //   ).next(
      //   p->{revision:revision ++}
      // ); 
    }


  public function delete(id:Int){
    server.auth().deleteUser(id).next(
      p->{revision :revision++}
    );
  }

 

  public static function createUserCheck(name:String):UserCheck{
    var uz=createUser(name);
    var uzCheck:UserCheck=tink.Anon.merge(uz,{date_id:0,dates:[]});
    return uzCheck;
  }

  public static function createUser(name:String):User{
    return {identity:name,id:userId++,mail:null};
  }

  public static function generateUser():User{
        var names=["david","magali","Zélote","Lupin"];
        return {identity:names[Std.random(names.length)],id: userId++, mail:null};

    }
  
  // @:transition function delete(item)
  //   return { items: items.filter(i => i != item) };

  // @:transition function clearCompleted() 
  //   return { items: items.filter(i => !i.completed) };

}