package app;
import auth.AuthApi;
#if server
import tink.http.Response;
import tink.http.Header;
import tink.http.Header.HeaderName;
import tink.http.Header.HeaderField;
using tink.CoreApi;
import tink.http.Request.IncomingRequestHeader;
import haxe.crypto.Md5;
import tink.http.Request.OutgoingRequest;
import jsonwebtoken.Claims;
import jsonwebtoken.crypto.NodeCrypto;
import tink.core.Option;
#end
import app.Interfaces;


typedef OTTO={
    id:String,
    pass:String
}
typedef OTTOMail= OTTO &{
	mail:String
}


class Session {
    #if server
    static var secret:String="otto";

    public var currentUser:Promise<Option<User>>=None;

    var header:IncomingRequestHeader;
    
    public function new(header){

        this.header =  header.concat([new HeaderField(HeaderName.AUTHORIZATION,header.getCookie("token"))]);
       
    }

    //helps writing Cookie 
    public function tokCookieResponse(otto:OTTO):Promise<OutgoingResponse>{
        var hours:Int=1;
        trace("tokCookieResponse");
       return  signToken(otto).next(
           token->{
            var cok=HeaderField.setCookie("token",token,{expires:Date.fromTime(Date.now().getTime()+3600*hours)});//one our
            currentUser=getUserByToken(token);
            return  OutgoingResponse.blob (
                OK,
                haxe.Json.stringify(otto),
                'application/json',
                [cok]
            );
        }
        );
    }
    // clear cookie
    public function clearCookieResponse():OutgoingResponse{
            "logout".Log();
            var cok=HeaderField.setCookie("token","",{expires:Date.fromTime(Date.now().getTime()-1) });//in the pasrt 
           // var cok=new HeaderField(SET_COOKIE,"token= Max-Age=0; Secure; HttpOnly");
            return  OutgoingResponse.blob (
                OK,
                haxe.Json.stringify({}),
                'application/json',
                [cok]
            );
            
    
    }
    //shortcut
    public function logout(){
        return clearCookieResponse();
    }

    //generate token
    public static function signToken(data:OTTO):Promise<String>{
        
        var crypto = new NodeCrypto(); // pick a crypto from the jsonwebtoken.crypto package
        var signer = new jsonwebtoken.signer.BasicSigner(HS256(secret), crypto);
        
        var payload:jsonwebtoken.Claims =  cast data.Log("signToken"); //{aud:"ppp"};
        return  signer.sign(payload.Log("signed token"));
        
       
    }

    
    //verify token
    public static function verifyToken(tok:String){
        var crypto = new NodeCrypto(); // pick a crypto from the jsonwebtoken.crypto package
        var verifier = new jsonwebtoken.verifier.BasicVerifier(HS256(secret), crypto);
        var p=Promise.trigger();

         verifier.verify(tok).handle(function(o:Outcome<Claims,Error>) {
                    switch o {
	                case Success(n): trace(n);p.resolve(Some(n.Log("verify"))); cast p;
	                case Failure(e): trace('Invalid token: $e');p.resolve(None); e;     
                 }
                
        });
        return p.asPromise();
    }


    private function getUserByToken(tok:String):Promise<Option<User>>{
            return auth.AuthApi.getInstance().getByToken(tok).next(
                u->{if( u!=null)
                    Some(u);
                    tink.core.Option.None;
                }
            ).tryRecover(f->tink.core.Option.None);
    }

    public function getUser():Promise<Option<User>> {
       // header.Log();
        
        return switch header.byName('authorization') {
            
            case Success(tok) if (tok != ""):
                tok.Log("auth");

                return getUserByToken(tok)
                .next(
                    u->u.Log("jk")
                ).next(
                    u->verifyToken(tok).next(
                        b->{
                                   return  switch(b){
                                    case Some(b):
                                           return  switch(u){
                                                case Some(us):
                                                        u;
                                                case None:
                                                    AuthApi.getInstance().getByName(untyped(b).id).next(n->Some(n).Log("olo"));
                                            }
                                            
                                    case None: None;
                                    }
                             //u;
                    }
                    )
                );
                
                
                
            case Success(authvide):
                return None;
            case Failure(e):
               
                      
                 return  None;
                
        }
    }
    #end
}



class UserTools{

    public static function mock():User{
        var uid=function(){
                    var n="abcdefghijklmnoqrstuvwxyz".split("");
                    var me="";
                    for( a in 0...6)
                            me+= n[Std.random(n.length)];
                    return me;
            }

            return cast {id:0, identity:uid()};
    }
}