package sticker;


import js.html.Element;
import coconut.ui.View;
using tink.CoreApi;
import coconut.ui.View;
import fomantic.Dropdown.NamedSel;
using tink.CoreApi;


class EcritoLineInput extends View{
    
    @:attr
    var double:Bool=@byDefault false;

    @:attr
    var text:String;
    
    @:attr
    var className:tink.domspec.ClassName=[];

   // @:state
    var done:Bool=false;


    @:attr
    var  choose:(String)->Promise<Noise>; 

var el:Element;

    function setup(el:js.html.Element){
trace( "setup");
        if( el==null)return;
        this.el=el;
        if( !double )
        untyped el.contentEditable=true;

        trace( el.innerText);
        var trigger:String= (double)? "dblclick" : "focus";
        el.addEventListener(trigger,e->{
            untyped el.contentEditable=true;
            if( done)return;
            var content=e.currentTarget.innerText;
           // untyped e.currentTarget.innerText="";
            _choose(content);
       // e.currentTarget.textContent= StringTools.replace(e.currentTarget.textContent,"."," ").Log("txtcontent");
        }
        ); 
        el.addEventListener("blur",e->{
            if( done)return;
            e.preventDefault();
            if( double)
                untyped el.contentEditable=false;
            trace("------------blur");
        
                var content=e.currentTarget.innerText;
                trace( content + "-----ni----");
                
             
           _choose(content);
      
        }
        );
    }

    function _choose( content) {
      
        done=true;
        trace( "_choose="+content);
        choose(content).next(
            n->done=false
        ).handle(r->r);
       

       
    }

    function viewDidRender(firstTime:Bool){
        trace( firstTime);
        trace( "vieRender"+ text);
        //  if( !firstTime)
        //  setup(el);
    el.innerText=text;


    }


    function clean(s:String):String{
        trace( s);
        return s;
    }

    function render()
        
           
            <span class={className.add(["choice span_input"])} ref={setup}>{clean(text)}</span>;

}



