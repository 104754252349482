package cocomponents.dood;

import cocomponents.dood.model.*;
import cocomponents.MayBool;
import js.html.MouseEvent;
import tink.core.Pair;
import tink.pure.List;
import app.Interfaces;
import fomantic.*;
import fomantic.Icon.IconName;
import fomantic.Types;
import coconut.ww.*;
//import cix.Style.rule as css;

import cocomponents.MayBool;


class Doodle extends coconut.ui.View{


    @:attr public var users:UserList;
    @:attr public var dates:NuDateList;
    @:attr public var udates:UserDateList;
    @:attr public var actDate:(p:Pair<Int,Int>,MayBool)->Void; 
    @:attr public var addUser:(val:String)->Void;
    @:attr public var addDate:(Date,String)->Void;
    @:attr public var userDelete:(Int)->Void;
    @:attr public var dateDelete:(Int)->Void;
    @:attr public var onCheck:(Pair<Int,Int>,checked:MayBool,date:NuDateCheck)->Void;
    @:attr public var isChecked:(u:UserCheck,d:NuDateCheck)->MayBool;
    @:tracked
    @:attr var prix:Float=40.0;
    @:state public var neoDate:Date=@byDefault Date.now();
    @:state
    var evtName:String="next event";
    @:state 
    var dateAdd:Bool=false; 

    public function render(){
    trace("rendering!!! doodle");
        return <div class="doodle">
        <Calendar onChange={date->neoDate=date}/>
        <Icon img={IconName.plus_circle} act={e->dateAdd=true} />
        <if {dateAdd}>
        <div>
        <Input placeholder='evt name' onChange={e-> evtName=untyped e.currentTarget.value} />
        <Button onClick={e->addDate(neoDate,evtName)} text="add" img={plus}/>
        </div>
        </if>
        <div class="tablo">
        <div class="col header-cell">
            dates 
        </div>
        <for {d in dates.items}>
            <div class="col date" >
                <Icon img={IconName.close} act={e->dateDelete(d.id)} />
                <p>{DateTools.format(d.date,"%F")}</p>
                <p>{d.name}</p>
                <p>{Std.string(Math.ceil(d.prix))} € </p>
            </div>
        </for>
         <for {u in users.items}>
            <if {u.dates.length >0 /*&& u.dates.indexOf(0)==-1*/ }>
            <div class="users row">
                <div class="cellname">
                    <span>{u.identity}</span>
                    <Icon img={IconName.minus_circle} size={Size.small} act={e->userDelete(u.id)} />
                </div>
                <for {d in dates.items}>
                <Checker3  checkit={(p,b)->onCheck(p,b,d)}  data={new Pair(u.id,d.id)} checked={isChecked(u,d)} />                
                </for>
            </div>
            </if>
        </for>  
        </div>
        <Adder options={users.items.map(u->{{value:u.identity,title:u.identity}})}add={addUser}/>
        </div>;
    }
}

class Checker3 extends coconut.ui.View{


    @:attr public var checked:MayBool;
    @:attr public var data:Pair<Int,Int>;
    @:attr var checkit:(Pair<Int,Int>,MayBool)->Void;
    @:tracked
    @:state 
    public var _checked:MayBool=Maybe;
    
     
     var tab:Array<MayBool>=[Maybe,Sure(true),Sure(false)];
    @:state 
     var counter:Int=0;

    @:transition
    function fun(e:js.html.Event){
        //var ids= untyped(e.currentTarget).dataset.id.split("-");
        _checked=navTab();
        checkit(data,_checked);
    }

     function navTab(){
        var _counter= counter%tab.length;
        var item= tab[_counter];
        counter++;
        return item;
     }

    // function setup(e){
    //      _checked=checked;
    // }
    function viewDidMount():Void{
        
       _checked=checked;
        
    }

    public function render(){
       return <div  
        data-id='${data.a}-${data.b}'
       >
       <switch {_checked}>
       <case {Sure(true)} >
        <Icon img={IconName.check} act={fun}/>
       <case {Sure(false)} >
       <Icon img={IconName.close}  act={fun}/>
       <case {Maybe} >
       <Icon img={IconName.circle} outline={true} act={fun}/>


   </switch>
         
        </div>;
    }


}

/* 
   */


class Checker extends coconut.ui.View{

    @:attr public var checked:Bool=false;
    @:attr public var data:Pair<Int,Int>;
    @:attr var checkit:(Pair<Int,Int>,Bool)->Void;
    @:tracked
    @:state public var _checked:Bool=checked;

    
    @:transition
    function fun(e:js.html.Event){
       // var ids= untyped(e.currentTarget).dataset.id.split("-");
        _checked=!_checked;
        checkit(data,_checked);
    }

    // function setup(e){
    //      _checked=checked;
    // }
    function viewDidMount():Void{
        
       
       _checked=checked;
        
    }
    public function render(){
        
        
       return <div  
        data-id='${data.a}-${data.b}'
       >
      
         <if {_checked==true}>
                <Icon img={IconName.check} act={fun}/>
            <else>
               <Icon img={IconName.circle} outline={true} act={fun}/>
            </if>
        </div>;
    }

}