package command;

import js.Browser.document as doc;
using tink.CoreApi;
import app.IRemoteRoot;
import cocomponents.*;
using Lambda;
#if client
import js.jquery.Helper.*;
#end

@:keep
class AgendaCommand implements poscope.wire.ICommand<Noise>{

   #if client
    public function execute<T>(?data:T):Promise<Noise>{
         trace( "execute agenda");
        init();
       
        //doc.addEventListener("DOMContentLoaded",init);  
        return Promise.lift(Noise);
    }

    function init(){
        trace("init");
       doc.querySelectorAll('.getinfo a').toArray().iter(el->
            el.addEventListener("click",e->{
                e.preventDefault();
                trace(e.currentTarget.getAttribute("href"));
                popIt();
                e;     
            })); 
//var mods=tink.pure.List.fromArray([for(a in 0...3)({id:'a',title:'titre$a'}:Stuff)]);
        
    }


    function popIt(){
        var yes= ()->{
            1.Log("yes");
            var po=J('.ui.modal input').val();
            trace(po);
    }

    var no= ()->0.Log("no");
    var t=untyped( J('.ui.modal').modal({onApprove:yes, onCancel:no}).modal('show') );
       
    }

    #end

}