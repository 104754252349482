package ecrito;
import haxe.Timer;
import js.Browser;
import js.html.SpanElement;
using StringTools;
using tink.pure.List;
import ecrito.EcritoModel;

class Ecrito extends View{

    @:attr
    var model:EcritoModel;

    function render()
        <div class="ecrito">
            {for (line in model.lines)

                <if {line.editable}>
                <EcritoLineInput {...line} />
                <else >
                <EcritoLine {...line} />
                </if>
            }
            <p>results-></p>
            {
                for (line in  model.lines)
                <p class="result">{line.result.val.join(",")}</p>
            }
        <button onclick={model.onOk} >ok</button>
       <p> raw content-> </p>
        <p> <raw content={model.stringy} /></p>
        </div>;
}


class EcritoLineInput extends View{
    
    @:attr
    var notGoodEmail:Bool;

    @:attr
    var textIn:String;
    @:attr
    var textOut:String;

    @:attr
    var choices:tink.pure.List<String>;

    @:attr
    var id:String;

    @:state
    var done:Bool=false;

    @:tracked
    @:attr
    var result:ResultLine;

    @:attr
    var  choose:(String,String)->Promise<Noise>; 

    @:computed 
    public var affiched:String= {
        switch result.val{
            case ["..."] : choices.last().or("bof");
            case [""] : choices.last().or("bif");
            case []:"rien";
            case _:result.val[0];
        }
    }

    function setup(el:js.html.Element){

        if( el==null)return;
        untyped el.contentEditable=true;
        el.addEventListener("focus",e->{
            if( done)return;
            var content=e.currentTarget.innerText;
            untyped e.currentTarget.innerText="";
            _choose(id,content);
       // e.currentTarget.textContent= StringTools.replace(e.currentTarget.textContent,"."," ").Log("txtcontent");
        }
        );
        el.addEventListener("blur",e->{
            if( done)return;
            e.preventDefault();
            trace("------------blur");
        //var content=StringTools.replace(e.currentTarget.textContent,".","").Log("txtcontent");
        //if( e.currentTarget.innerText.trim()!=result.val){
        // var content=StringTools.replace(untyped(e.currentTarget.innerText),".","").trim().Log("txtcontent");
                var content=e.currentTarget.innerText;
                trace( content + "-----ni----");
                //untyped e.currentTarget.innerText="";
             
           _choose(id,content);
        // untyped e.currentTarget
        // untyped el.contentEditable=false;        
        // untyped e.currentTarget.innerText=result.val.Log("result.val");
        // }
        }
        );
    }

    function _choose(id, content) {
       // Browser.alert(content);
       done=true;
        choose(id,content).next(
            n->done=false
        ).handle(r->r);
       // Timer.delay(()->done=false,1000);

       
    }

    function render()
        <p class="pecrito">
            <raw content={textIn}/>
            <span ref={setup} class="choice span_input">{affiched}</span>
            <if {notGoodEmail.Log('polplo')}><span class="info">notgood email</span></if>
            <raw class="textoiut" content={textOut} />
        </p>;
}


class EcritoLine extends View{
    
    @:attr
    var choices:tink.pure.List<String>;

    @:attr
    var textIn:String;
    @:attr
    var textOut:String;

    @:attr
    var multiple:Bool=@byDefault false;

    @:attr
    var  choose:(String,String)->Void; 

    @:attr
    var id:String;

    @:tracked
    @:attr
    var result:ResultLine;

    var els:tink.pure.List<SpanElement>=[].fromArray();

    function setup(el:js.html.Element){
        if( el==null)return;
        trace("setup");
       // this.el= cast el;
        els=els.append(cast el);

        el.addEventListener("click", e->{
            trace(e.currentTarget.textContent);
            choose(id,e.currentTarget.textContent.trim());
        });
    }

 
    function viewDidRender(){
        trace("render");
        for ( le in els){
            trace(le.textContent.trim().length);
            if (!result.val.contains( le.textContent.trim().Log("trim")))
               disable(le);
            else
               enable(le);
        }

    }

    function enable(el:SpanElement){
       // el.style.textDecoration='none'.Log();
        el.classList.remove("nochoice");
    }
    function disable(el:SpanElement){
       // el.style.textDecoration="line-through";
        el.classList.add("nochoice");
    }

    function render()
        <p class="pecrito">
    <raw class="textout" content={textIn} />
            
            { for (choice in choices){
                <>
                <span>/</span>
                <span ref={setup} class="choice">{choice}</span>
                </>
                }
            }
            <if {multiple}>
                <span class="info"> (choix multiple)</span>
            </if>
            <raw class="textout" content={textOut} />
            
        </p>;


}

