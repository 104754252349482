package sessionsModule;

import app.Interfaces.Activity;
using tink.CoreApi;
import sessionsModule.SessionApi;
import sessionsModule.ISessionApi;
import tink.template.Html;
using tink.pure.List;
import sessionsModule.ISessionApi.SessionAct as Session;

interface ISessionsRoute{

    @:get('/')
    function home():Promise<Html>;

    @:get('/setup')
    function setup():Promise<{msg:String}>;

    // @:get("clearevts")
    // public function clearevts():Promise<Noise>;
    
    @:get('/admin')
    function admin():Html;

    // @:get("/reset")
    // public function reset():Promise<{msg:String}>;
      
   
    @:consumes('application/json')
    @:post
    function create(body:Session):Promise<Int>;
 
    @:consumes('application/json')
    @:post
    function update(body:Session):Promise<Noise>;

    // @:get('/allEvts/$filter/tag/$tag')
    // @:get('/allEvts/$filter')
    // @:produces( "application/json")
    // function allEvts(filter:String,?tag:Int):Promise<List<Evt>>;

    // @:get('/allByTag/$tag')
    // @:produces( "application/json")
    // function allByTag(tag:String):Promise<List<Evt>>;

    @:get('/delete/$id')
    function delete(id:Int):Promise<Noise>;

    @:produces('application/json')
    @:get
    function all():Promise<Array<Session>>;


    @:produces('application/json')
    @:get('/act')
    function allActivities():Promise<Array<Activity>>;

    @:get('/deleteActivity/$id')
    function deleteActivity(id:Int):Promise<Noise>;

    @:consumes('application/json')
    @:post('/updateActivity/')
    function updateActivity(body:Activity):Promise<Noise>;

    @:consumes('application/json')
    @:post('/createActivity/')
    function createActivity(body:Activity):Promise<Noise>;

    @:get('/addActivity/$sess_id/$act_id')
    function addActivity(sess_id:Int,act_id:Int):Promise<Noise>;

    @:get('/allByActivity/$act/')
    function allByActivity(act:Int):Promise<Array<SessionActNames>>;
    // @:produces("application/json")
    // @:get('/tags')
    // function tags():Promise<Array<EvtTag>>;

    // @:produces("application/json")
    // @:get('/taggeds')
    // public function taggeds():Promise<Array<EvtTagged>>;

    // @:get('/createTag/$name')
    // function createTag(name:String):Promise<Noise>;

    // @:get('/deleteTag/$id')
    // function deleteTag(id:Int):Promise<Noise>;

    // @:consumes("application/json")
    // @:post('/updateTag')
    // function updateTag(body:EvtTag):Promise<Noise>;

    // @:get('/tag/evt/$evt/tag/$tag')
    // function taggue(evt:Int,tag:Int):Promise<Noise>;

    // @:get('/untag/evt/$evt/tag/$tag')
    // function unTaggue(evt:Int,tag:Int):Promise<Noise>;



}