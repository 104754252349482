package sessionsModule;

import fomantic.Dropdown.NamedSel;

import tink.state.Promised;
using tink.pure.List;
using tink.CoreApi;
import fomantic.*;
//import agenda.comp.AgendaModel;
import fomantic.Icon;
using Lambda;
import sessionsModule.ISessionApi.Activity;
import sessionsModule.ActivitiesModel.ActivityModel;
import cix.Style.rule as css;

//import map.comp.MapView;
//import up.UpComp;

class ActivitiesComp extends coconut.ui.View{


    @:attr
    var create:Activity->Promise<Noise>;
    @:attr
    var delete:Int->Promise<Noise>;

    @:tracked
    @:attr
    public var mods:Promised<List<ActivityModel>>;


    @:state
    var addMe:Bool=false;

    // @:attr
    // var navi:String->Void;

    // @:attr
    // var navigueTag:(Int,Int)->Void;

   @:attr
   var createModel:Void->ActivityModel;


    private function closeMe(i:Int){
        addMe=false;
        return Promise.NOISE;
    }

    private function toggleAdd(_){
        trace( 'toggle add');
        addMe=false;
       return  create(_).next(n->{
        trace( 'toggle add next');
       
        return Promise.NOISE;
        });
    }


    function renderC(){
    //  return new C({class="evt new" {... createModel()} close={closeMe} create={toggleAdd}})
        return hxx('<ActivityComp class="evt new" {... createModel() } close={closeMe} create={toggleAdd} />');
    }

    // private function nav(e:js.html.MouseEvent){
    //     trace("nav="+untyped(e.target).dataset.id);
    //     navi(untyped(e.target).dataset.id);
    // }   
    
    function render()
        <div class="planning">         
            <div class="neo">
                <Icon class="huge" img={IconName.plus} act={e->addMe=!addMe} />
                <span>nouvelle activité</span>
                <if {addMe}>
                <ActivityComp class="evt new" {... createModel() } close={closeMe} create={toggleAdd} />
                </if>
            
            </div>
            <switch {mods}>
            <case {Done(s)}> 
            
                  {for (sess in s)
                    
                    <ActivityComp class="evt" {... sess} close={closeMe} create={toggleAdd} />
                 }
            <case {Loading}>
            <case {Failed(f)}>
            </switch>            
        </div>;
}

class ActivityComp extends coconut.ui.View{


    static var CSS=css('
        .label{
            display:block;
        }
    ');

    @:attr 
    var act:Activity;

    @:attr
    public var close:Int->Void;

    @:attr
    public var create:Activity->Promise<Noise>;

    @:attr
    var modall:Dynamic->Promise<Noise>;

    @:attr
    var className:tink.domspec.ClassName=[];

   
    function viewDidMount(){
        trace( "should");
        trace( act);
    }


    function render()
        <div class={className.add(CSS)}>

        <Input class="huge"  value={act.titre} onBlur={s->modall({titre:s})}/>
       
            <Label>
            <p>date</p>
            <Input class="dd"  value={act.date} onBlur={s->modall({date:s})}/>
            </Label>

           <Label><p>prix</p><NumStepper  value={act.prix} onChange={s->modall({prix:s})} /></Label>
           <Label><p>cotisation</p><NumStepper  value={act.cotisation} onChange={s->modall({cotisation:s})} /></Label>
           <Label><p>details</p> <TextArea  value={act.details} onBlur={s->modall({details:s})} /></Label>
           <Label><p>prof</p><Input  value={act.prof} onBlur={s->modall({prof:s})} /></Label>
           <Label><p>duree</p><NumStepper  value={act.duree} onChange={s->modall({duree:s})} /></Label>
           <Label><p>statut</p><Input  value={act.statut} onBlur={s->modall({statut:s})} /></Label>

       
        <div class ="tools">
            <Icon img={Icon.IconName.close} act={e->close(act.id)}/>
            <Icon img={Icon.IconName.save} act={e->create(act)}/>
        </div>
        
        </div>;
}

class ActivitySelector extends View{

    @:tracked
    @:attr
    var acties:Promised<List<Activity>>;

    @:attr
    var value:Int;

    @:computed
    var data:Promised<List<NamedSel<Int>>>={
       
           acties.map(


                    l->
                    l.map(a->{
                        //trace('${a.id} ${a.titre} $value ${( a.id == value)}');
                    new NamedSel(a.titre,a.id,( a.id == value));
                    }
                    )
                );
            
        
    }

    @:attr
    var onChange:Int->Void;

    function render() {
        <div class="activiteselector">
            <switch {data}>
                <case {Done(d)}>
            <fomantic.Dropdown values={d.toArray()} entries={Done([])} onChange={onChange} />
            <case {Loading}>
                loadaing ...
            <case {Failed(d)}>  
            error 
                </switch>
            </div>;
    }

}