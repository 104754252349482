package sessionsModule;

import sessionsModule.SessionsModel;

class PlanningModel implements Model{
    

    @:constant
    var remote:Remote<app.IRemoteRoot>;


    @:constant 
    var sessMod:SessionsModel = new SessionsModel({remote:remote,setAct:setAct});

    @:constant
    var actsMod:ActivitiesModel = new ActivitiesModel({remote:remote});

    
    function setAct(sess:Int,activity:Int) {
       
    //     untyped console.log("actop" +activity);
    //    untyped  console.log(typeof( activity));
    //     trace( Type.typeof(activity));
        if (activity.Log("actip") == null || Std.string(activity) == ""  )  return ; // bug sur dropdown 

        remote.sess().addActivity(sess,activity)
        .next(n-> sessMod.refresh() ) //update here ? 
        .handle(
            f->f
        );
    }




}