package planning.mod;

import thx.DateTime;
import planning.api.Serv;
import haxe.Exception;
import thx.DateTimeUtc;
import haxe.display.JsonModuleTypes.JsonClassFieldReference;

using tink.CoreApi;
using Std;
using DateTools;
using Debug;

import tink.json.Representation;

class SessionCalc {
	public static var modeles:Array<Modele>;

	// public static var carlos = new Modele("carlos", Except(["8/9"]));
	// public static var hazel = new Modele("hazel", Dates(["15/9","6/10"],Between("1/11","31/12")));
	// public static var juanita = new Modele("juanita", All);
	// public static var desiree = new Modele("desiree", All);
	// public static var louiseValentine = new Modele("louiseValentine", Dates(["29/9"]));
	// public static var david = new Modele("david", Dates(["8/9", "9/11", "14/12"]));
	// public static var sophie = new Modele("sophie", Dates(["13/10"],Between("29/12", "30/06")));
	// //public static var sophie2 = new Modele("sophie", );
	// public static var nanda = new Modele("nanda", From("15/09"));
	// public static var laure = new Modele("laure", Until("22/10"));
	// public static var veronik = new Modele("veronik", From("29/09", Except(["09/11"])));
	// public static var anais = new Modele("anaïs", Except(["13/10", "24/11", "8/12"]));
	// public static var florence = new Modele("florence", Dates(["15/09","29/09","06/10","13/10","20/10","27/10","10/11","17/11","24/11","01/12","08/12"]));
	// public static var stephane = new Modele("stephane", Between("01/09","31/12"));
	// public static var stefan = new Modele("stefan", Until("15/10"));
	// public static var thomas = new Modele("thomas", Dates(["29/09","6/10","13/10"],Between("01/11","30
	// /11")));

	static function main() {
		trace("Hello, world!");

		// [01/09,08/09,15/09,22/09,29/09,06/10,13/10,20/10,26/10,02/11,09/11,16/11,23/11,30/11,07/12,14/12,21/12,28/12]

		// var carlos=new Modele("carlos",Except(["8/9"]));
		// var juanita=new Modele("juanita",All);
		// var louise=new Modele("louise",Dates(["8/9","13/10"]));
		// var david=new Modele("david",Dates(["8/9","9/11","14/12"]));
		// var sophie=new Modele("sophie",Dates(["13/10"]));
		// var nanda=new Modele("nanda",From("15/09"));
		// var laure=new Modele("laure",Until("22/10"));
		// var mag=new Modele("mag",Between("22/10", "22/11"));

		modeles = genModeles();
		// var sessions= genSessions();
		// trace(sessions);
		// var map = mix(modeles, sessions);
		// for (a in sessions) {
		// 	trace('$a :${map.get(a)} ');
		// }
	}

	public static function AsyncInit():Promise<Map<CoolDate, Array<String>>> {
		return genAsyncModeles().next(mods -> {
			var sessions = genSessions();
			new Pair(mods, sessions);
		}).next(pair -> mix(pair.a, pair.b));
	}

	public static function genSessions() {
		return genSession(Between("01/01", "15/07", Except(["29/12"])));
	}

	public static function genModeles():Array<Modele> {
		return [];
		// return [carlos, juanita, louiseValentine, nanda, veronik, sophie, anais,florence,hazel,thomas,stephane,desiree,stefan];
	}

	public static function genAsyncModeles():Promise<Array<Modele>> {
		return new Serv().getModeles().next(p -> p.toArray());

		//	return [carlos, juanita, louiseValentine, nanda, veronik, sophie, anais,florence,hazel,thomas,stephane,desiree,stefan];
	}

	public static function genSession(dispo:Dispo):Array<CoolDate> {
		trace("genSession" + dispo);
		var now = Date.now();
		var deb:DateTimeUtc = null;
		var fin:DateTimeUtc = null;

		switch dispo {
			case Between(debut, _fin, _except):
				deb = debut;
				deb = deb.snapToWeekDay(2).Log("snap");
				deb = deb.withHour(7);
				fin = _fin;

			case _:
				deb = if (now.getMonth() > 7) DateTimeUtc.create(Date.now().getFullYear(), 9, 1, 19,
					30); else DateTimeUtc.create(Date.now().getFullYear() - 1, 9, 1, 19, 30);
				deb = deb.snapToWeekDay(2); // les mardis
				fin = deb.addMonths(10);
		}

		deb = deb.snapToWeekDay(2); // les mardis
		trace("debiii=" + deb);

		var dd = [];
		while (deb.lessEqualsTo(fin)) {
			// while (deb.year<2021){
			dd.push(deb.toDate().Log("toDate"));
			deb = deb.jump(thx.TimePeriod.Week, 1);
		}
		// je commente ça pour que Liste(n) fonctionne mais je sais plus trop pkoi ...
		// if (dispo != null)
		// 	throw ("todo : implemnts that ");

		return dd;
	}

	public static function mix(modeles:Array<Modele>, sessions:Array<CoolDate>):Map<CoolDate, Array<String>> {
		trace(" mixing");
		// modeles.Log("mosdeles");
		// sessions.Log("sessions");
		var map:Map<CoolDate, Array<String>> = [];

		for (modele in modeles) {
			var it = sessions.iterator();
			trace(modele.nom);
			var flatten = modele.flat(sessions);
			flatten.Log('flatten');
			while (it.hasNext()) {
				var session = it.next();

				if (Lambda.exists(flatten, f -> f.sameDay(session))) {
					// trace("exist");
					if (map.exists(session)) {
						// trace('push ${modele.nom} in $session ');
						map.get(session).push(modele.nom);
					} else {
						// trace('create ${modele.nom} in $session ');
						map.set((session : CoolDate), [modele.nom]);
					}
				} else {
					trace('paappaap');
				}
			}
		}
		trace(map);
		return map;
	}
}

@:forward
abstract CoolDate(Date) from Date to Date {
	public function new(n:Date) {
		this = n;
	}

	/**
		Returns true if this date and the `other` date share the same year.
	**/
	public function sameYear(other:CoolDate):Bool {
		// trace(this);
		var t = this;
		return (t : Date).getFullYear() == other.getFullYear();
	}

	/**
		Returns true if this date and the `other` date share the same year and month.
	**/
	public function sameMonth(other:CoolDate):Bool
		return sameYear(other) && this.getMonth() == other.getMonth();

	// return  this.getMonth() == other.getMonth();

	/**
		Returns true if this date and the `other` date share the same year, month and day.
	**/
	public function sameDay(other:CoolDate):Bool {
		// trace('${this.toString()} && ${other.toString()}');
		// trace("other"+other.toUTC());
		// trace("samMonth"+sameMonth(other)+"--"+  (this.getDate() == other.getDate()));
		return sameMonth(other) && this.getDate() == other.getDate();
	}

	@:to
	public function toString():String {
		return this.format("%d/%m");
	}

	@:op(A == B)
	public function equals(x:CoolDate):Bool {
		return this.getTime() == x.getTime();
	}

	@:op(A != B)
	public function nequals(x:CoolDate):Bool {
		return this.getTime() != x.getTime();
	}

	@:from
	public static inline function fromUTC(d:DateTimeUtc) {
		return new CoolDate(d.toDate());
	}

	@:to
	public function toUTC() {
		return DateTimeUtc.fromDate(this);
	}

	@:from
	public static inline function fromString(s:String) {
		// trace( "fromString" + s);
		if (StringTools.contains(s, "/"))
			return fromShortString(s);
		trace(Std.string(s));
		var _date = // try
			// 	DateTimeUtc.fromString(Std.string(s)).toDate()
			// 	catch(msg:Dynamic)
			Date.fromString(s);

		// _date.Log("after from string");
		return new CoolDate(_date);
	}

	// parse 23/08
	public static inline function fromShortString(s:String) {
		var splited = s.split("/");
		var mois = splited[1].parseInt() - 1;
		var jour = splited[0].parseInt();
		var year = whichYear(Date.now().getMonth());

		var _date = new Date(year, mois, jour, 19, 0, 0);

		return new CoolDate(_date);
	}

	public static function whichYear(m:Int) {
		var year = Date.now().getFullYear();
		var moisNow = Date.now().getMonth();
		if (moisNow < 8)
			year = year - 1;

		// trace(moisNow);
		// trace('année en cours =$year/${year+1} ');
		if (m < 8)
			year = year + 1;
		// trace('current month is ${m+1} $year');
		return year;
	}

	@:to function toRepresentation():Representation<String>
		return new Representation(this.toString());

	@:from static function ofRepresentation(rep:Representation<String>)
		return new CoolDate(CoolDate.fromString(rep.get()));

	@:from static function ofString(s:String)
		return CoolDate.fromString(s);
}
