(:static function render(data:Array<Cal.CalString>):)
BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
PRODID:nuEnSlip modele planning./ics
METHOD:PUBLISH
X-PUBLISHED-TTL:PT1H(: for e in data :) 
BEGIN:VEVENT
UID:(:e.uuid:)
SUMMARY:(:e.title:)
DTSTAMP:(:e.stamp:)
DTSTART:(:e.start:)
DESCRIPTION:(:e.description:)
X-MICROSOFT-CDO-BUSYSTATUS:(:e.busyStatus:)
URL:(:e.url:)
GEO:(:e.geo:)
LOCATION:(:e.location:)
STATUS:(:e.status:)
CATEGORIES:(:e.categories:)
ORGANIZER;CN=(:e.organizer:)(: for u in e.attendees :)ATTENDEE;RSVP=TRUE;ROLE=REQ-PARTICIPANT;PARTSTAT=ACCEPTED;CN=(:u.name:):mailto:(:u.email:)(:end :)
DURATION:(:e.duration:)
END:VEVENT
(:end:)
END:VCALENDAR(:end:)