package app.routes;

import tink.CoreApi;
import app.Interfaces;
import tink.pure.List;

interface IDoodleRoute{

    @:get()
	public function allDates():Promise<List<NuDate>>;
	@:get('/addDate/$date/$name')
	public function addDate(?date:Date,?name:String):Promise<Noise>;
	@:get('/deleteDate/$id')
	public function deleteDate(id:Int):Promise<Noise>;
	@:get('/allUsersCheck/')
	public function allUsersCheck():Promise<List<UserCheck>>;
	@:get('/allDatesCheck/')
	public function allDatesCheck():Promise<List<NuDateCheck>>;
	@:get('/userdates/')
	public function userdates():Promise<List<UserDate>>;
	@:get('/assign/$did/$uid')
	public function assign(did:Int, uid:Int):Promise<Noise>;
	@:get('/deassign/$did/$uid')
	public function deassign(did:Int, uid:Int):Promise<Noise>;
	@:get('/maybise/$did/$uid')
	public function maybise(did:Int, uid:Int):Promise<Noise>;


 }